<div class="contenedor">
  <h1 class="titulo">Cuidado de Joyas y Relojes</h1>
  <p class="texto-principal">Aquí podrás encontrarás toda la información sobre los cuidados de joyas <br class="none"> y
    relojes que
    debes tener para que
    disfrutes tu producto por más tiempo.</p>
  <div class="cuadro">
    <div class="contenedor-columnas">
      <div class="columna1">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_oro.png" alt="">
        <p class="cuidado">Manual de cuidado<br>Joyas en</p>
        <p class="joyas">Oro</p>
        <a href="" class="descargar" (click)="descargarArchivos(0)">Descargar</a>
      </div>
      <div class="columna2">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_p&a.png" alt="">
        <p class="cuidado">Manual de cuidado<br>Joyas en</p>
        <p class="joyas">Plata y Acero</p>
        <a href="" class="descargar" (click)="descargarArchivos(1)">Descargar</a>
      </div>
      <div class="columna3">
        <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_reloj.png" alt="">
        <p class="cuidado">Manual de cuidado<br>en</p>
        <p class="joyas">Relojes</p>
        <a href="" class="descargar" (click)="descargarArchivos(2)">Descargar</a>
      </div>
    </div>
  </div>
  <div class="laterales">
    <div class="fila1">
      <div class="contenedor-titulo">
        <p class="cuidado">Manual de cuidado en</p>
        <p class="joyas">Joyas en Oro</p>
      </div>
      <!-- <a href="" class="descargar" (click)="descargarArchivos(0)">Descargar</a> -->
      <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_oro.png" alt="">
    </div>
    <div class="fila2">
      <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_p&a.png" alt="">
      <div class="contenedor-titulo">
        <p class="cuidado">Manual de cuidado en</p>
        <p class="joyas">Joyas en Plata y Acero</p>
      </div>
        <!-- <a href="" class="descargar" (click)="descargarArchivos(1)">Descargar</a> -->
    </div>
    <div class="fila3">
      <div class="contenedor-titulo">
        <p class="cuidado">Manual de cuidado en</p>
        <p class="joyas">Relojes</p>
      </div>
      <img src="https://qastorageorafa.blob.core.windows.net/iconos/cuidado_reloj.png" alt="">
        <!-- <a href="" class="descargar" (click)="descargarArchivos(2)">Descargar</a> -->
    </div>
  </div>
</div>
