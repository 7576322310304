import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID, SimpleChanges, effect, inject } from '@angular/core';
import { CardProductComponent } from 'libreria-tienda/shared/card-product/card-product.component';
import { EstadosFiltrosService, GetProductsByFilterNameCommand, InstantSearchService, LoadingService, ManejadorEventosService, Product, SioServicesService, filtroCadena } from 'libreria-tienda';
import { MatGridListModule } from '@angular/material/grid-list';
import { PaginadorComponent } from 'libreria-tienda/shared/paginador/paginador.component';
import { ActivatedRoute, Router } from '@angular/router';
import { min, Subscription } from 'rxjs';
import { ManejadorPaginacionGridService } from 'libreria-tienda/src/public-api';

@Component({
  selector: 'lib-grid-catalogo',
  standalone: true,
  imports: [CommonModule, CardProductComponent, MatGridListModule, PaginadorComponent],
  templateUrl: './grid-catalogo.component.html',
  styleUrl: './grid-catalogo.component.css'
})
export class GridCatalogoComponent implements OnInit, AfterViewInit, OnChanges {

  cant_columnas: number = 40; // Controla la cantidad por pagina
  posicion: number = 0;
  pagina: number;
  cargas: number = 0;
  filtroCadena: filtroCadena;
  hoveredIndex: number | null = null;

  contadorConsultas: number = 0

  @Input() categoria: any;
  @Input() busqueda: any[];

  _filtros: any[] = [];
  _max: number = 0;
  _min: number = 0;

  @Output() noEncontrado = new EventEmitter<string>();

  public products: Product[] = new Array<Product>();
  public productsAmostrar: Product[] = new Array<Product>();

  getProductsByFilterNameCommand: GetProductsByFilterNameCommand;

  // public instantSearchService = inject(InstantSearchService)
  public estadosFiltrosService = inject(EstadosFiltrosService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)
  public manejadorEventos = inject(ManejadorEventosService)
  public router = inject(Router)
  public manejadorPaginacionGridService = inject(ManejadorPaginacionGridService)

  private subscriptionFiltrosAplicados: Subscription;

  // currentOrder = this.manejadorPaginacionGridService.order;

  // orden: string = 'default';

  // paginador: PaginadorComponent = new PaginadorComponent();

  ordenCatalogo = this.manejadorPaginacionGridService.order;
  paginaSignal = this.manejadorPaginacionGridService.pagina;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute) {
    effect(() => {
      // console.log('Pagina', this.paginaSignal());
      this.paginaSignal();
      this.cambioPagina();
    });
    // effect(() => {
    //   console.log('Orden', this.ordenCatalogo());
    //   this.ordenCatalogo();
    // });
  }

  ngOnInit() {
    this.pagina = 1;
    this.posicion = 0;
    if (typeof localStorage !== undefined) {
      localStorage.removeItem("categoria");
      localStorage.removeItem("filtros");
      if (localStorage.getItem("posicion") != undefined) {
        var posicion = JSON.parse(localStorage.getItem("posicion") || "")
        console.log(posicion);
        if (this.categoria == posicion.codigo) {
          this.pagina = posicion.pagina
          this.posicion = posicion.y
        }
        // console.log(this.posicion);
      } else {
        this.pagina = 1
        this.posicion = 0
      }
    } else {
      this.pagina = 1
    }

    if (this.categoria !== 'busqueda') {
      this.subscriptionFiltrosAplicados = this.estadosFiltrosService.filtrosAplicados$.subscribe(value => {
        if ((value.filtros !== this._filtros || value.precioMax !== this._max || value.precioMin !== this._min)) {
          // this.pagina = 1
          this.loadProductsDos(this.pagina, value.filtros, value.precioMin, value.precioMax);
          this._filtros = value.filtros;
          this._max = value.precioMax;
          this._min = value.precioMin;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['busqueda']) {
      if (this.busqueda !== undefined && this.busqueda.length !== 0) {
        // this.noEncontrado.emit('siEncontrado');
        // console.log(this.busqueda.length + ' Pagina ' + this.pagina);
        this.products = [...[]];
        this.products = this.busqueda;
        // this.pagina = this.instantSearchService.currentPage;
        // this.productosBusqueda()
        // console.log(this.instantSearchService.currentPage);

      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      window.scrollTo(0, this.posicion);
      localStorage.removeItem("posicion")
      this.posicion = 0;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.categoria !== 'busqueda') {
      this.subscriptionFiltrosAplicados.unsubscribe();
    }
  }

  loadProductsDos(i: number, filtros: any[] = [], precioMin: number = this._min, precioMax: number = this._max, categoria: string = this.categoria) {
    if (filtros.length > 0) {
      // console.log('guardar filtros');
      localStorage.setItem("filtros", JSON.stringify({ 'filtros': filtros }));
    }
    this.load.cargar(true)
    this.sioservice.getArticulosPaginados(categoria, this.cant_columnas, i, filtros, precioMin, precioMax, this.ordenCatalogo()).then((resultado) => {
      if (resultado.exitoso) {
        this.load.cargar(true)
        this.pagina = i
        this.products = [...[]];
        this.products = this.products.concat(this.inicializarProducts(resultado.valor));
        // console.log(this.products.length);
        if (this.products.length == 0) {
          // Acá va la logica de cuando el menú no retorna articulos
          // console.log('entra no encontrado');
          this.noEncontrado.emit('noEncontrado');
          if (this.contadorConsultas < 3) {
            this.contadorConsultas++;
          }
          this.load.cargar(false);
        } else {
          this.noEncontrado.emit('siEncontrado');
          this.load.cargar(false);
        }
      }
    }).catch((error) => {
      console.error(error);
      this.load.cargar(false);
    });
  }

  inicializarProducts(data: any) {
    let aux2: Product[] = [];
    for (let index = 0; index < data.length; index++) {
      let aux: Product = {
        cantidad: data[index].cantidad,
        descripcionWeb: data[index].descripcionWeb,
        nombreComercial: data[index].nombreComercial,
        esGIA: data[index].esGIA,
        giA_PrecioMinimo: data[index].giA_PrecioMinimo,
        giA_PrecioMaximo: data[index].giA_PrecioMaximo,
        idProducto: data[index].idProducto,
        imagen: data[index].imagen,
        manejaTalla: data[index].manejaTalla,
        porcentaje: data[index].porcentaje,
        precioAutorizado: data[index].precioAutorizado,
        precioFull: data[index].precioFull,
        precioMaximo: data[index].precioMaximo,
        precioMinimo: data[index].precioMinimo,
        referenciaProveedor: data[index].referenciaProveedor,
        totalRegistros: data[index].totalRegistros,
        errorImagen: false,
        pesoPiedraCentral: data[index].pesoPiedraCentral,
        tipoProducto: this.linea(data[index].idLinea, data[index].esGIA),
        linea: data[index].nombreLinea,
        idLinea: data[index].idLinea
      }
      aux2.push(aux)
    }
    // this.load.cargar(false)
    return aux2
  }

  linea(id: string, gia: boolean): string {
    switch (id) {
      case '41':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '31':
        if (gia) {
          return 'Gia';
        }
        return 'Anillo';
      case '01':
        return 'Anillo';
      case '03':
        return 'Anillo';
      case '28':
        return 'Venezia';
      default:
        return 'Normal';
    }
  }

  eliminarDuplicados(array: Product[]): Product[] {
    array = array.filter((producto, index, self) =>
      index === self.findIndex((p) => p.idProducto === producto.idProducto)
    );
    return array
  }

  ErrorImagen(arreglo: Product[]): Product[] {
    if (isPlatformBrowser(this.platformId)) {
      for (let i = 0; i < arreglo.length; i++) {
        const img = new Image();
        img.src = arreglo[i].imagen;
        img.onerror = () => {
          arreglo[i].errorImagen = true
        };
      }
    }
    return arreglo
  }

  // paginaChange(e: any) {
  //   // console.log(e);
  //   this.load.cargar(true)
  //   if (this.categoria !== 'busqueda') {
  //     // console.log(e, this._filtros, this._min, this._max);
  //     // this.load.cargar(false)
  //     this.loadProductsDos(e, this._filtros);
  //   } else {
  //     this.instantSearchService.otherPage();
  //   }
  // }

  cambioPagina() {
    this.load.cargar(true)
    if (this.categoria !== 'busqueda') {
      this.loadProductsDos(this.paginaSignal(), this._filtros);
    } else {
      // this.instantSearchService.otherPage();
    }
  }

  onHover(index: number): void {
    this.hoveredIndex = index;
  }

  onLeave(): void {
    this.hoveredIndex = null;
  }

}
