<div class="contenedor">
  <lib-miga-pan [migas]="migas" [indicador]="'compra'"></lib-miga-pan>
  <div class="contenedor-orden-boton-filtros">
    <div class="contenedor-boton-filtros" (click)="abriFiltros()">
      <img class="icono-filtros" src="https://kevinsweb.blob.core.windows.net/iconos/Icono_Filtro.png" alt="">
      <span>Filtrar</span>
    </div>
    <div class="contenedor-orden">
      <span>Ordenar Por</span>
      <select name="" id="" class="select-orden" (change)="changeOrder($event)">
        <option value="desc">Precio: Mayor a Menor</option>
        <option value="asc">Precio: Menor a Mayor</option>
        <option value="desc">Novedades</option>
      </select>
    </div>
  </div>
  @if (filtros) {
  <div class="contenedor-chips">
    <div *ngFor="let categorias of filtros; let j = index">
      @for (filtro of categorias.valor; track $index) {
      @if (filtro.select) {
      <div class="chip">
        {{filtro.nombreWeb}}
        <button class="chip-eliminar" (click)="remove(filtro.idFiltroAplicable, j, $index)">
          <!-- <mat-icon>cancel</mat-icon> -->
          <i class="fa-solid fa-x"></i>
        </button>
      </div>
      }
      }
    </div>
  </div>
  }
</div>
