import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Anillo, ConfigService, ExpressService, Item, ItemCar, JsonTextService, ManejadorEventosService, SelectAderezo, ShoppingCartService, TextResponse, caracteristicas, infoVenecia } from 'libreria-tienda';
import { EtiquetaDescuentoComponent } from 'libreria-tienda/shared/etiqueta-descuento/etiqueta-descuento.component';
import { TallasComponent } from 'libreria-tienda/shared/tallas/tallas.component';
import { ComplementoComponent } from '../detalles-producto-venezia/complemento/complemento.component';
import { ComplementoCarruselComponent } from '../detalles-producto-venezia/complemento-carrusel/complemento-carrusel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DescripcionCaracteristicasComponent } from '../descripcion-caracteristicas/descripcion-caracteristicas.component';
import { ImagenesMobileComponent } from '../imagenes-mobile/imagenes-mobile.component';

@Component({
  selector: 'lib-detalles-producto-anillo',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule, TallasComponent, ReactiveFormsModule, MatFormFieldModule, EtiquetaDescuentoComponent,
    ComplementoComponent, ComplementoCarruselComponent, DescripcionCaracteristicasComponent, ImagenesMobileComponent],
  templateUrl: './detalles-producto-anillo.component.html',
  styleUrl: './detalles-producto-anillo.component.css'
})
export class DetallesProductoAnilloComponent implements OnInit {

  @Input() catalogo: string;
  @Input() item: Item;
  @Input() itemAnillo: Anillo;
  @Input() complementos: infoVenecia[];

  caracteristicas: caracteristicas[];
  caracteristicasItem: caracteristicas[] = [];

  selectSize: boolean = true;
  open: boolean = false;
  submited: boolean = false;
  unico: boolean = false;

  complementosSelecd: SelectAderezo[] = [];

  jsonDetalle: TextResponse[]

  // canti: number = 0;

  indexProducto: number = 0;

  volver: string;

  caracteristicasFull: any;

  caracFull: boolean = false;
  catafull: boolean = false;

  cantidadDisponibleTallas: number = 0;

  caracteristicasMostrarObj: any;

  public manejadorEventos = inject(ManejadorEventosService);
  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)
  public cartService = inject(ShoppingCartService)
  public expressService = inject(ExpressService)
  private route = inject(ActivatedRoute);
  public router = inject(Router)

  constructor() { }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
      if (res !== undefined) {
        this.jsonDetalle = res;
        this.caracteristicasFull = this.getName("caracteristicas")
        this.caracFull = true;
        if (this.catafull) {
          this.caracteristicasMostrar();
        }
      }
    })

    if (this.complementos) {
      this.complementosSelecd = new Array(this.complementos.length)
    }
    this.route.queryParamMap.subscribe(params => {
      this.volver = (params.get('compra') ?? 'false');
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Este hook se llama cada vez que cambian los inputs del componente
    if (changes['catalogo']) {
      // console.log('ngOnChanges:', this.catalogo);
      if (this.catalogo != undefined) {
        this.catafull = true
        // console.log('Catalogo en ngOnChanges:', this.catalogo);
        if (this.caracFull) {
          this.caracteristicasMostrar();
        }
      }
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true
  }

  talla(i: number) {
    this.selectSize = true
    this.indexProducto = i;
    this.itemAnillo.TallaSeleccionada = this.itemAnillo.TallasCantidad[i].talla;
    this.itemAnillo.TallaIdSeleccionada = this.itemAnillo.TallasCantidad[i].tallaId;
    this.itemAnillo.CantidadDisponibleSeleccionada = this.itemAnillo.TallasCantidad[i].cantidad;
    this.itemAnillo.CantidadSeleccionada = 1;
  }

  mas() {
    if (this.itemAnillo.TallaSeleccionada == '-') {
      this.selectSize = false
    } else {
      if ((this.itemAnillo.CantidadSeleccionada + 1) <= this.itemAnillo.TallasCantidad[this.indexProducto].cantidad) {
        this.itemAnillo.CantidadSeleccionada++;
      }
    }
  }

  menos() {
    if (this.itemAnillo.TallaSeleccionada == '-') {
      this.selectSize = false
    } else {
      if ((this.itemAnillo.CantidadSeleccionada - 1) > 0) {
        this.itemAnillo.CantidadSeleccionada--;
      }
    }
  }

  agregarCarrito() {
    this.submited = true
    this.conteoUnidades()
    if (this.itemAnillo.TallaSeleccionada !== '-') {
      // console.log(this.itemAnillo.CantidadSeleccionada == this.itemAnillo.TallasCantidad[this.indexProducto].cantidad);
      if (this.itemAnillo.CantidadSeleccionada < this.cantidadDisponibleTallas) {
        this.unico = false
        this.agregarComplentosCarrito()
        let aux: ItemCar = new ItemCar();
        aux.idProducto = this.item.idProducto;
        aux.tallaId = this.itemAnillo.TallaIdSeleccionada;
        aux.cantidad = this.itemAnillo.CantidadSeleccionada;
        aux.imagen = this.item.imagen;
        aux.maxCantidad = this.itemAnillo.CantidadDisponibleSeleccionada;
        aux.descTalla = this.itemAnillo.TallaSeleccionada;
        aux.descWeb = this.item.descripcion;
        this.cartService.addItemCart(aux);
      } else {
        this.unico = true;
      }
    }
    if (this.volver == 'true') {
      this.router.navigate(['shopping-cart']);
    }
  }

  donwloadManual() {
    this.expressService.getPdf("Manual de tallas").subscribe(res => {
      window.open(this.configService._config.URLPDFTALLAS + res.file, '_blank');
    })
  }

  donwloadPolitics() {
    window.open(this.configService._config.URLPDFTERMINOS, '_blank');
  }

  handleKeydown(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  complemtoSele(i: number, e: any) {
    this.complementosSelecd[i] = e
  }

  complemtoSeleCarrusel(e: any) {
    // this.complementosSelecd[e.index].index = e.index
    // this.complementosSelecd[e.index].item = e.item
    // this.complementosSelecd[e.index].select = e.select
  }

  agregarComplentosCarrito() {
    if (this.complementosSelecd[0]) {
      for (let i = 0; i < this.complementosSelecd.length; i++) {
        if (this.complementosSelecd[i].select) {
          this.cartService.addItemCart(this.complementosSelecd[i].item)
        }
      }
      this.manejadorEventos.lanzarEvento('goCart');
    }
  }

  caracteristicasMostrar() {
    this.caracteristicasMostrarObj = [this.caracteristicasFull[this.item.catalogo], this.item.caracteristicas]
  }

  conteoUnidades() {
    this.cantidadDisponibleTallas = this.itemAnillo.TallasCantidad.reduce((acumulador, objeto) => {
      return acumulador + objeto.cantidad;
    }, 0);
    // console.log(this.cantidadDisponibleTallas);
  }

  openChat() {
    const enlaceWhatsApp = this.configService._config.WHATSAPP;
    window.open(enlaceWhatsApp, '_blank');
  }

}

