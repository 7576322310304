<div class="d-none d-sm-none d-md-block d-lg-block">
  <mat-form-field appearance="fill" [ngClass]="{'border-red': tallaSelectd == '-' && submited}">
    <mat-select (selectionChange)="talla($event)" placeholder="Elige tu talla" required [(value)]="tallaSelecionada">
      @for (talla of tallas; track $index) {
      <mat-option [value]="talla"> {{talla.talla}} </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

<div class="d-block d-sm-block d-md-none d-lg-none">
  <div class="contenedor-tallas">
    @for (talla of tallas; track $index) {
    <div class="boton-talla" [ngClass]="{'bg-gris' : $index == inedexTallaSeleccionada}" (click)="tallaMobile(talla, $index)">
      <span> {{talla.talla}} </span>
    </div>
    }
  </div>
</div>
