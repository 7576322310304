import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { Deptos, direccion, LoadingService, Municipes, SioServicesService, userlogged, UserService } from 'libreria-tienda';

@Component({
  selector: 'lib-form-direccion',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule],
  templateUrl: './form-direccion.component.html',
  styleUrl: './form-direccion.component.css'
})
export class FormDireccionComponent {

  @Input() submited: boolean = false;

  @Output() datosFormulario = new EventEmitter<FormBuilder | boolean>();


  AddresForm: FormGroup;

  listMunicipes: Municipes[] = new Array<Municipes>();
  listDeptos: Deptos[] = new Array<Deptos>();

  submitted: boolean = false;

  deptoini: Deptos;


  load = inject(LoadingService)
  userService = inject(UserService)
  sioService = inject(SioServicesService)

  constructor(private fb: FormBuilder) {
    this.AddresForm = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      celular: ['', Validators.required],
      departamento: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      direccion: ['', [Validators.required]],
      informacionAdi: [''],
    })
  }

  ngOnInit(): void {
    // this.addresService.setDireccion(new direccion())
    // this.addresService.setIndex(-1)
    // this.userLog = this.userService.getUserLocal()
    this.sioService.GetDataListCommand("Departamentos", "", []).subscribe((deptos: Deptos[]) => {
      deptos.forEach(dep => {
        this.deptoini = new Deptos();
        this.deptoini = dep
        this.listDeptos.push(this.deptoini)
      })
      this.deptoini = new Deptos();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submited']) {
      if (this.submited !== undefined) {
        if (this.AddresForm.valid) {
          this.datosFormulario.emit(this.AddresForm.value)
          console.log('uno');
        } else {
          console.log('dos');
          this.AddresForm.markAllAsTouched()
          // this.datosFormulario.emit(false)
        }
      }
    }
  }

  deptoSelected(event: any) {
    // console.log(this.AddresForm.get('departamento')?.value);
    this.load.cargar(true)
    this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [this.AddresForm.get('departamento')?.value.idDepto]).subscribe((municipe: Municipes[]) => {
      this.listMunicipes = municipe
      this.load.cargar(false)
    })
  }

  municipeSelected(event: any) {
    //   this.AddresForm.controls['ciudad'].setValue(event.value);
  }


  onSubmit(): void {
    this.submitted = true;

    if (this.AddresForm.invalid) {
      // Marcar todos los campos como "touched" para que se muestren los errores
      Object.keys(this.AddresForm.controls).forEach(field => {
        const control = this.AddresForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
      console.log('Formulario inválido');
      return;
    }

    // Si el formulario es válido, manejar los datos aquí
    console.log('Formulario enviado con éxito:', this.AddresForm.value);

    // Reiniciar formulario después de un envío exitoso, si lo deseas
    this.AddresForm.reset();
    this.submitted = false;
  }


}
