import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Banner, ExpressService, LoadingService } from 'libreria-tienda';

@Component({
  selector: 'lib-banner-footer',
  standalone: true,
  imports: [],
  templateUrl: './banner-footer.component.html',
  styleUrl: './banner-footer.component.css'
})
export class BannerFooterComponent implements OnInit {

  @Input() catalogo: string;

  banners: Banner;

  imageUrl: string;

  public load = inject(LoadingService)
  public expressService = inject(ExpressService)

  constructor(private router: Router) { }

  ngOnInit() {
    this.expressService.getBanners(this.catalogo).subscribe(res => {
      this.banners = res.banners[0]

      this.imageUrl = this.banners.imagen;

      // console.log(this.imageUrl);

    })
  }

  navigate(){
    this.router.navigate(['/catalogo/venezia']).then(() => {
      window.scrollTo(0, 0);
    });
  }

}
