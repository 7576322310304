<div class="contenedor">
  <div class="imagen responsive-image">
    <img src="https://qastorageorafa.blob.core.windows.net/generales/Imagen certificado.png" alt="">
  </div>
  <div class="datos">
    <div class="contenedor-titulos">
      <p class="certificado">certificado de</p>
      <p class="autenticidad">autenticidad</p>
    </div>
    <div class="contenedor-formulario">
      <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
        <div class="campo">
          <label for="identificacion">Número de Identificación</label>
          <input
            id="identificacion"
            type="text"
            formControlName="identificacion"
            class="form-control"
            placeholder="Escribe tu número de identificación"
          />
          <div
            class="error"
            [class.visible]="formulario.get('identificacion')?.invalid && formulario.get('identificacion')?.touched"
          >
            Número de identificación es requerido y debe ser numérico.
          </div>
        </div>
        <div class="campo">
          <label for="factura">Número de Factura</label>
          <input
            id="factura"
            type="text"
            formControlName="factura"
            class="form-control"
            placeholder="Escribe el número de factura"
          />
          <div
            class="error"
            [class.visible]="formulario.get('factura')?.invalid && formulario.get('factura')?.touched"
          >
            Número de factura es requerido y debe ser numérico.
          </div>
        </div>
        <div class="boton">
          <button
            type="submit"
            [disabled]="formulario.invalid"
            class="buscar"
          >
            Buscar
          </button>
        </div>
      </form>

    </div>
    <div class="textos">
      <span>*En Kevin's Joyeros te certificamos la compra de tui piedra preciosa (Diamantes, Esmeraldas, Rubís, Zafiros). Con este certificado te garantizamos la autenticidad de la joya que acabas de adquirir.</span>
      <span>**Para joyas en oro SIN piedra o con perlas no generamos certificado, ya que con la factura se certifica el tipo de metal y piedra que estas comprando.</span>
    </div>
  </div>
</div>
