import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { direccion, User, userlogged } from '../class/user';
import { Pdf } from '../interfaces/pdf';
import { OrdenDeVenta, Product, favorite, productoMP } from '../class/product';
import { items } from '../class/cart';
import { Observable, lastValueFrom } from 'rxjs';
import { Banner } from '../interfaces/banner';
import { Store } from '../class/store';
import { Vacante } from '../class/vacante';
import { Aplicantes } from '../class/aplicantes';
import { ItemCar } from '../class/carro-compras';

@Injectable({
  providedIn: 'root'
})
export class ExpressService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  urlExpress = this.configService._config.URLEXPRESS;
  // urlExpress = 'http://localhost:5540';
  // urlExpress = 'https://a185-181-49-65-46.ngrok-free.app';

  // rutas publicas login

  insertUser(user: User) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(
      this.urlExpress
      // 'http://localhost:5540'
      + '/web/insertar-usuario', user, { headers: headersApi });
  }

  logginUser(email: string, password: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(this.urlExpress + '/web/inicio-sesion-usuario',
        // this.http.post<any>('http://localhost:5540' + '/web/inicio-sesion-usuario',
        { email: email, password: password },
        { headers: headersApi })
    )
  }

  logginUserSocial(email: string, id: string, facebook: boolean, google: boolean, usuario: User) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(
      this.urlExpress
      // 'http://localhost:5540'
      + '/web/inicio-sesion-usuario-social', { email: email, id: id, facebook: facebook, google: google, user: usuario }, { headers: headersApi });
  }

  forgotPassword(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // 'http://localhost:5540'
        + '/web/olvido-contrasena',
        { email: email },
        { headers: headersApi })
    )
  }

  restorePassword(email: string, token: string, password: string): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + "/web/restaurar-contrasena", { correoElectronico: email, token: token, password: password }, { headers: headersApi })
  }


  // rutas privadas usuario

  añadirItemCarrito(correo: string, item: ItemCar): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        // "http://localhost:5540"
        this.urlExpress
        + "/web/add-articulo-carro-compras",
        { correo: correo, item: item },
        { headers: headersApi }
      )
    )
  }

  eliminarItemCarrito(correo: string, idProducto: string, cantidad: number): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        // "http://localhost:5540"
        this.urlExpress
        + "/web/eliminar-articulo-carro-compras",
        { correo: correo, idProducto: idProducto, cantidad: cantidad },
        { headers: headersApi }
      )
    )
  }

  editUser(user: User, oldPassword: string | null, newPassword: string | null) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + '/web/editar-usuario', { user: user, oldPassword: oldPassword, newPassword: newPassword }, { headers: headersApi });
  }

  editDireccionesUser(correo: string, direcciones: direccion[]): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        // "http://localhost:5540"
        this.urlExpress
        + "/web/editar-direcciones",
        { correo: correo, direcciones: direcciones },
        { headers: headersApi }
      )
    )
  }

  editFavCarrito(user: userlogged): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        // "http://localhost:5540"
        this.urlExpress
        + "/web/editar-favoritos-carrito",
        { user: user },
        { headers: headersApi })
    )
    // return this.http.post<any>(this.urlExpress + "/web/editar-favoritos", { email: email, favorites: items }, { headers: headersApi })
  }

  getPdf(name: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Pdf>(this.urlExpress + '/web/obtener-pdf-nombre', { name: name }, { headers: headersApi });
  }

  getUser(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<User>(
        this.urlExpress
        // "http://localhost:5540"
        + "/web/obtener-usuario",
        { email: email }, { headers: headersApi })
    )
  }

  getCarroCompras(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // "http://localhost:5540"
        + "/web/get-carro-compras", { correo: email }, { headers: headersApi })
    )
  }

  getUserDireccion(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<User>(
        this.urlExpress
        // "http://localhost:5540"
        + "/web/obtener-direcciones-usuario",
        { correo: email }, { headers: headersApi })
    )
  }

  setCode(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // "http://localhost:5540"
        + "/web/establecer-codigo", { correo: email }, { headers: headersApi })
    )
  }

  verifyCode(email: string, code: string): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + "/web/verificar-codigo", { correoElectronico: email, code: code }, { headers: headersApi })
  }

  verifyUser(email: string, token: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.get<any>(
        this.urlExpress
        // 'http://localhost:5540'
        + "/web/verificar-email/" + email + "/" + token, { headers: headersApi }))
    // return this.http.get<any>("http://localhost:5540"+ "/web/verificar-email/" + email + "/" + token, { headers: headersApi })
  }

  getIcon(): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + "/web/obtener-icono-seleccionado", { headers: headersApi })
  }
  // rutas privadas ordenes


  getShoppingRecord(email: string): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // 'http://localhost:5540'
        + "/web/obtener-ordenes-aprobadas",
        { email: email }, { headers: headersApi })
    )
  }

  getCostoEnvio(valorCompra: number, nombreenvio: string) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<number>(this.urlExpress + '/web/calcular-costo-envio', { valorCompra: valorCompra, nombreenvio: nombreenvio }, { headers: headersApi });
  }

  getPreferenceId(email: string, ordenVenta: OrdenDeVenta): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // 'http://localhost:5540'
        + "/web/ordenes",
        { email, ordenVenta }, { headers: headersApi })
    )
  }

  getOrderApproved(id: string): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + "/web/obtener-orden-aprobada-id", { id: id }, { headers: headersApi })
  }

  // rutas publicas banner

  getBanners(section: string): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Banner[]>(this.urlExpress + "/web/obtener-banners", { section }, { headers: headersApi })
  }

  // rutas publicas tiendas

  getStores(): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<Store[]>(this.urlExpress + "/web/obtener-tiendas", { headers: headersApi })
  }

  // rutas publicas vacantes

  getVacantes(): Observable<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Vacante[]>(this.urlExpress + "/web/obtener-vacantes", { headers: headersApi })
  }

  insertAplicante(aplicante: Aplicantes) {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.urlExpress + '/web/insertar-aspirante', aplicante, { headers: headersApi });
  }

  uploadFile(file: any) {
    return this.http.post<any>(this.urlExpress + "/cargar-archivo", file)
  }

  compraRapida(datos: any): Promise<any> {
    const headersApi = new HttpHeaders({ 'Content-Type': 'application/json' });
    return lastValueFrom(
      this.http.post<any>(
        this.urlExpress
        // 'http://localhost:5540'
        + "/web/compra-rapida",
        { datos }, { headers: headersApi })
    )
  }
}
