<div class="d-flex-column pl-100 pr-100 d-none d-sm-none d-md-none d-lg-block center">
  <div class="d-flex-row w-100 pt-5 pb-5">
    <lib-miga-pan [migas]="migas" [indicador]="'compra'" class="pl-2rem"></lib-miga-pan>
  </div>
  <div class="d-flex-row w-100 justify-center">
    <div class="d-flex-column pr-1rem">
      <lib-img-auxiliar-detalle [imagenes]="imagenes" (cambio)="changePhoto($event)" />
    </div>
    <div class="pr-1rem d-flex-column contenedor-corazon">
      <!-- <img class="img-size-p" src="{{imagenMostrar}}" alt=""> -->
      <lib-image-zoom [imageSrc]="imagenMostrar" />
      <lib-favorito-corazon [esGia]="false" [idProducto]="item.idProducto" [peso]="0" [referenciaProveedor]="''"
        class="favorito" />
      <div class="aclaracion">
        <span class="relative top-uno">{{getName('foto')}}</span>
        <span class="relative">{{getName('colorfoto')}}</span>
        <span class="relative">{{getName('tamfoto')}}</span>
      </div>
    </div>
    <div class="d-flex-column info-container pl-4rem-s">
      <div>
        <lib-detalles-producto [item]="item" [itemAnillo]="itemAnillo" [itemGia]="itemGia"
          [complementos]="complementos" />
      </div>
    </div>
  </div>

  @if (idProducto == '' && categoria !== 'busqueda') {
  <div>
    <lib-slider-card [title]="'Productos similares'" [info]="infoSimilares" />
  </div>
  }
</div>

<div class="d-block d-sm-block d-md-block d-lg-none espacioooooooooooooo">
  <div class="d-flex-row w-100 pt-5 pb-5">
    <lib-miga-pan [migas]="migas" [indicador]="'compra'" class="pl-2rem"></lib-miga-pan>
  </div>
  <div class="d-flex-column">
    <lib-detalles-producto [item]="item" [itemAnillo]="itemAnillo" [itemGia]="itemGia"
          [complementos]="complementos" />
  </div>
</div>
