<div class="contenedor-perfil">
  <div class="contenedor-datos-personales contenedor">
    <div class="cont-titulo">
      <h2 class="title pb-3 pt-5">DATOS PERSONALES</h2>
    </div>
    <div class="contenedor-datos">
      <div class="grid-info">
        <div class="titulo-icono">
          <span class="icon-User icon-size  color-icon"></span>
          <span class="titles">Nombre</span>
        </div>
        <div class="info">
          <span class="info">{{user.nombre}} {{user.apellido}}</span>
        </div>
      </div>
      <div class="grid-info">
        <div class="titulo-icono">
          <span class="icon-Cedula icon-size "></span>
          <span class="titles">Identificación</span>
        </div>
        <div class="info">
          <span class="info">{{user.numeroDocumento}}</span>
        </div>
      </div>
      <div class="grid-info">
        <div class="titulo-icono">
          <span class="icon-Telefono icon-size "></span>
          <span class="titles">Celular</span>
        </div>
        <div class="info">
          <span class="info">{{user.celular}}</span>
        </div>
      </div>
      <div class="grid-info">
        <div class="titulo-icono">
          <mat-icon class=""> mail_outline</mat-icon>
          <span class="titles">Correo</span>
        </div>
        <div class="info">
          <span class="info">{{user.correoElectronico}}</span>
        </div>
      </div>
      <div class="grid-info">
        <div class="titulo-icono">
          <span class="icon-Llave icon-size "></span>
          <span class="titles">Contraseña</span>
        </div>
        <div class="info">
          <span class="pass info"></span>
        </div>
      </div>
    </div>
    <div class="button-edit pb-5">
      <button (click)="editUser()">Editar informarción</button>
    </div>
  </div>
  <div class="contenedor-direcciones pt-5 contenedor">
    <app-modify-address [page]="false" />
  </div>
</div>
