import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, inject, signal, } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { Router } from '@angular/router';
import { DispositivoService, EstadosFiltrosService, filterActiveShow } from 'libreria-tienda';
import { MigaPanComponent } from 'libreria-tienda/shared/miga-pan/miga-pan.component';
import { ManejadorEventosService } from '../../../src/lib/services/manejador-eventos.service';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { ManejadorPaginacionGridService } from 'libreria-tienda/src/public-api';

@Component({
  selector: 'lib-menu-catalogo',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MigaPanComponent, MatChipsModule, MatIconModule],
  templateUrl: './menu-catalogo.component.html',
  styleUrl: './menu-catalogo.component.css'
})
export class MenuCatalogoComponent implements OnInit {


  fruits = ['Lemon', 'hola', 'Mandarina'];

  propiedad_filtro: number[] = [-1, -1]


  private subscriptionIdFiltrosAplicados: Subscription;


  // @Output() changeColumns = new EventEmitter<string>();
  // @Output() activeFiltros = new EventEmitter<boolean>();

  migas: string[] = [];

  public mobile: boolean = false;

  filtros_copia: any;

  @Input() categoria: string = "";
  @Input() filtros: any;

  @Output() abrirFiltros = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filtros']) {
      if (this.filtros.length > 0) {
        // console.log(this.filtros);
        this.filtros_copia = this.filtros;
        this.activarChips();
      }
    }
  }

  filtroUsuario: string = ""
  clase = "wrapper-product"

  filtersActiveTotal: number = 0
  cont = 1;

  idsFiltros: number[] = []

  filterActiveShow: Array<filterActiveShow> = new Array<filterActiveShow>();

  public manejadorPaginacionGridService = inject(ManejadorPaginacionGridService)
  public dispositivoService = inject(DispositivoService)
  public manejadorEventosService = inject(ManejadorEventosService)
  public estadosFiltrosService = inject(EstadosFiltrosService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.migas.push(this.categoria)
    if (typeof screen !== 'undefined') {
      if (screen.width < 1023) {
        this.mobile = true
      }
    }

    this.subscriptionIdFiltrosAplicados = this.estadosFiltrosService.idsFiltrosAplicados$.subscribe(value => {
      this.idsFiltros = value
      if (this.filtros.length > 0) {
        this.activarChips();
      }
    });
  }

  routing() {
    this.router.navigate(["/home"]).then(() => {
      window.scrollTo(0, 0);
    });
  }

  activarChips() {
    // console.log(this.idsFiltros);
    this.filtros = this.filtros_copia;
    for (let i = 0; i < this.filtros.length; i++) {
      // console.log(this.filtros[i]);
      for (let j = 0; j < this.filtros[i].valor.length; j++) {
        // console.log('ids: ', this.filtros[i].valor[j].idFiltroAplicable, 'filtro: ', this.filtros[i].valor[j].nombreWeb);
        for (let ids = 0; ids < this.idsFiltros.length; ids++) {
          if (this.idsFiltros[ids] == this.filtros[i].valor[j].idFiltroAplicable) {
            // console.log('Lo encontre');
            this.filtros[i].valor[j].select = true;
          }
        }
      }
    }
  }

  abriFiltros() {
    // console.log('abrir filtros');
    this.abrirFiltros.emit();
  }

  remove(filtro: number, i: number, j: number): void {
    this.estadosFiltrosService.updateFiltrosAplicadosMobile([i, j, filtro])
    // console.log(filtro, i, j);
  }

  changeOrder(event: Event) {
    const newOrder = (event.target as HTMLSelectElement).value;
    // console.log(newOrder);
    this.manejadorPaginacionGridService.setOrder(newOrder);
  }

}
