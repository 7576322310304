import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { caracteristicas, ConfigService, JsonTextService, TextResponse } from 'libreria-tienda';

@Component({
  selector: 'lib-descripcion-caracteristicas',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatDividerModule],
  templateUrl: './descripcion-caracteristicas.component.html',
  styleUrl: './descripcion-caracteristicas.component.css'
})
export class DescripcionCaracteristicasComponent implements OnChanges {

  @Input() caracteristicas: any;
  @Input() descripcion: string;

  jsonDetalle: TextResponse[]

  open: boolean = false;

  caracteristicasItem: caracteristicas[] = [];

  indexTono: number = -1;
  indexMetal: number = -1;

  tono_metal: boolean = false;

  reemplazos: any = {
    "am": "amarillo",
    "bl": "blanco",
    "rj": "rosa",
    "rs": "rosa"
  };

  public jsonService = inject(JsonTextService)
  public configService = inject(ConfigService)

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['caracteristicas']) {
      if (this.caracteristicas !== undefined) {
        this.jsonService.downloadJson(this.configService._config.URLJSONDETALLE).subscribe(res => {
          if (res !== undefined) {
            this.jsonDetalle = res;
          }
        })
        this.caracteristicasMostrar()
      }
    }
  }

  caracteristicasMostrar() {

    // console.log(this.caracteristicas[0]);

    this.caracteristicasItem = this.caracteristicas[0]
      .map((item: any) => {
        const valor = this.caracteristicas[1][item.db];
        return valor !== undefined ? { ...item, valor } : null;
      })
      .filter((item: any) => item !== null);
    // console.log(this.caracteristicasItem);

    this.indexTono = this.caracteristicasItem.findIndex(obj => obj.web === "Tono Metal");
    this.indexMetal = this.caracteristicasItem.findIndex(obj => obj.web === "Metal");
    // console.log(index);

    // console.log(this.indexTono);
    // console.log(this.indexMetal);

    if (this.indexTono !== -1 && this.indexMetal !== -1) {
      this.tono_metal = this.caracteristicasItem[this.indexTono].valor == this.caracteristicasItem[this.indexMetal].valor
    }


    if (this.indexTono !== -1) {
      // console.log(this.caracteristicasItem[indexTono].valor);
      switch (this.caracteristicasItem[this.indexTono].valor) {
        case "ACERO":
          break;
        case "PLATA":
          this.caracteristicasItem[this.indexTono].valor = "Plata Ley 925"
          break;

        default:
          this.caracteristicasItem[this.indexTono].valor = this.reemplazarFrase(this.caracteristicasItem[this.indexTono].valor)
          break;
      }
    }
    if (this.indexMetal !== -1) {
      // console.log(this.caracteristicasItem[index].valor);
      switch (this.caracteristicasItem[this.indexMetal].valor) {
        case "ACERO":
          break;
        case "PLATA":
          this.caracteristicasItem[this.indexMetal].valor = "Plata Ley 925"
          break;

        default:
          this.caracteristicasItem[this.indexMetal].valor = "Oro 18 Kilates"
          break;
      }
    }


    // console.log(this.caracteristicasItem[index].valor);
  }

  reemplazarFrase(frase: string) {
    // Encontrar la parte que contiene las siglas (después de "2 tonos ")
    // console.log(frase);

    let partesFrase = frase.split(" ");
    // console.log(partesFrase[0]);
    if (partesFrase[0] == '2') {
      let parteSiglas = partesFrase[2]; // Esto es "Am-BL"
      // console.log(parteSiglas);

      // Separar las siglas unidas por guiones
      let siglas = parteSiglas.split("-");

      // Reemplazar cada sigla con el valor correcto
      let siglasReemplazadas = siglas.map(sigla => {
        let siglaLowerCase = sigla.toLowerCase();
        return this.reemplazos[siglaLowerCase] || sigla; // Si no encuentra reemplazo, deja la palabra igual
      });

      // Unir las siglas reemplazadas con guiones
      let parteReemplazada = siglasReemplazadas.join(" - ");

      // Volver a armar la frase completa
      partesFrase[2] = parteReemplazada;
      return partesFrase.join(" ");
    } else if (frase == 'ROJO') {
      return 'rosa'
    }
    else {
      return frase
    }
  }

  getName(name: string) {
    return this.jsonDetalle?.find(x => x.Nombre == name)?.Valor
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true
  }



}
