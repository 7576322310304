<div class="d-none d-sm-none d-md-block d-lg-block">
  <div class="">
    <span class="reference">Ref. {{item.idProducto}}</span>
  </div>
  @if (item.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{item.precioFull | number}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">$ {{item.precioAutorizado |
      number}}</span>
    @if (item.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (item.porcentaje > 30 && item.porcentaje !==0) {
  <span>Precio exclusivo para ventas online</span>
  }

  <lib-descripcion-caracteristicas [caracteristicas]="caracteristicasMostrarObj" [descripcion]="item.descripcion" />

  <div class="d-flex-row gap pt-3">
    <!-- <lib-tallas [tallas]="tallas" [tallaSelectd]="item.talla || '-'" [submited]="submited" [codTallas]="codTallas" -->
    @if (itemAnillo) {
    <lib-tallas [submited]="submited" [tallas]="itemAnillo.TallasCantidad" (ItemData)="talla($event)"></lib-tallas>
    }
  </div>
  <div class="d-flex-row d-flex-center pt-2">
    <span class="icon-ring font-size-16"></span>
    <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
  </div>
  <div class="d-flex-column pt-3">
    <span class="cant pb-2">{{getName('cant')}}</span>
    <div class="d-flex-row">
      <button class="button-addremove" (click)="menos()">
        <mat-icon>remove</mat-icon>
      </button>
      @if (itemAnillo) {
      <input type="number" id="cant" #cant
        value="{{ itemAnillo.CantidadSeleccionada == 0 ? '-': itemAnillo.CantidadSeleccionada}}" min=1 name="" id=""
        placeholder="Cantidad" class="input-class input-cant" (keydown)="handleKeydown($event)" readonly>
      }
      <button class="button-addremove" (click)="mas()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  @if (itemAnillo) {
  @if (itemAnillo.TallaSeleccionada == '-' && submited || !selectSize) {
  <mat-error class="pt-3 d-flex-center">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
      para tu producto.</span>
  </mat-error>
  }
  }
  <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
      favor
      Comunicate con servicio al cliente</span>
  </mat-error>
  @if (complementos) {
  <div class="pt-3">
    <span class="cant pb-2">¿ Te gustaría completar tú compra ? </span>
    @if (complementos.length <=3) { <div class="d-flex-row pt-3">
      @for (item of complementos; track $index) {
      <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
      }
  </div>
  }@else {
  <lib-complemento-carrusel [productos]="complementos" (selectComple)="complemtoSeleCarrusel($event)" />
  }
</div>
}
<div class="d-flex-column gap pt-3">
  @if (itemAnillo.TallasCantidad.length !== 1) {
  <div class="d-flex-row d-flex-center">
    <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}
      <span class="icon-shoppingCart icon-size icon-color-b"></span></button>
  </div>
  }@else {
  <div class="contenedor-experto">
    <mat-icon class="dorado">error_outline</mat-icon>
    <span>
      Producto único en la compañía. Por favor comunícate con nuestras expertas al WhatsApp <span (click)="openChat()"
        class="link_whatsapp">3186338303</span>
    </span>
  </div>
  <button (click)="openChat()" class="btn-experto">{{getName('gia-but')}}</button>
  }
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('entrega')"> </span>
    <span class="link" [innerHTML]="getName('entrega_link')"></span>
  </div>
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('devoluciones')"> </span>
    <span class="link" [innerHTML]="getName('devoluciones_link')"></span>
  </div>
  <div class="iconos-sociales">
    <!-- <lib-favorito-corazon [esGia]="false" [idProducto]="item.idProducto" [peso]="0"
      [referenciaProveedor]="''" /> -->
    <i class="fa-brands fa-facebook-f iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-pinterest-p iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-whatsapp iconos-sociales-tamaño"></i>
  </div>
</div>
</div>

<!-- Mobile -->


<div class="d-block d-sm-block d-md-none d-lg-none contenedor-mobile">
  <div class="">
    <span class="reference">Ref. {{item.idProducto}}</span>
  </div>
  @if (item.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{item.precioFull | number}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">$ {{item.precioAutorizado |
      number}}</span>
    @if (item.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (item.porcentaje > 30 && item.porcentaje !==0) {
  <span class="exclusivo-ventas">Precio exclusivo para ventas online</span>
  }

  <lib-imagenes-mobile [IdProducto]="item.idProducto" />



  <lib-descripcion-caracteristicas [caracteristicas]="caracteristicasMostrarObj" [descripcion]="item.descripcion" />

  <div class="d-flex-row gap pt-3">
    <!-- <lib-tallas [tallas]="tallas" [tallaSelectd]="item.talla || '-'" [submited]="submited" [codTallas]="codTallas" -->
    @if (itemAnillo) {
    <lib-tallas [submited]="submited" [tallas]="itemAnillo.TallasCantidad" (ItemData)="talla($event)"></lib-tallas>
    }
  </div>
  <div class="d-flex-row d-flex-center pt-2">
    <span class="icon-ring font-size-16"></span>
    <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
  </div>
  <div class="d-flex-column pt-3">
    <span class="cant pb-2">{{getName('cant')}}</span>
    <div class="d-flex-row">
      <button class="button-addremove" (click)="menos()">
        <mat-icon>remove</mat-icon>
      </button>
      @if (itemAnillo) {
      <input type="number" id="cant" #cant
        value="{{ itemAnillo.CantidadSeleccionada == 0 ? '-': itemAnillo.CantidadSeleccionada}}" min=1 name="" id=""
        placeholder="Cantidad" class="input-class input-cant" (keydown)="handleKeydown($event)" readonly>
      }
      <button class="button-addremove" (click)="mas()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  @if (itemAnillo) {
  @if (itemAnillo.TallaSeleccionada == '-' && submited || !selectSize) {
  <mat-error class="pt-3 d-flex-center">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
      para tu producto.</span>
  </mat-error>
  }
  }
  <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
    <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
      favor
      Comunicate con servicio al cliente</span>
  </mat-error>
  @if (complementos) {
  <div class="pt-3">
    <span class="cant pb-2">¿ Te gustaría completar tú compra ? </span>
    @if (complementos.length <=3) { <div class="d-flex-row pt-3">
      @for (item of complementos; track $index) {
      <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
      }
  </div>
  }@else {
  <lib-complemento-carrusel [productos]="complementos" (selectComple)="complemtoSeleCarrusel($event)" />
  }
</div>
}
<div class="d-flex-column gap pt-3">
  @if (itemAnillo.TallasCantidad.length !== 1) {
  <div class="d-flex-row d-flex-center">
    <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}
      <span class="icon-shoppingCart icon-size icon-color-b"></span></button>
  </div>
  }@else {
  <div class="contenedor-experto">
    <mat-icon class="dorado">error_outline</mat-icon>
    <span>
      Producto único en la compañía. Por favor comunícate con nuestras expertas al WhatsApp <span (click)="openChat()"
        class="link_whatsapp">3186338303</span>
    </span>
  </div>
  <button (click)="openChat()" class="btn-experto">{{getName('gia-but')}}</button>
  }
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('entrega')"> </span>
    <span class="link" [innerHTML]="getName('entrega_link')"></span>
  </div>
  <div>
    <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('devoluciones')"> </span>
    <span class="link" [innerHTML]="getName('devoluciones_link')"></span>
  </div>
  <div class="iconos-sociales">
    <!-- <lib-favorito-corazon [esGia]="false" [idProducto]="item.idProducto" [peso]="0"
      [referenciaProveedor]="''" /> -->
    <i class="fa-brands fa-facebook-f iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-pinterest-p iconos-sociales-tamaño"></i>
    <i class="fa-brands fa-whatsapp iconos-sociales-tamaño"></i>
  </div>
</div>
</div>
