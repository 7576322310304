import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { DomicilioComponent } from 'libreria-tienda/shopping-cart/domicilio/domicilio.component';
import { PasosCompraComponent } from 'libreria-tienda/shopping-cart/pasos-compra/pasos-compra.component';
import { ResumenComponent } from 'libreria-tienda/shopping-cart/resumen/resumen.component';
import { RetiroTiendaComponent } from 'libreria-tienda/shopping-cart/retiro-tienda/retiro-tienda.component';
import { SendcodeComponent } from 'libreria-tienda/shopping-cart/sendcode/sendcode.component';
import { ConfigService, ControlSesionService, ItemCar, ItemCompra, itemRangosDeEntrega, LoadingService, Store, TipoEnvio, userlogged, UserService } from 'libreria-tienda';
import { AddressService } from 'libreria-tienda';
import { BuyServiceService } from 'libreria-tienda';
import { ExpressService, JsonTextService, ListaProductos, ShoppingCartService, SioServicesService, TextResponse, User, calculateTime, direccion, tiempoEntregaPeticion, time } from 'libreria-tienda';
import { MatDividerModule } from '@angular/material/divider';
import { ReceiveByComponent } from 'libreria-tienda/shopping-cart/domicilio/receive-by/receive-by.component';
import * as i0 from '@angular/core';

@Component({
  selector: 'app-shippings',
  standalone: true,
  imports: [CommonModule, MatTabsModule, ResumenComponent,
    PasosCompraComponent, DomicilioComponent, RetiroTiendaComponent
    , MatDividerModule, ReceiveByComponent, FormsModule],
  templateUrl: './shippings.component.html',
  styleUrl: './shippings.component.css'
})
export class ShippingsComponent implements OnInit {

  open = false;
  costoEnvio: number = 0

  userLog: userlogged = new userlogged()

  user: User = new User();
  isReceiveByMe: boolean = true;
  isReceiveByOther: boolean;
  direccion: direccion = new direccion();
  tienda: Store = new Store()
  message: any = {
    textBool: false,
    texto: null
  }

  isMessage: boolean;

  tabLabel = "DOMICILIO"
  isDireccion = false;
  isTienda = false;
  formDomicilioBool: boolean = false;
  formTiendaBool: boolean = false;
  tipoEnvio: TipoEnvio = new TipoEnvio()

  sendType: TipoEnvio = new TipoEnvio();

  isSendNormal: boolean = true;
  isSendExpress: boolean;

  envio: number = 1;

  listItemsCompra: ItemCompra[] = [];
  listItemsShoppingCart: ItemCar[] = [];

  shoppingCart: ItemCar[] = [];
  product: ListaProductos = new ListaProductos();
  listaproductos: ListaProductos[] = []
  direcciones: direccion[] = [];

  TiempoEntregaPeticion: calculateTime = new calculateTime()
  tiempoentregape: tiempoEntregaPeticion = new tiempoEntregaPeticion()

  time: time = new time()

  tiemposEntrega: any[] = [
    {
      ordenSecuencia: 1,
      rangoDeEntrega: "DIEZ_A_QUINCE_DIAS",
      tipoDeEntrega: "DOMICILIO"
    }
  ]

  selectEnvio: number = 0;

  public sesionService = inject(ControlSesionService)
  public cartService = inject(ShoppingCartService)
  public sioService = inject(SioServicesService)
  public buyService = inject(BuyServiceService)
  public load = inject(LoadingService)
  public userService = inject(UserService)
  public jsonService = inject(JsonTextService)
  public expressService = inject(ExpressService)
  public addresService = inject(AddressService)
  public configService = inject(ConfigService)

  constructor(public dialogo: MatDialog, private fb3: FormBuilder, private router: Router) {
  }
  jsonShippings: TextResponse[];

  ngOnInit() {
    this.load.cargar(true)
    this.userLog = this.userService.getUserLocal();
    this.jsonService.downloadJson(this.configService._config.URLSHIPPINGSJSON).subscribe(res => {
      this.jsonShippings = res
    })

    this.listItemsShoppingCart = this.cartService.getCart();
    this.informacionCarrito();
    this.userLog = this.userService.getUserLocal();

    this.userService.getUser().then(res => {
      this.user = res;
    }).catch(err => {
      this.sesionService.cerrarSesion();
    });

    this.buyService.newBuy.subscribe(buy => {
      if (buy) {
        // usuario
        this.buyService.sendType.nombreUser = this.userLog.nombre;
        this.buyService.sendType.apellidoUser = this.userLog.apellido;
        this.buyService.sendType.tipoDocumentoUser = this.user.tipoDocumento;
        this.buyService.sendType.numeroDocumentoUser = this.user.numeroDocumento;
        this.buyService.sendType.numeroContacto = this.user.celular;
        this.buyService.sendType.correo = this.user.correoElectronico;
        // this.buyService.sendType.yoRecibo = this.isReceiveByMe;
        this.buyService.sendType.mensajePersonalizado = this.message.texto;

        this.buyService.sendType.metodo = this.tabLabel;

        if (this.tabLabel == "DOMICILIO") {
          if (this.isDireccion) {
            this.buyService.sendType.direccion = this.direccion.direccion;
            // this.buyService.sendType.mensajePersonalizado = this.message.textBool ? this.message.texto : "";

            // locacion
            this.buyService.sendType.idDepto = this.direccion.departamento.idDepto;
            this.buyService.sendType.idMunicipio = this.direccion.ciudad.idMunicipio;
            this.buyService.sendType.nombreDepto = this.direccion.departamento.departamentoNombre;
            this.buyService.sendType.nombreMunicipio = this.direccion.ciudad.municipioNombre;

            // Costo Envio
            this.buyService.sendType.envioNormal = this.sendType.envioNormal;
            this.buyService.sendType.envioExpress = this.sendType.envioExpress;
            this.buyService.sendType.tiempoEntrega = this.sendType.tiempoEntrega;
          } else {
            this.buyService.formValid(false, "No tiene una dirección de envio, para continuar con el proceso registre una dirección donde se entregarán sus productos")
          }
        } else {
          this.buyService.sendType.direccion = null
          this.buyService.sendType.idDepto = null
          this.buyService.sendType.idMunicipio = null
          this.buyService.sendType.nombreDepto = null
          this.buyService.sendType.nombreMunicipio = null
          this.buyService.sendType.envioNormal = null
          this.buyService.sendType.envioExpress = null
          if (this.isTienda) {
            this.buyService.sendType.idCentroCosto = this.tienda.codPuntoChr + ''
            this.buyService.sendType.nombreTienda = this.tienda.nombre
          } else {
            // this.openDialogMessage("No ha seleccionado una tienda","Para continuar con el proceso seleccione la tienda donde recogerá sus productos")
            this.buyService.formValid(false, "No ha seleccionado una tienda, para continuar con el proceso seleccione la tienda donde recogerá sus productos")
          }
        }
      }

    })

  }

  recibo(e: any) {
    this.isReceiveByMe = e;
  }

  newMensaje(e: any) {
    if (e.textBool) {
      this.message = e;
    } else {
      this.message.textBool = false;
    }
  }

  direccionPrin(e: any) {
    if (e.direccion) {
      this.isDireccion = true;
      this.direccion = e
      this.getTiempoEnvio();
    }
  }

  tiendaRecoge(e: any) {
    console.log(e);
    console.log(e.abierto == "1");

    if (e.abierto == "1") {
      this.isTienda = true;
      this.tienda = e
    }
  }

  informacionCarrito() {
    this.sioService.getInfoArticulosCarrito(this.listItemsShoppingCart).then((resultado) => {
      if (resultado.exitoso) {
        this.listItemsCompra = resultado.valor;
        // console.log(this.listItemsCompra);
        this.cartService.saveCarritoCompra(this.listItemsCompra)
        this.load.cargar(false);
      }
    }).catch((error) => {
      // window.location.reload();
    });
  }

  getTiempoEnvio() {
    let carroConsulta: itemRangosDeEntrega[] = [];

    for (let i = 0; i < this.listItemsShoppingCart.length; i++) {
      let aux: itemRangosDeEntrega = new itemRangosDeEntrega();
      aux.CodigoProducto = this.listItemsShoppingCart[i].idProducto;
      aux.CantidadSolicitada = this.listItemsShoppingCart[i].cantidad;
      aux.TallaId = this.listItemsShoppingCart[i].tallaId;
      carroConsulta.push(aux)
    }

    this.sioService.getOpcionesRangosDeEntrega(carroConsulta, this.direccion.departamento.departamentoNombre).then((resultado: any[]) => {

      // console.log(resultado);
      this.tiemposEntrega = resultado.filter(resultado => resultado.tipoDeEntrega === "DOMICILIO")
      // console.log(this.tiemposEntrega);
      if (this.tiemposEntrega.length == 1) {
        this.sendType.envioNormal = true;
        this.sendType.envioExpress = false;
      } else {
        this.sendType.envioNormal = false;
        this.sendType.envioExpress = true;
      }

    }).catch((error) => {
      console.log(error);
      this.sendType.envioNormal = true;
      this.sendType.envioExpress = false;
      this.tiemposEntrega = [{
        ordenSecuencia: 1,
        rangoDeEntrega: "DIEZ_A_QUINCE_DIAS",
        tipoDeEntrega: "DOMICILIO"
      }]

      // window.location.reload();
    });
  }

  getName(name: string) {
    return this.jsonShippings?.find(x => x.Nombre == name)?.Valor
  }

  onTabChange(event: MatTabChangeEvent) {
    this.tabLabel = event.tab.textLabel
    // console.log(this.tabLabel);
  }

  onPanelClosed() {
    this.open = false
  }

  onPanelOpened() {
    this.open = true
  }

  changePrice(event: any) {
    this.costoEnvio = event
  }

  routing() {
    this.openDialogMessageSendcode()
  }

  openDialogMessageSendcode(): void {
    const dialogRef = this.dialogo.open(SendcodeComponent, { panelClass: 'custom-dialog-container' })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.router.navigate(["/modify-address"])
          console.log('Quedo bien la validación con el correo');
        }
      })
  }

  sendTypeEmit(event: TipoEnvio) {
    this.sendType = event
  }

  onChangeEntrega(index: number) {
    this.selectedOptionIndex = index;
    if (this.tiemposEntrega.length == 2) {
      switch (index) {
        case 0:
          this.isSendExpress = true
          this.sendType.envioExpress = true;
          this.sendType.envioNormal = false;
          break;

        default:
          this.sendType.envioExpress = false
          this.sendType.envioNormal = true
          break;
      }
    } else {
      this.sendType.envioExpress = false;
      this.sendType.envioNormal = true;
    }
    this.sendType.tiempoEntrega = this.tiemposEntrega[index].rangoDeEntrega
  }

  separarPalabras(frase: string): string {
    if (frase == undefined) {
      return "Diez a quince dias"
    } else {
      let palabras = frase.split('_').join(' ');
      return palabras
    }
  }

  selectedOptionIndex: number | null = 0; // Índice del checkbox seleccionado (por defecto el primero)

  isChecked(index: number): boolean {
    return this.selectedOptionIndex === index; // Devuelve si el índice está seleccionado
  }
}
