import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManejadorPaginacionGridService {
  private orderSignal = signal<string>('desc');
  private paginaSignal = signal<number>(1);

  // Getter para exponer el Signal
  get order() {
    return this.orderSignal;
  }

  // Setter para actualizar el Signal
  setOrder(newOrder: string) {
    this.orderSignal.set(newOrder);
    this.setPagina(1); // Resetea la página al cambiar el orden
  }

  // Getter para exponer el Signal
  get pagina() {
    return this.paginaSignal;
  }

  // Setter para actualizar el Signal
  setPagina(newPage: number) {
    this.paginaSignal.set(newPage);
  }
}
