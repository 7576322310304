import { Component, Input, OnInit, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ControlSesionService, ExpressService, LoadingService, ShoppingCartService, User, userlogged, UserService } from 'libreria-tienda';
import { AddressComponent } from 'libreria-tienda/shopping-cart/address/address.component';
import { ModifyAddressComponent } from '../../../../web-tienda/src/app/pages/modify-address/modify-address.component';

@Component({
  selector: 'lib-info-perfil',
  standalone: true,
  imports: [MatIconModule, ModifyAddressComponent],
  templateUrl: './info-perfil.component.html',
  styleUrl: './info-perfil.component.css'
})
export class InfoPerfilComponent implements OnInit {

  user: User = new User()
  userLog: userlogged = new userlogged()

  sesionService = inject(ControlSesionService)
  userService = inject(UserService)

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.userLog = this.userService.getUserLocal();

    this.userService.getUser().then((res) => {
      // console.log(res);
      this.user = res
    }).catch(error => {
      this.sesionService.cerrarSesion()
    })
  }

  editUser() {
    this.router.navigate(['edit-perfil']).then(() => {
      window.scrollTo(0, 0);
    });
  }

}
