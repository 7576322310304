<div class="">
  <lib-pasos-compra [paso]="2" />
  <div class="pl-pr-100 back-img d-flex-row space-around pt-5 pb-5 contenedor-shipping">
    <div class="w-60">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="DOMICILIO">
          <div>
            <lib-domicilio (direccionPrin)="direccionPrin($event)" />
          </div>
        </mat-tab>
        <mat-tab label="RETIRO EN TIENDA">
          <div>
            <lib-retiro-tienda (tiendaRecoge)="tiendaRecoge($event)" />
          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="contenedor-cond-entrega">
        <mat-divider></mat-divider>
        <lib-receive-by [recibeYo]="getName('recibe-yo')" [recibeOtro]="getName('recibe-otro')"
          [quienRecibe]="getName('quien-recibe')" [guardar]="getName('but-guardar')"
          (sendTypeEmit)="sendTypeEmit($event)" />
        <mat-divider></mat-divider>

        <div class="pt-3">
          <h2 class="titles">{{getName('metodo entrega')}}</h2>
          <div class="d-flex-row pl-35px pb-3">
            <div class="d-flex-row col-6 align-center contenedor-envio">
              @for (item of tiemposEntrega; track $index) {
                <div class="opcion-envio">
                  <label class="checkbox-container">
                    <input type="radio" name="envioOptions"
                      class="pr-1 envios"
                      [checked]="isChecked($index)"
                      (change)="onChangeEntrega($index)">
                    <span class="entrega">
                      {{ separarPalabras(item.rangoDeEntrega) }}
                    </span>
                    <span class="checkmark"></span>
                  </label>
                  <mat-divider vertical style="height:22px; width: 12px;" class=""></mat-divider>
                  <span class="titles">${{ costoEnvio | number }}</span>
                  <mat-divider vertical style="height:22px; width: 12px;" class="pr-1"></mat-divider>
                  <span class="info pl-1 no-wrap">{{ item.diasMaximo }} días hábiles</span>
                </div>
              }
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="contenedor-mensaje pl-35px pb-3">

            <div class="check-label">
              <label class="checkbox-container">
                <input type="checkbox" class="pr-1" [(ngModel)]="isMessage">

                <span class="checkmark center-check"></span>
              </label>
              <span class="titles pl-0 no-wrap break-spaces pt-6">{{getName('mensaje')}}</span>
            </div>
            @if (isMessage) {
            <textarea [(ngModel)]="message.texto" class="w-100 text-message pl-2rem" type="text"
              placeholder="Escribe tu mensaje">
              </textarea>
            }

          </div>
        </div>


      </div>
    </div>
    <div class="pl-1 pr-10r">
      <lib-resumen [section]="2" [envio]="-1" [carrito]="listItemsCompra" (valorEmit)="changePrice($event)" />
    </div>
  </div>
</div>
