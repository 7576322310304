import { Component, Inject, inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { Router } from '@angular/router';
import { LoadingService } from 'libreria-tienda';

@Component({
  selector: 'lib-popup-correo-registro',
  standalone: true,
  imports: [MatPseudoCheckboxModule, FormsModule, MatDialogClose],
  templateUrl: './popup-correo-registro.component.html',
  styleUrl: './popup-correo-registro.component.css'
})
export class PopupCorreoRegistroComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { }
  load = inject(LoadingService)

  redirect() {
    this.router.navigate(['/login']).then(() => {
      console.log('Redirección completada');
      window.scrollTo(0, 0);
      this.load.cargar(false)
    });
  }


}
