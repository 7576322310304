<lib-banners [catalogo]="filtro" [noEncontrado]="noEncontrado" />
<lib-menu-catalogo [categoria]="filtro" [filtros]="filtrosCatalogo" (abrirFiltros)="popup.open()" />
<div class="conetenedor-columnas">
  <div class="columna-filtros">
    <lib-side-bar-filtro [codigo]="filtro" (filtrosCategoria)="filtros($event)"/>
  </div>
  <div class="columna-catalogo">
    <div [ngClass]="{'catalogo-init-mobile':dispositivoService.isMobile, 'catalogo-init':!dispositivoService.isMobile}">
      <lib-grid-catalogo [categoria]="filtro" [busqueda]="idsBusqueda"
      (noEncontrado)="noEncontradoBanner($event)" />
    </div>
  </div>
  <lib-pop-up-bottom #popup>
    <lib-side-bar-filtro [codigo]="filtro" />
  </lib-pop-up-bottom>
  <!-- <button (click)="popup.open()">Abrir Pop-Up</button> -->
</div>
