import { CommonModule } from '@angular/common';
import { Component, inject, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ConfigService, ExpressService, LoadingService } from 'libreria-tienda';

@Component({
  selector: 'app-olvido-password',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './olvido-password.component.html',
  styleUrl: './olvido-password.component.css'
})
export class OlvidoPasswordComponent {
  form: FormGroup;

  load = inject(LoadingService)
  expressService = inject(ExpressService)
  route = inject(Router)
  public configService = inject(ConfigService)

  constructor(private fb: FormBuilder, private renderer: Renderer2) {
    this.load.cargar(false)
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    })
  }

  isSendMail: boolean = false;
  submited: boolean = false;
  user: boolean = true;
  email: string = "";
  res: boolean = false;
  ngOnInit(): void {
  }

  sendMail() {
    this.submited = true
    if (this.form.get('email')?.value != '') {
      if (this.form.valid) {
        this.load.cargar(true)
        this.email = this.form.get('email')?.value
        this.expressService.forgotPassword(this.email).then(res => {
          console.log(res);
          this.isSendMail = true;
          this.user = true
          this.res = true
          this.load.cargar(false)
        }).catch(error => {
          // console.log(error);
          if (error.error.message == "Usuario no encontrado") {
            this.load.cargar(false);
            this.isSendMail = false;
            this.user = false
            this.res = false
          }
        })
      }
    } else {
      console.log("no valido")
      this.form.markAllAsTouched();
    }
  }
  routing(section: string) {
    this.route.navigate([section])
  }

  whatsapp(){
    const enlaceWhatsApp = this.configService._config.WHATSAPP;
    window.open(enlaceWhatsApp, '_blank');
  }
}
