import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, effect, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DispositivoService, EstadosFiltrosService, InstantSearchService, JsonTextService, LoadingService, SioServicesService, favorite } from 'libreria-tienda';
import { ManejadorPaginacionGridService } from 'libreria-tienda/src/public-api';
import { Subscription } from 'rxjs';

class pagina {
  id: number;
  select: boolean = false
}

@Component({
  selector: 'lib-paginador',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './paginador.component.html',
  styleUrl: './paginador.component.css'
})
export class PaginadorComponent implements OnInit, AfterViewInit {

  // @Output() paginaOut = new EventEmitter<number>();
  @Input() categoria: string;
  @Input() fav: favorite[];

  @Input() _max: number = 0;
  @Input() _min: number = 0;


  _filtros: any[] = [];
  @Input() set filtros(value: any) {
    if (value !== this._filtros) {
      this.pagina = 1
      this.paginasMostrar = [];
      this.contarPaginas(40, value);
      this._filtros = value;
    }
  }

  // @Input() orden: string;

  total: number = 0;

  pagina: number;

  max: number;

  paginas: pagina[];

  paginasMostrar: pagina[] = [];

  diamante: string = "https://qastorageorafa.blob.core.windows.net/icono-paginador/diamond_ico.svg"

  private subscriptionFiltrosAplicados: Subscription;

  public manejadorPaginacionGridService = inject(ManejadorPaginacionGridService)
  // public instantSearchService = inject(InstantSearchService)
  public estadosFiltrosService = inject(EstadosFiltrosService)
  public jsonService = inject(JsonTextService)
  public dipositivoService = inject(DispositivoService)
  public sioservice = inject(SioServicesService)
  public load = inject(LoadingService)

  ordenCatalogo = this.manejadorPaginacionGridService.order;


  constructor() {
    effect(
      () => {
        // console.log('Orden', this.ordenCatalogo());
        this.pageSelect(1);
      },
      { allowSignalWrites: true } // Permite escribir señales dentro del effect
    );
  }

  ngOnInit() {
    // this.load.cargar(true);
    if (typeof localStorage !== undefined) {
      if (this.categoria == 'busqueda') {
        // this.contarPaginaPaso2(this.instantSearchService.itemsTotal)
      } else {
        if (localStorage.getItem("posicion") != undefined) {
          var posicion = JSON.parse(localStorage.getItem("posicion") || "")
          // this.pagina = posicion.pagina;
          if (this.categoria == posicion.codigo) {
            this.pagina = posicion.pagina
          } else {
            this.pagina = 1;
          }
        } else {
          this.pagina = 1;
          // console.log('No hay posicion');
        }
        // if (localStorage.getItem("columns") != undefined) {
        //   this.contarPaginas(JSON.parse(localStorage.getItem("columns") || ""), this._filtros)
        // } else {
        this.contarPaginas(40, this._filtros)
        // }
      }
    } else {
      this.pagina = 1;
      this.contarPaginas(40, this._filtros)
    }

    this.subscriptionFiltrosAplicados = this.estadosFiltrosService.filtrosAplicados$.subscribe(value => {
      if (value.precioMax !== this._max || value.precioMin !== this._min) {
        // this.pagina = 1
        // console.log(value.precioMin, value.precioMax);

        this.contarPaginas(40, this._filtros, value.precioMin, value.precioMax)
        this._max = value.precioMax;
        this._min = value.precioMin;
      }
    });
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['orden']) {
  //     if (this.orden !== undefined && this.orden !== 'default') {
  //       console.log('cambio', this.orden);

  //       this.pagina = 1
  //       this.paginasAmostrar()
  //     }
  //   }
  // }

  ngAfterViewInit(): void {

  }

  contarPaginas(columnas: number = 40, filtros: any[] = [], precioMin: number = this._min, precioMax: number = this._max) {
    if (this.categoria == 'Favoritos') {
      this.contarPaginaPaso2(this.fav.length, columnas)
    } else {
      this.load.cargar(true)
      this.sioservice.getResumenArticulosPaginados(this.categoria, filtros, precioMin, precioMax).then((res) => {
        // console.log(res);
        if (res.exitoso) {
          // console.log(res.valor);
          this.estadosFiltrosService.updatePreciosConsulta(res.valor.precioMinimo, res.valor.precioMaximo)
          // if (res.valor.filtros !== null) {
          this.estadosFiltrosService.updateFiltrosDispopnibles(res.valor.filtros);
          // }
          this.contarPaginaPaso2(res.valor.totalArticulosAgrupadosReferenciaProveedorYPiedra, columnas);
        } else {
          console.log('Error en la consulta');
        }
        this.load.cargar(false)
      }).catch((error) => {
        console.error(error);
        this.load.cargar(false);
      });
    }

  }

  contarPaginaPaso2(total: number, columnas: number = 40) {
    // console.log(total, columnas);
    if (total > 0) {
      this.total = 0;
      this.total = total
      this.max = 0;
      this.max = Math.ceil(this.total / columnas);
      this.paginas = [];
      this.paginas = Array.from({ length: this.max }, (_, index) => {
        const nuevaPagina = new pagina();
        nuevaPagina.id = index + 1;
        return nuevaPagina;
      });

      this.paginas[this.pagina - 1].select = true
      this.paginasAmostrar();
    } else {
      console.log('No hay productos para mostrar');

    }
  }

  paginasAmostrar() {
    if (this.pagina >= 1 && this.pagina <= this.max) {
      // console.log('SI sed ejecvuta');
      this.paginasMostrar = [];
      if ((this.pagina) <= 2) {
        this.paginasMostrar = this.paginas.slice(0, 5)
      } else {
        this.paginasMostrar = this.paginas.slice(this.pagina - 3, this.pagina + 2);
      }
    }
  }

  async pageSelect(i: number) {
    if (i != this.pagina) {
      // console.log(this.pagina);
      this.paginas[this.pagina - 1].select = false;
      this.pagina = i
      // this.paginaOut.emit(this.pagina)
      this.manejadorPaginacionGridService.setPagina(this.pagina)
      // console.log(this.pagina);
      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true;
    }
    window.scrollTo(0, 0);
  }

  async next() {
    if (this.pagina <= this.max - 1) {
      this.paginas[this.pagina - 1].select = false;
      this.pagina++;
      // this.paginaOut.emit(this.pagina)
      this.manejadorPaginacionGridService.setPagina(this.pagina)
      window.scrollTo(0, 0);
      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true
    }
  }

  async back() {
    if (this.pagina - 1 !== 0) {
      this.paginas[this.pagina - 1].select = false;
      this.pagina--;
      window.scrollTo(0, 0);
      // this.paginaOut.emit(this.pagina)
      this.manejadorPaginacionGridService.setPagina(this.pagina)
      await this.paginasAmostrar()
      this.paginas[this.pagina - 1].select = true
    }
  }

}
