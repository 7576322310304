<div class="d-flex-center d-flex-column pt-5 pb-5 back-img">
  <!-- <div class="d-flex-row d-flex-center">
    <span class="icon-ring font-size"></span>
    <h2 class="title m-0 pl-1rem">{{getName('title')}}</h2>
  </div> -->
  <!-- <span class="subtitle">{{getName('subtitle')}}</span> -->
  <div class="contenedor-map-info pl-1 w-100cont">
    <div class="find-column pl-1 w-50-r w-100-r w29">
      <div class="">
        <mat-form-field appearance="fill" class="">
          <mat-select (selectionChange)="citieSelected($event)" placeholder="Selecciona tu ciudad" required>
            <mat-option *ngFor="let citie of listCities" [value]="citie">
              {{citie | titlecase}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      @if (citie !=="") {
      <div>
        <mat-form-field appearance="fill" class="">
          <mat-select (selectionChange)="pointSelected($event)" placeholder="Selecciona la tienda" required>
            <div *ngFor="let point of stores">
              <mat-option *ngIf="point.ciudad == citie && point.mostrarEnPagina == 1" [value]="point">
                {{point.nombre | titlecase}}
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="pt-3" *ngIf="!emtpyob">
        <div class="grid-points pt-3">
          <div class="icon-grid pr-1">
            <span class="icon-Icono_Tienda icon-size-22px icon-black"></span>
          </div>
          <div class="name-point pl-1rem">
            <span class="text-title">{{store.nombre}}</span>
          </div>
          <div class="direccion pl-1rem">
            <span class="text">{{store.direccion}}</span>
          </div>
          <div class="local pl-1rem">
            <span class="text">Local {{store.local}}.</span>
          </div>
        </div>
        <div class="d-flex-row pt-3">
          <div>
            <span class="icon-Icono_reloj icon-size-22px icon-black"></span>
          </div>
          <div class="d-flex-column pl-1rem">
            <div class="">
              <span class="text-title">Horarios de atención</span>
            </div>
            <div class="">
              <span class="break-spaces text">{{store.horario}}</span>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="cont" id="map"></div>
  </div>
  <!-- <lib-receive-by [recibeYo]="getName('recibe-yo')" [recibeOtro]="getName('recibe-otro')"
    [quienRecibe]="getName('quien-recibe')" [guardar]="getName('but-guardar')" (sendTypeEmit)="sendTypeEmit($event)"
    (recibeYoEmit)="recibeYo($event)" (recibeOtroEmit)="recibeOtro($event)" /> -->
</div>
