<section class="container">
  <div class="slider-wrapper">
    <div class="slider" #slider>
      @for (imagen of imagenes; track $index) {
      @if (!imagen.error) {
      <img [id]="'slide-'+$index+1" [src]="imagen.img" alt="">
      }
      }
      <!-- <img id="slide-1" [src]="slides[0].image" alt="">
      <img id="slide-2" [src]="slides[1].image" alt="">
      <img id="slide-3" [src]="slides[2].image" alt=""> -->
    </div>
    <div class="slider-nav">
      @for (imagen of imagenes; track $index) {
      @if (!imagen.error) {
      <a href="#" #navLink [attr.data-slide]="$index" [class.active]="$index === 0"></a>
      }
      }
      <!-- <a href="#" #navLink data-slide="0" class="active"></a>
      <a href="#" #navLink data-slide="1"></a>
      <a href="#" #navLink data-slide="2"></a> -->
    </div>
  </div>
</section>
