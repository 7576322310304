<div class="d-none d-sm-none d-md-block d-lg-block">
  @if (!giaSeleccionado) {
  <div class="pt-3">
    <span class="reference">{{getName('gia-desde')}}</span>
  </div>
  <div>
    <span class="price price-auto">$ {{precioMinimo | numeroPunto}}</span>
  </div>
  }@else {
  <div class="pt-3">
    <span class="reference">Ref. {{infoGiaSeleccionado.idProducto}}</span>
  </div>
  @if (infoGiaSeleccionado.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{infoGiaSeleccionado.precioFull | numeroPunto}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':infoGiaSeleccionado.porcentaje !== 0}">$
      {{infoGiaSeleccionado.precioAutorizado | numeroPunto}}</span>
    @if (infoGiaSeleccionado.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="infoGiaSeleccionado.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (infoGiaSeleccionado.porcentaje < 15 && infoGiaSeleccionado.porcentaje !==0) { <span>Precio exclusivo para ventas
    online</span> }
    }
    <div>
      <span class="price frase">{{getName('gia-title-1')}}</span>
    </div>
    <div>
      <span class="info-gia">{{getName('gia-info-1')}}</span>
    </div>
    <div class="d-flex-row gap pt-3">
      <lib-tallas [tallas]="tallas" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.talla"
        (ItemData)="talla($event)"></lib-tallas>
    </div>
    <div class="d-flex-row d-flex-center pt-2">
      <span class="icon-ring font-size-16"></span>
      <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
    </div>
    @if (tallaSelec || giaSeleccionado) {
    <div class="d-flex-row gap pt-3">
      <lib-referencias [tallas]="referencias" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.idProducto"
        (ItemData)="referencia($event)"></lib-referencias>
    </div>
    }

    <lib-descripcion-caracteristicas [caracteristicas]="caracteristicasMostrarObj"
      [descripcion]="item[0].descripcion" />

    <mat-error class="pt-3 d-flex-center" *ngIf="(infoGiaSeleccionado.talla == '-' && submited) || !selectSize">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
        para tu producto.</span>
    </mat-error>
    <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
        favor
        Comunicate con servicio al cliente</span>
    </mat-error>
    <div class="pt-3 pr-2rem">
      <div class="pb-3">
        <span class="titles-gia">{{getName('gia-title-2')}}</span>
      </div>
      <div class="pb-3 pr-2rem">
        <span class="info-gia">{{getName('gia-info-2')}}</span>
      </div>
      <button (click)="openChat()" class="button-secondary">{{getName('gia-but')}}</button>
    </div>
</div>


<!-- Mobile -->

<div class="d-block d-sm-block d-md-none d-lg-none contenedor-mobile">
  @if (!giaSeleccionado) {
  <div class="pt-3">
    <span class="reference">{{getName('gia-desde')}}</span>
  </div>
  <div>
    <span class="price price-auto">$ {{precioMinimo | numeroPunto}}</span>
  </div>
  }@else {
  <div class="pt-3">
    <span class="reference">Ref. {{infoGiaSeleccionado.idProducto}}</span>
  </div>
  @if (infoGiaSeleccionado.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{infoGiaSeleccionado.precioFull | numeroPunto}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':infoGiaSeleccionado.porcentaje !== 0}">$
      {{infoGiaSeleccionado.precioAutorizado | numeroPunto}}</span>
    @if (infoGiaSeleccionado.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="infoGiaSeleccionado.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (infoGiaSeleccionado.porcentaje < 15 && infoGiaSeleccionado.porcentaje !==0) { <span>Precio exclusivo para ventas
    online</span> }
    }
    <div>
      <span class="price price-auto">{{getName('gia-title-1')}}</span>
    </div>
    <div>
      <span class="info-gia">{{getName('gia-info-1')}}</span>
    </div>

    <lib-imagenes-mobile [IdProducto]="item[0].idProducto" />

    <lib-descripcion-caracteristicas [caracteristicas]="caracteristicasMostrarObj"
      [descripcion]="item[0].descripcion" />

    <div class="d-flex-row gap pt-3">
      <lib-tallas [tallas]="tallas" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.talla"
        (ItemData)="talla($event)"></lib-tallas>
    </div>
    <div class="d-flex-row d-flex-center pt-2">
      <span class="icon-ring font-size-16"></span>
      <span (click)="donwloadManual()" class="manual cursor-pointer pl-05rem">{{getName('manual-tallas')}}</span>
    </div>
    @if (tallaSelec || giaSeleccionado) {
    <div class="d-flex-row gap pt-3">
      <lib-referencias [tallas]="referencias" [submited]="submited" [tallaSelectd]="infoGiaSeleccionado.idProducto"
        (ItemData)="referencia($event)"></lib-referencias>
    </div>
    }
    <mat-error class="pt-3 d-flex-center" *ngIf="(infoGiaSeleccionado.talla == '-' && submited) || !selectSize">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Debes seleccionar una talla
        para tu producto.</span>
    </mat-error>
    <mat-error class="pt-3 d-flex-center" *ngIf="unico && submited">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
        favor
        Comunicate con servicio al cliente</span>
    </mat-error>
    <div class="pt-3 pr-2rem">
      <div class="pb-3">
        <span class="titles-gia">{{getName('gia-title-2')}}</span>
      </div>
      <div class="pb-3 pr-2rem">
        <span class="info-gia">{{getName('gia-info-2')}}</span>
      </div>
      <button (click)="openChat()" class="button-secondary">{{getName('gia-but')}}</button>
    </div>
</div>
