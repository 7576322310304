import { CommonModule, Location } from '@angular/common';
import { AfterViewInit, Component, inject, OnDestroy, OnInit } from '@angular/core'; import { AddressService, Deptos, ExpressService, LoadingService, Municipes, SioServicesService, User, UserService, direccion, userlogged } from 'libreria-tienda';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UpdateUserComponent } from 'libreria-tienda/shopping-cart/update-user/update-user.component';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-edit-address',
  standalone: true,
  imports: [CommonModule, MatSelectModule, ReactiveFormsModule],
  templateUrl: './edit-address.component.html',
  styleUrl: './edit-address.component.css'
})
export class EditAddressComponent implements OnInit, AfterViewInit, OnDestroy {

  usuario: userlogged = new userlogged();

  direccion: direccion = new direccion()
  form: FormGroup;
  listDeptos: Deptos[] = new Array<Deptos>();
  listMunicipes: Municipes[] = new Array<Municipes>();
  submited: boolean = false;
  depto2: Deptos = new Deptos();
  municipe2: Municipes;
  direcciones: Array<direccion> = new Array<direccion>()
  index: number = -1;

  userService = inject(UserService)
  load = inject(LoadingService)
  addresService = inject(AddressService)
  sioService = inject(SioServicesService)
  expressService = inject(ExpressService)
  dialogo = inject(MatDialog)

  private fb = inject(FormBuilder)
  private router = inject(Router)

  constructor(private _location: Location) {
    // this.userService.getUserDireccion().then((res) => {
    //   this.usuario = res;
    //   this.inicializar()
    // })
    this.form = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      celular: ['', Validators.required],
      municipio: ['', Validators.required],
      ciudad: ['', Validators.required],
      direccion: ['', Validators.required],
      informacionAdicional: ['', Validators.required],
    })
  }

  async ngOnInit() {

    this.usuario = this.userService.getUserLocal();
    // console.log(this.usuario);

    this.direccion = this.addresService.getDireccion();
    console.log(this.direccion);

    if (!this.direccion.nombres) {
      await this.userService.getUserDireccion().then(res => {
        // console.log(res);
        this.direcciones = res
        console.log(this.direcciones);

      }).catch(error => {
        this.router.navigate(['modify-address']);
      })

      if (this.direcciones.length == 0) {
        this.router.navigate(['modify-address']);
      }

      this.index = this.direcciones.findIndex(x => x.editando === true)
      if (!this.index) {
        this.router.navigate(['modify-address']);
      }
      this.direccion = this.direcciones[this.index]
    } else {
      this.index = this.addresService.getIndex();
    }
    await this.sioService.GetDataListCommand("Departamentos", "", []).subscribe((deptos: Deptos[]) => {
      this.listDeptos = deptos
      // this.inicializar()
    })

  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  async inicializar() {
    try {
      this.municipe2 = this.direccion.ciudad;
      this.depto2 = this.direccion.departamento;

      let indexDeps = this.listDeptos.findIndex(x => x.idDepto == this.depto2.idDepto)

      this.form.get("nombres")?.setValue(this.direccion.nombres)
      this.form.get("apellidos")?.setValue(this.direccion.apellidos)
      this.form.get("celular")?.setValue(this.direccion.celular)
      this.form.get("ciudad")?.setValue(this.depto2)
      this.form.get("municipio")?.setValue(this.municipe2)

      this.depto2 = this.listDeptos.find(x => x.departamentoNombre == this.direccion.departamento.departamentoNombre) || this.depto2
      this.form.get("ciudad")?.setValue(this.depto2)
      await this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [this.depto2.idDepto]).subscribe((municipe2: Municipes[]) => {
        this.listMunicipes = municipe2
        this.municipe2 = this.listMunicipes.find(x => x.municipioNombre == this.direccion.ciudad.municipioNombre) || this.municipe2
        this.form.get("municipio")?.setValue(this.municipe2)
      })
      this.form.get("direccion")?.setValue(this.direccion.direccion)
      this.form.get("informacionAdicional")?.setValue(this.direccion.descripcion)
      this.load.cargar(false)
    } catch (error) {
      console.error(error);

      this.router.navigate(['perfil']).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }

  municipeSelected(event: any) {
    this.municipe2 = event.value
    this.form.controls['municipio'].setValue(event.value);
  }

  deptoSelected(event: any) {
    this.depto2 = event.value
    this.form.controls['ciudad'].setValue(event.value);
    this.sioService.GetDataListCommandMunicipe("Municipios", "IdDepto = @0", [event.value.idDepto]).subscribe((municipe2: Municipes[]) => {
      this.listMunicipes = municipe2
    })
  }

  addEditAddress() {
    this.submited = true
    if (this.form.valid) {
      this.direccion.nombres = this.form.get('nombres')?.value;
      this.direccion.apellidos = this.form.get("apellidos")?.value;
      this.direccion.celular = this.form.get("celular")?.value;

      this.direccion.departamento = this.form.get("ciudad")?.value;
      this.direccion.ciudad = this.form.get("municipio")?.value;

      this.direccion.direccion = this.form.get("direccion")?.value;
      this.direccion.descripcion = this.form.get("informacionAdicional")?.value;

      this.direccion.editando = false;

      this.direcciones[this.index] = this.direccion;

      this.expressService.editDireccionesUser(this.usuario.correoElectronico, this.direcciones).then((res) => {
        if (res) {
          this.form.reset()
          this.openDialogMessage()
          this.load.cargar(false)
        }
      }).catch((error) => {
        if (error.status == 403 || error.status == 401) {
          // this.usuario = new User()
          // this.usuario.nombre = "-"
          // this.userService.setUser(this.usuario)
          this.router.navigate(['modify-address'])
          // this.load.cargar(false)
        }
      })
    }

  }

  openDialogMessage(): void {
    const dialogRef = this.dialogo.open(UpdateUserComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      this._location.back();
      setTimeout(() => {
        window.location.reload();
      }, 100); // Puedes ajustar el tiempo de espera si es necesario

    })
  }

  cancelar() {
    this._location.back();
    setTimeout(() => {
      window.location.reload();
    }, 100); // Puedes ajustar el tiempo de espera si es necesario

  }

  ngOnDestroy() {
    this.direcciones[this.index].editando = false;
    this.expressService.editDireccionesUser(this.usuario.correoElectronico, this.direcciones);
  }


}
