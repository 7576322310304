<div [ngClass]="{'back-img' : !false}">
  <div class="pb-5 pt-4" [ngClass]="{'page-none':!page}">
    <span (click)="routing()" class="return pt-3 pl-100 pointer">{{'< Regresar a envío'}}</span>
  </div>
  <div class="center pb-5">
    <h2 class="title">TUS DIRECCIONES</h2>
  </div>
  <div class="pb-1rem contenedor-direcciones">

    <div class="contenedor-list-direcciones">

      @if (direcciones.length == 0) {
      <div class="pb-3 pt-3 center">
        <span class="title">No tiene direcciones</span>
      </div>
      }@else{
      @for (dire of direcciones; track $index) {
      <div class="contenedor-direcciones-lista">
        <mat-accordion>
          <mat-expansion-panel [expanded]="$index === expandedPanelIndex" (opened)="onPanelOpened($index)"
            class="border-expansion" (closed)="onPanelClosed($index)" hideToggle="true">
            <mat-expansion-panel-header
              [ngClass]="{'header-principal' : $index === expandedPanelIndex && dire.isPrincipal, 'header-principal2' : dire.isPrincipal }">
              <mat-panel-title class="flex-start">
                <label class="checkbox-container">
                  <input type="checkbox" class="pr-15px mt-2px" [disabled]="true" *ngIf="dire.isPrincipal" checked>
                  <span class="checkmark"></span>
                </label>
                <div class="d-flex-column no-wrap title-desple">
                  <span class="expansion-text-principal pb-2" *ngIf="!false && dire.isPrincipal">Enviar a
                    esta dirección</span>
                  <span class="expansion-text-principal" *ngIf="false && dire.isPrincipal">Dirección
                    principal</span>
                  <span class="expansion-text"
                    [ngClass]="{'expansion-text-principal' : dire.isPrincipal}">{{dire.descripcion |
                    uppercase}}</span>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <mat-icon *ngIf="!isPanelOpen[$index]">add</mat-icon>
                <mat-icon *ngIf="isPanelOpen[$index]">remove</mat-icon>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="row-column-responsive">
              <div class="d-flex-column pl-6 no-wrap pt-3">
                <span class="text">{{dire.direccion}}</span>
                <span class="text">{{dire.ciudad.municipioNombre}}
                  {{dire.departamento.departamentoNombre}}</span>
                <span class="text">{{dire.nombres}} {{dire.apellidos}}</span>
                <span class="text">{{dire.celular}}</span>
              </div>
              @if (dire.isPrincipal) {
              <div class="d-flex-row w-100 icon-location pl-6 pt-3">
                <span (click)="editDirection($index)" class="icon-Editar icon-color-size pointer"></span>
                <span (click)="deleteAddres($index)" class="icon-Eliminar pl-5 icon-color-size pointer"></span>
              </div>
              }
            </div>
            @if (!dire.isPrincipal && false) {
            <div class="pl-6 row-column-responsive">
              <label class="checkbox-container">
                <input type="checkbox" (click)="newPrincipal($index)" class="text">Quiero que esta sea mi dirección
                principal
                <span class="checkmark"></span>
              </label>
              <div class="d-flex-row w-100 icon-location pt-3 pl-1rem pb-3">
                <span (click)="editDirection($index)" class="icon-Editar icon-color-size pointer"></span>
                <span (click)="deleteAddres($index)" class="icon-Eliminar pl-5 icon-color-size pointer"></span>
              </div>
            </div>
            }
            @if (!dire.isPrincipal && !false) {
            <div class="pl-6 pt-3 row-column-responsive">
              <label class="checkbox-container">
                <input type="checkbox" (click)="newPrincipal($index)" class="text">Enviar a esta dirección>
                <span class="checkmark"></span>
              </label>
              <div class="d-flex-row w-100 icon-location pt-3 pl-1rem pb-3">
                <span (click)="editDirection($index)" class="icon-Editar icon-color-size pointer"></span>
                <span (click)="deleteAddres($index)" class="icon-Eliminar pl-5 icon-color-size pointer"></span>
              </div>
            </div>
            }
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      }
      }

    </div>

    <div class="contenedor-formulario">
      <!-- Formulario para agregar una nueva dirección -->
      <div class="pl-6 pt-3 pb-3">
        <mat-expansion-panel [expanded]="panelOpenState" [disabled]="true" class="border-expansion">
          <mat-expansion-panel-header class="pl-0">
            <mat-panel-title class="center space-bet">
              <div *ngIf="!panelOpenState" class="w-25 w-100-mobile">
                <button class="button-secondary" (click)="expandPanelAddDirection()">Agregar
                  dirección</button>
              </div>
              <span class="text-new-direction" *ngIf="panelOpenState">NUEVA DIRECCIÓN</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <lib-form-direccion [submited]="submited" (datosFormulario)="enviarDireccion($event)" />
          <div class="d-flex-row contenedor-botones">
            <div class="pr-50px w-50-mobile">
              <button (click)="saveAddress()" class="button-primary">Guardar dirección</button>
            </div>
            <div class="w-50-mobile">
              <button (click)="closePanelAddDirection()" class="button-secondary">Cancelar</button>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>

    <!-- <div [ngClass]="!false ? 'pb-1rem' : 'pb-3rem'" class="d-block d-sm-block d-md-block d-lg-none pr-6 pl-6">
      <button class="button-secondary" (click)="newDirection()">Agregar dirección</button>
    </div> -->
  </div>
</div>
