import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, AfterContentInit, OnDestroy, signal } from '@angular/core';
import { Router } from '@angular/router';
import { InstantSearchService, LoadingService, PantallaSaleService, SioServicesService } from 'libreria-tienda';
import { connectHits, connectSearchBox } from 'instantsearch.js/es/connectors';
import { configure } from 'instantsearch.js/es/widgets';

@Component({
  selector: 'lib-busqueda',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css']
})
export class BusquedaComponent implements OnInit, AfterContentInit, OnDestroy {

  sale: boolean = false;
  idPReferencias: string = '';
  cantidadTotal: number = 0;
  resultados = signal<any[]>([]); // Usamos signals para almacenar resultados de forma reactiva
  public query: string = '';
  public search?: (value: string) => void;
  private searchRequestId: number = 0;

  private sioservice = inject(SioServicesService);
  private load = inject(LoadingService);
  private instantSearchService = inject(InstantSearchService);
  private pantallaSaleService = inject(PantallaSaleService);

  constructor(private router: Router) {
    this.setupInstantSearch();
  }

  ngOnInit(): void {
    this.pantallaSaleService.estado$.subscribe(valor => {
      this.sale = valor;
    });
  }

  ngAfterContentInit(): void {
    this.instantSearchService.start();
  }

  ngOnDestroy(): void {
    this.instantSearchService.dispose();
  }

  private setupInstantSearch(): void {
    this.instantSearchService.addWidgets([
      connectSearchBox(({ refine, query }) => {
        this.search = refine;
        this.query = query;
      })({}),

      connectHits(({ results }) => {
        if (this.query && results?.nbHits) {
          this.handleSearchResults(results);
        }
      })({}),

      configure({
        hitsPerPage: 40
      })
    ]);
  }

  private handleSearchResults(results: any): void {
    this.cantidadTotal = results.nbHits;
    const currentRequestId = this.searchRequestId;

    setTimeout(() => {
      if (this.searchRequestId === currentRequestId) {
        this.load.cargar(false);
        const hits = results.hits.map((res: any) => res.objectID);
        this.instantSearchService.actualizarResultados(hits, this.cantidadTotal);
        this.productosBusqueda(hits);
      }
    }, 0);
  }

  async productosBusqueda(ids: string[]): Promise<void> {
    if (ids.length > 0 && this.idPReferencias !== ids[0]) {
      this.idPReferencias = ids[0];
      try {
        const resultado = await this.sioservice.getArticulosBusqueda(ids);

        if (resultado.valor.length === 0) {
          setTimeout(() => this.instantSearchService.otherPage(), 5000);
        } else {
          this.instantSearchService.actualizarResultados(resultado.valor, this.cantidadTotal);
        }
      } catch (error) {
        console.error('Error al obtener artículos de búsqueda:', error);
      }
    }
  }

  buscar(): void {
    if (this.search) {
      this.searchRequestId++;
      this.load.cargar(true);
      this.search(this.query);
    }
  }

  handleInput(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      this.query = event.target.value;
    }
  }

  onEnter(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const valorInput = (event.target as HTMLInputElement).value;

      this.router.navigate(['/catalogo/busqueda'], {
        queryParams: {
          'ProductoCaracteristicasJson[query]': valorInput
        }
      }).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });

      this.buscar();
    }
  }
}
