import { EventEmitter, Injectable, Output } from '@angular/core';
import { TipoEnvio } from '../class/send-type';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyServiceService {

  private _pagar = new BehaviorSubject<boolean>(false);

  pagar$ = this._pagar.asObservable();


  @Output() newBuy = new EventEmitter<boolean>();
  @Output() newFormValid = new EventEmitter<boolean>();
  @Output() messageInvalid = new EventEmitter<string>();
  @Output() sectionEmit = new EventEmitter<number>();

  isnew: boolean = false;
  isformValid: boolean = false;
  messageInvalidshow: string = ""
  sendType: TipoEnvio = new TipoEnvio();
  constructor() { }

  newBuyMethod(isnew: boolean) {
    this.isnew = isnew
    this.newBuy.emit(this.isnew);
  }
  formValid(isformValid: boolean, mesagge: string) {
    this.isformValid = isformValid
    this.messageInvalidshow = mesagge
    this.messageInvalid.emit(this.messageInvalidshow)
    this.newFormValid.emit(this.isformValid);
  }
  getSendType() {
    return this.sendType
  }
  setSendStype(send: TipoEnvio) {
    this.sendType = send
  }

  updateEstaPagando(newValue: boolean) {
    this._pagar.next(newValue);
  }

}
