export const environment = {
  URLCARDJSON: "https://qastorageorafa.blob.core.windows.net/json-components/tiempo-cartas.json",
  URLSERVICE_SIO: "https://apis.orafa.com.co/sio-p",
  // URLSERVICE_SIO: "https://qa.apis.orafa.com.co/sio-p",
  // URLSERVICE_SIO: "http://localhost:5070",
  URLJSONIMAGE: "https://qastorageorafa.blob.core.windows.net/json-components/image-slider-home.json",
  URLJSONGIA: "https://qastorageorafa.blob.core.windows.net/json-components/gia.json",
  URLJSONABOUT: "https://qastorageorafa.blob.core.windows.net/json-components/about-us.json",
  URLJSONIMGMARCAS: "https://qastorageorafa.blob.core.windows.net/json-components/imagenes-marcas-home.json",
  URLJSONCATEGORIES: "https://qastorageorafa.blob.core.windows.net/json-components/categories-home.json",
  URLJSONSERVICES: "https://qastorageorafa.blob.core.windows.net/json-components/kevin-services.json",
  URLJSONLOGIN: "https://qastorageorafa.blob.core.windows.net/json-components/login.json",
  URLJSONCREATE: "https://qastorageorafa.blob.core.windows.net/json-components/create-account.json",
  URLJSONDETALLE: "https://qastorageorafa.blob.core.windows.net/json-components/detalle.json",
  URLPDFTALLAS: "https://qastorageorafa.blob.core.windows.net/pdf/",
  URLPDFTERMINOS: "https://drive.google.com/uc?id=1TOR-1_cTCcp-90jnPROtGThIc67TSvfo",
  WHATSAPP: "https://api.whatsapp.com/send?phone=573186338303&",
  EMAILWEB: "sistemas9@orafa.com.co",
  URLEXPRESS: "https://apiplaxd.orafa.com.co",
  // URLEXPRESS: "https://qa.apiplaxd.orafa.com.co",
  // URLEXPRESS: "http://localhost:5540",
  URLBASEIMG: "https://kevins.com.co/img/productos/",
  // URLBASEIMG: "https://kevinsweb.blob.core.windows.net/imagenes-web/imagenes_agrupadas/",
  KEYGOOGLERECAPTCHA: "6LdyBUUpAAAAAOlCJcTkU3k8w2hgBMGxnAIE6d02",
  SECRETKEY: "11122023",
  URLRESUMENJSON: "https://qastorageorafa.blob.core.windows.net/json-components/resumen.json",
  URLSHIPPINGSJSON: "https://qastorageorafa.blob.core.windows.net/json-components/envio.json",
  URLRESPONSESUCCESS: "https://qastorageorafa.blob.core.windows.net/json-components/approved.json",
  URLRESPONSEFAIL: "https://qastorageorafa.blob.core.windows.net/json-components/rejected.json",
  URLIMGGIA: "https://kevins.com.co/img/gia_logo_sm.png",
  URLGARANTIASJSON: "https://qastorageorafa.blob.core.windows.net/json-components/garantias.json",
  URLDELIVERYJSON: "https://qastorageorafa.blob.core.windows.net/json-components/delivery-methods.json",
  URLWORKJSON: "https://qastorageorafa.blob.core.windows.net/json-components/trabaja-con-nosotros.json",
  URLJSONJOYERIAS: "https://qastorageorafa.blob.core.windows.net/json-components/joyerias.json",
  URLJSONTRATAMIENTODATOS: "https://qastorageorafa.blob.core.windows.net/json-components/politica_tratamiento_datos.json",
  URLJSONPREGUNTASFRECUENTES: "https://qastorageorafa.blob.core.windows.net/json-components/preguntas_frecuentes.json",
  URLJSONGANAKILATES: "https://qastorageorafa.blob.core.windows.net/json-components/gana-kilates.json",
  URLJSONPQRS: "https://qastorageorafa.blob.core.windows.net/json-components/pqrs.json",
  KEYGOOGLECLIENT: "798742616400-20hbsn4oi880ah3tjt9mkj2buduoi3t9.apps.googleusercontent.com",
  KEYGOOGLEMAPS: "AIzaSyBjd2EMTwEcF_QxDn3S6w3N16zYIK_IhkI"
};
