import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { CarritoVacioComponent } from 'libreria-tienda/shopping-cart/detail-cart/carrito-vacio/carrito-vacio.component';
import { CarritoComponent } from 'libreria-tienda/shopping-cart/detail-cart/carrito/carrito.component';
import { PasoCompraMobileComponent } from 'libreria-tienda/shopping-cart/paso-compra-mobile/paso-compra-mobile.component';
import { PasosCompraComponent } from 'libreria-tienda/shopping-cart/pasos-compra/pasos-compra.component';
import { ResumenComponent } from 'libreria-tienda/shopping-cart/resumen/resumen.component';
import { ItemCar, ItemCompra, LoadingService, ShoppingCartService, SioServicesService } from 'libreria-tienda';
import { MatDividerModule } from '@angular/material/divider';




@Component({
  selector: 'app-carrito-compra',
  standalone: true,
  imports: [ResumenComponent,
    PasosCompraComponent,
    CarritoComponent,
    CarritoVacioComponent,
    PasoCompraMobileComponent,
    MatDividerModule],
  templateUrl: './carrito-compra.component.html',
  styleUrl: './carrito-compra.component.css'
})
export class CarritoCompraComponent implements OnInit, AfterViewInit {

  public load = inject(LoadingService)

  constructor() { }

  public cartService = inject(ShoppingCartService)
  public sioService = inject(SioServicesService)
  shoppingCart: boolean
  listItemsShoppingCart: ItemCar[] = [];
  listItemsCompra: ItemCompra[] = [];

  ngOnInit(): void {
    this.shoppingCart = this.cartService.getTotalProducts() == 0;
    this.load.cargar(!this.shoppingCart)
    if (!this.shoppingCart) {
      this.listItemsShoppingCart = this.cartService.getCart();
      // console.log(this.listItemsShoppingCart);
      this.informacionCarrito();
    }
  }

  ngAfterViewInit(): void {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    }
  }

  informacionCarrito() {
    this.sioService.getInfoArticulosCarrito(this.listItemsShoppingCart)
      .then((resultado) => {
        if (resultado.exitoso) {
          this.listItemsCompra = resultado.valor;
          // this.cartService.saveCarritoCompra(this.listItemsCompra)
          this.load.cargar(false);
        }
      }).catch((error) => {
        console.error(error);
        this.load.cargar(false);
      });
  }

  EliminarItem(e: any) {
    console.log(e);
    this.listItemsCompra.splice(e, 1);
    this.cartService.deleteItemCart(e)
    this.shoppingCart = this.cartService.getTotalProducts() == 0;
  }

}
