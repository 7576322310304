@if (login) {
  <div class="contenedor-iniciales" [matMenuTriggerFor]="beforeMenu">
    <div class="circle-iniciales z-index pointer">
      <span>{{iniciales}}</span>
    </div>
    <span class="text-banner">Perfil</span>

  </div>
  <mat-menu #beforeMenu="matMenu" xPosition="before" class="menu-perfil">
    <button mat-menu-item (click)="redirectPage('perfil')">Ver perfil</button>
    <mat-divider ></mat-divider>
    <button mat-menu-item (click)="cerrarSesion()">Cerrar sesión</button>
  </mat-menu>

  }@else {
  <div (click)="redirectPage('login')" class="z-index pointer">
    <span class="icon-User black-color icon-size pl-2" [ngClass]="{'icon-color-sale':sale}"></span>
  </div>
  }
