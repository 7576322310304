<div class="pt-3 border-bottom">
  <span class="characteristics">{{getName('desc')}}</span>
</div>
<div class="d-flex-center">
  @if (descripcion !== undefined) {
  <span class="details">{{descripcion}}:</span>
  }
</div>
<mat-accordion class="pt-3">
  <mat-expansion-panel (opened)="onPanelOpened()" (closed)="onPanelClosed()" hideToggle="true">
    <mat-expansion-panel-header [ngClass]="{'aplicar-borde': open}">
      <mat-panel-title>
        <span class="no-wrap characteristics">{{getName('caract')}}</span>
      </mat-panel-title>
      <mat-panel-description>
        @if (open) {
        <i class="fa-solid fa-angle-up"></i>
        }@else {
        <i class="fa-solid fa-angle-down"></i>
        }
      </mat-panel-description>
    </mat-expansion-panel-header>
    @for (caracteristica of caracteristicasItem; track $index) {
    @if (caracteristica.valor !== null && caracteristica.valor !== '' && caracteristica.valor !== undefined && caracteristica.valor !== "NO" && caracteristica.valor !== '0') {
    <div class="d-flex-align-end d-flex-row pr-2">
      <span class="titulos-carac">{{caracteristica.web}}:</span>
      <span class="titulos-carac carac-valor">{{caracteristica.valor | titlecase }}</span>
    </div>
    }
    }
  </mat-expansion-panel>
  <mat-divider></mat-divider>
</mat-accordion>
