import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, inject, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ViewChildren, ElementRef, QueryList, Renderer2, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { ConfigService, imagenesAux } from 'libreria-tienda';

@Component({
  selector: 'lib-imagenes-mobile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './imagenes-mobile.component.html',
  styleUrl: './imagenes-mobile.component.css'
})
export class ImagenesMobileComponent implements OnInit, AfterViewInit {
  @Input() IdProducto: string = "";

  abc: string[] = ['', '_B', '_C', '_D', '_E', '_F', '_G']

  imagenes: imagenesAux[] = [];

  imagenMostrar: string

  private isDragging = false; // Indica si el usuario está arrastrando
  private startX = 0; // Posición inicial del toque
  private scrollLeft = 0; // Posición inicial del desplazamiento


  public configService = inject(ConfigService);

  ngOnChanges(changes: SimpleChanges) {
    if (changes['IdProducto']) {
      if (this.IdProducto !== undefined) {
        this.fotosSegundarias(this.IdProducto)
        // this.img = this.configService._config.URLBASEIMG + this.IdProducto;
        // this.slides = [
        //   { image: this.img + ".jpg", alt: 'Slide 1' },
        //   { image: this.img + "_B.jpg", alt: 'Slide 2' },
        //   { image: this.img + "_C.jpg", alt: 'Slide 3' },
        // ];
      }
    }
  }


  img: string = this.configService._config.URLBASEIMG + this.IdProducto

  slides: any[] = [];

  ngOnInit(): void {
    // this.startAutoSlide();
  }

  @ViewChild('slider', { static: true }) slider!: ElementRef; // Referencia al contenedor del slider
  @ViewChildren('navLink') navLinks!: QueryList<ElementRef>;  // Referencia a los enlaces de navegación


  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngAfterViewInit(): void {

    const sliderEl = this.slider.nativeElement;

    // Escucha el evento scroll para detectar la imagen visible
    sliderEl.addEventListener('scroll', () => {
      this.detectVisibleImage();
    });

    this.navLinks.forEach((navLink, index) => {
      this.renderer.listen(navLink.nativeElement, 'click', (event: Event) => {
        event.preventDefault(); // Previene el comportamiento por defecto del enlace
        this.scrollToSlide(index); // Llama a la función personalizada de animación
        this.updateNavStyles(index); // Actualiza los estilos de los enlaces
      });
    });
  }

  onSliderScroll(): void {
    const sliderEl = this.slider.nativeElement;
    const scrollLeft = sliderEl.scrollLeft; // Desplazamiento actual
    const slideWidth = sliderEl.clientWidth; // Ancho de cada imagen
    const activeIndex = Math.round(scrollLeft / slideWidth); // Índice de la imagen activa

    this.updateNavStyles(activeIndex); // Actualiza los estilos del nav
  }


  fotosSegundarias(aux: string) {
    this.imagenes = [];
    for (let index = 0; index < this.abc.length; index++) {
      let auxImg: imagenesAux = new imagenesAux()
      auxImg.img = this.configService._config.URLBASEIMG + aux + this.abc[index] + ".jpg"
      this.imagenes.push(auxImg)
    }
    this.imagenes = this.eliminarErrorImagen(this.imagenes)
    this.imagenMostrar = this.imagenes[0].img
  }

  eliminarErrorImagen(arreglo: imagenesAux[]) {
    this.imagenes = [];
    if (isPlatformBrowser(this.platformId)) {
      for (let i = 0; i < arreglo.length; i++) {
        const img = new Image();
        img.src = arreglo[i].img;
        img.onerror = () => {
          arreglo[i].error = true
        };
      }
    }
    return arreglo
  }

  updateNavStyles(activeIndex: number): void {
    this.navLinks.forEach((navLink, index) => {
      if (index === activeIndex) {
        this.renderer.addClass(navLink.nativeElement, 'active'); // Agrega clase activa
      } else {
        this.renderer.removeClass(navLink.nativeElement, 'active'); // Quita clase activa
      }
    });
  }

  scrollToSlide(index: number): void {
    const slideWidth = this.slider.nativeElement.clientWidth;
    const targetPosition = index * slideWidth;
    const currentPosition = this.slider.nativeElement.scrollLeft;
    const distance = targetPosition - currentPosition;
    const duration = 500;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const easeInOutQuad = progress < 0.5
        ? 2 * progress * progress
        : -1 + (4 - 2 * progress) * progress;

      this.slider.nativeElement.scrollLeft =
        currentPosition + distance * easeInOutQuad;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  }

  detectVisibleImage(): void {
    const sliderEl = this.slider.nativeElement;
    const scrollLeft = sliderEl.scrollLeft;
    const slideWidth = sliderEl.clientWidth;
    const activeIndex = Math.round(scrollLeft / slideWidth);
    this.updateNavStyles(activeIndex)
  }



}
