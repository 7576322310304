<div class="">
  <h2 class="titles pt-3 pb-3 margin-0">{{getName('titleEnvio')}}</h2>
  <div class="d-flex-column col-12">
    @if (isDireccion) {
    <div class="d-flex-row d-flex-start pl-6  shadowh h-90 space-bet contendor-info-direccion">
      <div class="d-flex-column start item1">
        <div class=" d-flex-center pb-2">
          <span class="titles">{{direccion.descripcion}}</span>
        </div>
        <div class="d-flex-center">
          <span class="titles">{{direccion.direccion}}</span>
        </div>
      </div>
      <div class="d-flex-center d-flex-column start item2">
        <div>
          <span class="info">{{direccion.nombres}} </span>
          <span class="info">{{direccion.apellidos}}</span>
        </div>
        <div>
          <span class="info">Cel: {{direccion.celular}}</span>
        </div>
      </div>
      <div class="d-flex-center item3">
        <span class="info">{{direccion.ciudad.municipioNombre}}
          {{direccion.departamento.departamentoNombre}}</span>
      </div>
      <div class="d-flex-center pr-10 item4">
        <span (click)="editAddres()" class="icon-Editar font-size-22 cursor pr-1"></span>
        <span (click)="deleteAddres()" class="icon-Eliminar font-size-22 cursor"></span>
      </div>
    </div>
    }@else {
    <div>
      <h2 class="titles">{{getName('no-dire')}}</h2>
    </div>
    }
  </div>
  <div class="d-flex-space-bet pt-3 pb-3 pl-35px">
    <!-- Boton de cambiar dirección o agregar -->
    <div class="w-30">
      <button *ngIf="direccion.nombres != undefined" (click)="routing()"
        class="titles button-secondary pl-0">{{getName('but-envio')}}</button>
      <button *ngIf="direccion.nombres == undefined" (click)="routing()"
        class="titles button-secondary pl-0">{{getName('but-adicionar')}}</button>
    </div>
  </div>
</div>
