import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import {
  BuyServiceService,
  CommonLista, ConfigService, ExpressService, ItemCar, JsonTextService, ListaProductos, LoadingService, ShoppingCartService, SioServicesService, TextResponse, User, UserService, calculateTime, ciudades, direccion, puntosKevins, tiempoEntregaPeticion, time,
  userlogged
} from 'libreria-tienda';
import { TipoEnvio} from 'libreria-tienda';
import { Router } from '@angular/router';
import { AddressService } from 'libreria-tienda';
import { MatDialog } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SendcodeComponent } from '../sendcode/sendcode.component';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { ReceiveByComponent } from './receive-by/receive-by.component';
import { UpdateUserComponent } from '../update-user/update-user.component';
@Component({
  selector: 'lib-domicilio',
  standalone: true,
  imports: [CommonModule, MatDividerModule, MatExpansionModule,
     MatIconModule, MatButtonModule, FormsModule],
  templateUrl: './domicilio.component.html',
  styleUrl: './domicilio.component.css'
})
export class DomicilioComponent implements OnInit {

  @Output() direccionPrin = new EventEmitter<direccion>();


  isMessage: boolean;

  envio: number = 1;
  // section: number = 1
  costoEnvio: number = 0

  isDireccion: boolean = false;
  phone: boolean = false;
  isSendNormal: boolean = true;
  isSendExpress: boolean;
  isReceiveByMe: boolean = true;
  isReceiveByOther: boolean;
  formDomicilioBool: boolean = false;
  formTiendaBool: boolean = false;
  open: boolean = false;

  ciudad: string = ""
  message: string = "";

  sendType: TipoEnvio = new TipoEnvio();
  shoppingCart: ItemCar[] = [];

  time: time = new time()

  direccion: direccion = new direccion();
  direcciones: direccion[] = [];
  product: ListaProductos = new ListaProductos();
  listaproductos: ListaProductos[] = []
  listCities: ciudades[] = [];
  listDocuments: CommonLista[] = [];

  mensajeEspecial: boolean = false;

  TiempoEntregaPeticion: calculateTime = new calculateTime()
  tiempoentregape: tiempoEntregaPeticion = new tiempoEntregaPeticion()
  pointKevinsSelected: puntosKevins;

  public userService = inject(UserService)
  public jsonService = inject(JsonTextService)
  public cartService = inject(ShoppingCartService)
  public sioservice = inject(SioServicesService)
  public expressService = inject(ExpressService)
  public addresService = inject(AddressService)
  public buyService = inject(BuyServiceService)
  public configService = inject(ConfigService)
  public load = inject(LoadingService)

  jsonShippings: TextResponse[];

  userLog: userlogged = new userlogged()

  constructor(private router: Router, public dialogo: MatDialog) { }

  ngOnInit(): void {
    this.load.cargar(true)
    this.userLog = this.userService.getUserLocal();
    this.jsonService.downloadJson(this.configService._config.URLSHIPPINGSJSON).subscribe(res => {
      this.jsonShippings = res
    })

    this.userService.getUserDireccion().then((res) => {
      // console.log(res);
      this.direcciones = res
      if (this.direcciones.length > 0) {
        this.direccion = this.direcciones.filter((item: direccion) => item.isPrincipal == true)[0];
        if (!this.direccion) {
          console.log(this.direccion);
          this.direcciones[0].isPrincipal = true;
          this.direccion = this.direcciones[0];
          this.actualizarDirecciones();
        }
        this.isDireccion = true;

        this.direccionPrin.emit(this.direccion)

      } else {
        this.isDireccion = false;
      }
    }).catch((error) => {
      console.error('Error al consultar las direcciones, eliminar sesión y contactar a un experto');
    });

    this.sioservice.GetCiudadesKevins().subscribe(list => {
      this.listCities = list
    })

    this.sioservice.GetListaPorCodigo("TPDOC").subscribe(list => {
      this.listDocuments = list
    })

    this.load.cargar(false)
  }

  getName(name: string) {
    return this.jsonShippings?.find(x => x.Nombre == name)?.Valor
  }

  editAddres() {
    let i = this.direcciones.findIndex((item: direccion) => item.isPrincipal == true);
    this.addresService.setDireccion(this.direcciones[i])
    this.addresService.setIndex(i)
    this.direcciones[i].editando = true;
    this.expressService.editDireccionesUser(this.userLog.correoElectronico, this.direcciones);
    this.router.navigate(["edit-Address"]);
  }

  openDialogMessage(): void {
    const dialogRef = this.dialogo.open(UpdateUserComponent, { panelClass: 'custom-dialog-container' }).afterClosed().subscribe(res2 => {
      // this._location.back()
      console.log('Back');
    })
  }

  deleteAddres() {
    let i = this.direcciones.findIndex((item: direccion) => item.isPrincipal == true);
    this.direcciones.splice(i, 1);
    if (this.direcciones.length > 0) {
      this.direccion = this.direcciones[0];
      this.direcciones[0].isPrincipal = true;
    } else {
      this.isDireccion = false;
    }
    this.actualizarDirecciones();
  }

  actualizarDirecciones() {
    this.load.cargar(true);
    this.expressService.editDireccionesUser(this.userLog.correoElectronico, this.direcciones).then((res) => {
      if (res) {
        // console.log("respuesta", res)
        this.openDialogMessage()
        this.load.cargar(false);
      }
    }).catch((error) => {
      console.error('Error al actualizar las direcciones');
      window.location.reload();
    })

  }

  routing() {
    this.openDialogMessageSendcode()
  }

  openDialogMessageSendcode(): void {
    const dialogRef = this.dialogo.open(SendcodeComponent, { panelClass: 'custom-dialog-container' })
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.router.navigate(["/modify-address"])
          console.log('Quedo bien la validación con el correo');
        }
      })
  }

}
