<div class="container-father h70">
  <div class="restore-container">
    <div class="center pb-2">
      <span class="title">Restablece tu contraseña</span>
    </div>
    <form [formGroup]="form" action="" class="center d-flex-column w-100">
      <div class="d-flex-row pos-rela w-100-r"
        [ngClass]="{'place': form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)}">
        <div class="div-eye w-100-r">
          <input
            [ngClass]="{'input-error' : form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched) || form.controls['password'].value == '' && submited, 'text-field' : !form.controls['password'].invalid || form.controls['password'].invalid }"
            [type]="hide ? 'password' : 'text'" formControlName="password" placeholder="Crea tu contraseña">
          <span (click)="isHide('boton1')" *ngIf="hide"
            class="icon-Visualizar_contrasea eye top-right font-size-18 color-eye"></span>
          <span (click)="isHide('boton1')" *ngIf="!hide"
            class="icon-No_visualizar_contrasea close-eye top-right font-size-18 color-eye"></span>
        </div>
        <div class="center pl-1">
          <mat-icon class="red"
            *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
            error_outline</mat-icon>
        </div>
      </div>
      <div class="pt-2 pb-2 d-flex-column center">
        <span class="adv">Usa 8 o más caracteres con una combinación de letras, números y símbolos.</span>
        <mat-error class="pass mr-10"
          *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)">
          Ingresa una contraseña que cumpla con los requisitos</mat-error>
      </div>

      <div class="pb-3 w-100-r">
        <div class="d-flex-row pos-rela w-100-r"
          [ngClass]="{'place': form.controls['passwordConfirm'].invalid && (form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched)}">
          <div class="div-eye w-100-r">
            <input
              [ngClass]="{'input-error' : form.controls['passwordConfirm'].invalid && (form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched)|| !equal || form.controls['passwordConfirm'].value == '' && submited, 'text-field' : !form.controls['passwordConfirm'].invalid || form.controls['passwordConfirm'].invalid }"
              [type]="hide2 ? 'password' : 'text'" formControlName="passwordConfirm"
              placeholder="Confirma tu contraseña">
            <span (click)="isHide('boton2')" *ngIf="hide2"
              class="icon-Visualizar_contrasea eye top-right font-size-18 color-eye"></span>
            <span (click)="isHide('boton2')" *ngIf="!hide2"
              class="icon-No_visualizar_contrasea close-eye top-right font-size-18 color-eye"></span>
          </div>
          <div class="center pl-1">
            <mat-icon class="red"
              *ngIf="form.controls['passwordConfirm'].invalid && (form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched)">
              error_outline</mat-icon>
          </div>
        </div>
        <mat-error class="pass mr-10 pt-1 center"
          *ngIf="form.controls['passwordConfirm'].invalid && (form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched)">
          Ingresa tu contraseña</mat-error>
      </div>
      <button (click)="Restore()" class="button-primary">Restablecer contraseña</button>
    </form>

  </div>
</div>
