<form [formGroup]="AddresForm" (ngSubmit)="onSubmit()">
  <div class="contenedor-formulario">
    <!-- Nombres -->

    <div class="dos-columnas">
      <div class="columna-uno columna">
        <!-- Nombres -->
        <div class="contenedor-campo">
          <label for="nombres" class="label">Nombres*</label>
          <input id="nombres" type="text" formControlName="nombres" placeholder="Escriba su nombre completo"
            class="custom-input">
          <div *ngIf="AddresForm.get('nombres')?.invalid && AddresForm.get('nombres')?.touched">
            <small *ngIf="AddresForm.get('nombres')?.errors?.['required']">
              El nombre es requerido.</small>
            <small *ngIf="AddresForm.get('nombres')?.errors?.['minlength']">
              El nombre debe tener al menos 3 caracteres.</small>
            <small *ngIf="AddresForm.get('nombres')?.errors?.['pattern']">
              Solo se permiten letras y espacios.</small>
          </div>
        </div>
      </div>
      <div class="columna-dos columna">
        <!-- Apellidos -->
        <div class="contenedor-campo">
          <label for="apellidos" class="label">Apellidos*</label>
          <input id="apellidos" type="text" formControlName="apellidos" placeholder="Escriba su nombre completo"
            class="custom-input">
          <div *ngIf="AddresForm.get('apellidos')?.invalid && AddresForm.get('apellidos')?.touched">
            <small *ngIf="AddresForm.get('apellidos')?.errors?.['required']">Los apellidos son
              requerido.</small>
            <small *ngIf="AddresForm.get('apellidos')?.errors?.['minlength']">Los apellidos deben tener
              al menos 3 caracteres.</small>
            <small *ngIf="AddresForm.get('apellidos')?.errors?.['pattern']">Solo se permiten
              letras y espacios.</small>
          </div>
        </div>
      </div>
      <div class="columna-dos columna">
        <div class="contenedor-campo">
          <label for="celular" class="label">Celular*</label>
          <input id="celular" type="text" formControlName="celular" placeholder="Escribe tu numero de celular"
            class="custom-input">
          <div *ngIf="AddresForm.get('celular')?.invalid && AddresForm.get('celular')?.touched">
            <small *ngIf="AddresForm.get('celular')?.errors?.['required']">El número de celular es
              requerido.</small>
            <small *ngIf="AddresForm.get('celular')?.errors?.['pattern']">Debe contener entre 7 y 10
              dígitos
              numéricos.</small>
          </div>
        </div>
      </div>
      <div class="columna-dos columna none"></div>
    </div>
    <div class="dos-columnas align-end">
      <div class="columna-dos columna">
        <!-- Departamento -->
        <div class="contenedor-campo">
          <label for="departamento" class="label">Departamento*</label>
          <select id="departamento" formControlName="departamento" placeholder (change)="deptoSelected($event)">
            <option value="">Escoge departamento</option>
            @for (depto of listDeptos; track $index) {
            <option [ngValue]="depto">{{depto.departamentoNombre}}</option>
            }
          </select>
          <div *ngIf="AddresForm.get('departamento')?.invalid && AddresForm.get('departamento')?.touched">
            <small>Debe seleccionar un departamento.</small>
          </div>
        </div>
      </div>
      <div class="columna-uno columna">
        <!-- Ciudad -->
        <div class="contenedor-campo">
          <label for="ciudad" class="label">Escoge una ciudad*</label>
          <select id="ciudad" formControlName="ciudad">
            <option value="">Escoge ciudad</option>
            @for (citie of listMunicipes; track $index) {
            <option [ngValue]="citie"> {{citie.municipioNombre| titlecase}} </option>
            }
          </select>
          <div *ngIf="AddresForm.get('ciudad')?.invalid && AddresForm.get('ciudad')?.touched">
            <small>Debe seleccionar una ciudad.</small>
          </div>
        </div>
      </div>
      <div class="columna-dos columna">
        <!-- Dirección -->
        <div class="contenedor-campo">
          <label for="direccion" class="label">Dirección*</label>
          <input id="direccion" type="text" formControlName="direccion" placeholder="Escribe su dirección"
            class="custom-input">
          <div *ngIf="AddresForm.get('direccion')?.invalid && AddresForm.get('direccion')?.touched">
            <small *ngIf="AddresForm.get('direccion')?.errors?.['required']">La dirección es
              requerida.</small>
            <small *ngIf="AddresForm.get('direccion')?.errors?.['minlength']">La dirección debe tener
              al menos 8 caracteres.</small>
          </div>
        </div>
      </div>
      <div class="columna-dos columna">
        <!-- Información adicional -->
        <div class="contenedor-campo">
          <label>Información adicional (opcional)</label>
          <input type="text" placeholder="Ejemplo: Apto, oficina, casa" formControlName="informacionAdi" />
        </div>
      </div>
    </div>
    <!-- <div>
      <button type="submit" [disabled]="AddresForm.invalid">Enviar</button>
    </div> -->
  </div>
</form>
