import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ItemCar, LoadingService, SelectAderezo, infoVenecia } from 'libreria-tienda';
import { PopUpTallaComponent } from './pop-up-talla/pop-up-talla.component';

@Component({
  selector: 'lib-complemento',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './complemento.component.html',
  styleUrl: './complemento.component.css'
})
export class ComplementoComponent {

  @Input() info: infoVenecia

  @Output() selectComple = new EventEmitter<SelectAderezo>();

  estado: SelectAderezo = new SelectAderezo();
  talla: string;
  isChecked: boolean = false;

  public load = inject(LoadingService)

  constructor(public dialogo: MatDialog) { }

  select() {
    if (this.isChecked) {
      this.isChecked = !this.isChecked
      this.estado.select = this.isChecked
      this.selectComple.emit(this.estado);
    } else {
      if (this.info.manejaTalla) {
        this.load.cargar(true)
        const dialogRef = this.dialogo.open(PopUpTallaComponent,
          {
            data: { info: this.info, talla: this.talla, isDialog: true },
            position: { top: '6rem' },
            maxHeight: '84vh'
          })
        dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
          // console.log(result);
          if (result !== undefined) {
            this.isChecked = !this.isChecked;
            this.estado.select = this.isChecked;
            this.estado.item = result
            this.selectComple.emit(this.estado);
          }
        });
      } else {
        this.isChecked = !this.isChecked;
        this.estado.select = this.isChecked;
        let aux: ItemCar = new ItemCar();
        aux.cantidad = 1;
        aux.descTalla
        aux.descWeb = this.info.descripcion
        aux.idProducto = this.info.idProducto
        aux.imagen = this.info.imagen
        aux.maxCantidad = this.info.cantidad
        aux.tallaId = -1;
        this.estado.item = aux
        this.selectComple.emit(this.estado);
      }
    }

  }

}
