<div class="container-father pt-5 pb-5 p-2 h70">
  <form class="flex-column" [formGroup]="form" action="">
    <div class="forgot-container d-flex-column d-flex align-items-center">
      <span class="title">Recuperar contraseña</span>
      <span *ngIf="!res" class="sub-title">Se enviará un vínculo para restablecer tu contraseña a la dirección <br> de
        correo electrónico asociada con tu cuenta de inicio de sesión.</span>
      <div *ngIf="res" class="flex-row d-flex">
        <span class="icon-Icono_aprobacion icon-size apro pr-1rem"></span>
        <span class="apro p-l-3">Se envió un mensaje de correo electrónico a {{email}}. <br>Sigue las instrucciones del
          mensaje y
          restablece tu contraseña.</span>
      </div>
      <input
        [ngClass]="{'input-error' : form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched), 'text-field' : !form.controls['email'].invalid || form.controls['email'].invalid }"
        type="text" placeholder="Ingresa tu correo electrónico" formControlName="email">
      <mat-error class="center"
        *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)">
        <mat-icon class="pr-2rem">error_outline</mat-icon><span class="pl-1">El correo electrónico que ingresaste no es
          válido.</span>
      </mat-error>
      <mat-error class="center" *ngIf="!user">
        <mat-icon class="pr-2rem">error_outline</mat-icon><span class="font-size-13 pl-1">No tenemos registro de
          una cuenta en Kevins para la <br> dirección de correo electrónico que ingresaste. <br> Si necesitas ayuda,
          puedes contactar con uno de nuestros <br> asesores. </span>
      </mat-error>
      <button *ngIf="!user" class="button-secondary" (click)="whatsapp()">Contactar con servicio al cliente</button>
      <span *ngIf="!user" class="create-acount" (click)="routing('/registro')">¿Aún no tienes una cuenta? creala
        aquí <span (click)="routing('/registro')" class="deco">creala aquí</span></span>
      <button *ngIf="!user" (click)="routing('/login')" class="button-primary">Regresar a inicio de sesión</button>
      <button *ngIf="!res && user" (click)="sendMail()" class="button-primary">Continuar</button>
      <button *ngIf="res" (click)="routing('/login')" class="button-primary">Regresar a inicio de sesión</button>
    </div>
  </form>
</div>
