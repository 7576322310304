<div class="pt-3 pb-3">
  <h2 class="titles pl-35px">{{quienRecibe}}</h2>
  <div class="d-flex-column">
    <!-- Opción "yo" -->
    <div class="pr-1 pl-35px pb-3">
      <label class="checkbox-container">
        <input
          type="radio"
          name="receiverOptions"
          class="pr-1"
          [checked]="isChecked('yo')"
          (change)="onChangeRecibido('yo')">
        {{ recibeYo }}
        <span class="checkmark"></span>
      </label>
    </div>

    <!-- Opción "otro" -->
    <div class="pr-1 pl-35px">
      <label class="checkbox-container">
        <input
          type="radio"
          name="receiverOptions"
          class="pr-1"
          [checked]="isChecked('otro')"
          (change)="onChangeRecibido('otro')">
        {{ recibeOtro }}
        <span class="checkmark"></span>
      </label>
    </div>
  </div>

  <mat-expansion-panel [expanded]="isReceiveByOther" disabled="true">
    <form [formGroup]="formDomicilio" action="">
      <div class="row-column-responsive-2 gap-r gap-3 grid-tercero">
        <!-- Nombres y apellidos -->
        <div>
          <input formControlName="nombres" class="text-fields" type="text" placeholder="Nombres">
          <div *ngIf="formDomicilio.get('nombres')?.invalid && formDomicilio.get('nombres')?.touched">
            <small *ngIf="formDomicilio.get('nombres')?.errors?.['required']">
              El nombre es requerido.</small>
            <small *ngIf="formDomicilio.get('nombres')?.errors?.['minlength']">
              El nombre debe tener al menos 4 caracteres.</small>
            <small *ngIf="formDomicilio.get('nombres')?.errors?.['pattern']">
              Solo se permiten letras y espacios.</small>
          </div>
        </div>
        <div>
          <input formControlName="apellidos" class="text-fields" type="text" placeholder="Apellidos">
          <div *ngIf="formDomicilio.get('apellidos')?.invalid && formDomicilio.get('apellidos')?.touched">
            <small *ngIf="formDomicilio.get('apellidos')?.errors?.['required']">
              El apellido es requerido.</small>
            <small *ngIf="formDomicilio.get('apellidos')?.errors?.['minlength']">
              El apellidos debe tener al menos 4 caracteres.</small>
            <small *ngIf="formDomicilio.get('apellidos')?.errors?.['pattern']">
              Solo se permiten letras y espacios.</small>
          </div>
        </div>
        <!-- Select tipo documento y documento -->
        <div>
          <mat-form-field appearance="fill">
            <mat-select formControlName="tipoDocumento" placeholder="Documento" required class="pl-15 pr-15px">
              <mat-option *ngFor="let doc of listDocuments" [value]="doc">
                {{doc.nombre | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="formDomicilio.get('tipoDocumento')?.invalid && formDomicilio.get('tipoDocumento')?.touched">
            <small>Debe seleccionar un tipo de documento.</small>
          </div>
        </div>
        <div>
          <input formControlName="numeroDocumento" class="text-fields" type="text" placeholder="Numero de documento">
          <div *ngIf="formDomicilio.get('numeroDocumento')?.invalid && formDomicilio.get('numeroDocumento')?.touched">
            <small *ngIf="formDomicilio.get('numeroDocumento')?.errors?.['required']">El número de documento es
              requerido.</small> <br />
            <small *ngIf="formDomicilio.get('numeroDocumento')?.errors?.['minlength']">El documento debe tener
              al menos 5 caracteres.</small>
          </div>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>
