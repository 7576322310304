import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { BuyServiceService, ControlSesionService, ItemCar, ItemCompra, LoadingService, ManejadorEventosService, userlogged, UserService } from 'libreria-tienda';
import { Cabecera, Cart, ConfigService, ExpressService, JsonTextService, OrdenDeVenta, ShoppingCartService, SioServicesService, TextResponse, User, productoMP } from 'libreria-tienda';
import { TipoEnvio } from 'libreria-tienda';
import { AddressService } from 'libreria-tienda';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MessageBoxComponent } from '../domicilio/message-box/message-box.component';
import { MatDialog } from '@angular/material/dialog';
import { TipoCompraComponent } from './tipo-compra/tipo-compra.component';
import { NumeroPuntoPipe } from 'libreria-tienda';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-resumen',
  standalone: true,
  imports: [
    CommonModule,
    MatDividerModule,
    MatPseudoCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    NumeroPuntoPipe
  ],
  templateUrl: './resumen.component.html',
  styleUrl: './resumen.component.css'
})
export class ResumenComponent implements OnInit {

  private subscriptionEstaPagando: Subscription;


  @Input() section: number;
  @Input() envio: number = 1
  @Input() carrito: ItemCompra[] = [];

  jsonResumen: TextResponse[];
  costoEnvio: number = 10;
  subtotal: number = 0;

  productos: productoMP[] = new Array<productoMP>();
  ordenVenta: OrdenDeVenta = new OrdenDeVenta();
  cabeceraOrden: Cabecera = new Cabecera()

  user: User = new User();
  userLog: userlogged = new userlogged();

  messageError: string = "";

  validForm: boolean = false;
  shop: boolean = false;
  isCheckBoxSelected: boolean = true;
  submited: boolean = false;

  shoppingCart: boolean
  listItemsShoppingCart: ItemCar[] = [];
  listItemsCompra: ItemCompra[] = [];

  datosEnvio: TipoEnvio

  public load = inject(LoadingService);
  public sioservice = inject(SioServicesService);
  public jsonService = inject(JsonTextService);
  public buyService = inject(BuyServiceService);
  public addresService = inject(AddressService);
  public expressService = inject(ExpressService);
  public cartService = inject(ShoppingCartService);
  public configService = inject(ConfigService);
  public manejadorEventos = inject(ManejadorEventosService);
  public userService = inject(UserService);
  public sesionService = inject(ControlSesionService);

  constructor(private router: Router, public dialogo: MatDialog) { }

  ngOnInit(): void {
    this.jsonService.downloadJson(this.configService._config.URLRESUMENJSON).subscribe(res => {
      this.jsonResumen = res;
    })
    if (this.section == 2) {
      this.addresService.getDirectionCost().subscribe(res => {
        this.getCosto(this.subtotal, res)
      });
    }

    this.subscriptionEstaPagando = this.buyService.pagar$.subscribe(value => {
      if (value) {
        this.realizarCompra()
      } else {
        console.log('Falta info tercero');
        this.load.cargar(false);
      }
    })
  }

  getName(name: string) {
    return this.jsonResumen?.find(x => x.Nombre == name)?.Valor
  }

  totalFull() {
    let total = 0
    this.carrito.forEach(element => {
      total += element.precioFull * element.cantidadSeleccionada
    });
    return total
  }

  totalAutorizado() {
    let total = 0
    this.carrito.forEach(element => {
      total += element.precioAutorizado * element.cantidadSeleccionada
    });
    this.subtotal = total;
    return this.subtotal;
  }

  subTotalDescuento() {
    let total = this.totalFull();
    let totalDesc = this.totalAutorizado();
    return total - totalDesc
  }

  getCosto(valorCompra: number, nombreCiudad: string) {
    this.expressService.getCostoEnvio(valorCompra, nombreCiudad).subscribe((res: any) => {
      this.costoEnvio = res.res;
      console.log(this.costoEnvio);
    })
  }

  total() {
    return this.costoEnvio + this.totalAutorizado()
  }

  goBuy() {
    this.router.navigate(['home'])
  }

  pagar() {
    // this.load.cargar(true);
    this.buyService.newBuyMethod(true);
    this.submited = true;
  }

  async realizarCompra() {
    // this.load.cargar(false)
    console.log('Terceros completo');

    if (this.userService.userLogueado()) {
      this.datosEnvio = this.buyService.sendType;
      console.log('datosEnvio', this.datosEnvio);
      await this.validarDatosEntrega();
      this.ordenVenta.InfoEntrega = this.datosEnvio;
      this.ordenVenta.Detalle = this.carrito;


      // Acá va la petición de la información completa para la compra
      this.userLog = this.userService.getUserLocal();

      await this.userService.getUser().then(res => {
        this.user = res;
        // console.log('usuario', res);
      }).catch(error => {
        this.sesionService.cerrarSesion();
      });
      // this.cartToOrderProducts()
      // console.log('ItemCompra', this.carrito);

      await this.setCabeceraOrdenVenta();
      // console.log(this.ordenVenta);

      // console.log(this.user.correoElectronico, this.ordenVenta);


      if (this.validForm) {
        this.expressService.getPreferenceId(this.user.correoElectronico, this.ordenVenta).then(res => {
          // console.log(res);
          location.href = res.init

        }).catch(error => {
          // console.log(error);
          if (error.statusText == "Unauthorized" || error.error.message == "No token provided!") {
            // pop uptime, error inicia sesión
            this.sesionService.cerrarSesion();
          }
        })
      }
    } else {
      this.router.navigate(["/login"], {
        queryParams: {
          shopping: true,
        },
      })
    }
  }



  async comprar() {
    // this.load.cargar(true);
    this.cartService.saveCarritoCompra(this.carrito);
    if (this.userService.userLogueado()) {
      await this.userService.getUser().then((res) => {
        if (res.correoElectronico !== undefined) {
          this.router.navigate(["/shippings"]);
        } else {
          this.router.navigate(['/login']).then(() => {
            window.scrollTo(0, 0);
          });
        }
      }).catch(() => {
        console.log('error user.getUser');
        this.router.navigate(['/login']).then(() => {
          window.scrollTo(0, 0);
        });
      });
    } else {
      console.log('No esta logueado');
      this.openDialogMessage('Es una prueba', 'Hola', 5)
    }


  }

  openDialogMessage(titulo: string, mensaje: string, tipo: number): void {
    const dialogRef = this.dialogo.open(TipoCompraComponent, { data: { title: titulo, msg: mensaje, tipo: tipo } })

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if (result.valor == 'login') {
        this.router.navigate(["/login"], {
          queryParams: {
            shopping: true,
          },
        })
      } else {
        this.router.navigate(["/compra-rapida"])
      }
    });
  }

  cartToOrderProducts() {
    this.ordenVenta.Detalle = [];
    this.carrito.forEach(item => {
    })
  }

  setCabeceraOrdenVenta() {
    let fecha = new Date + '';
    this.cabeceraOrden.FechaSistema = fecha;
    this.cabeceraOrden.IdCliente = this.user.id;
    this.cabeceraOrden.IdCarroCompras = this.user.carro;
    this.cabeceraOrden.Nombres = this.user.nombre;
    this.cabeceraOrden.Apellidos = this.user.apellido;
    this.cabeceraOrden.TipoDocumento = this.user.tipoDocumento;
    this.cabeceraOrden.NumeroDocumento = this.user.numeroDocumento;
    this.cabeceraOrden.Telefono = this.user.celular;
    this.cabeceraOrden.correoelectronico = this.user.correoElectronico;
    this.cabeceraOrden.ValorEnvio = this.costoEnvio;
    this.cabeceraOrden.ValorItems = this.subtotal;
    this.cabeceraOrden.ValorTotal = this.subtotal + this.costoEnvio;

    this.ordenVenta.Cabecera = this.cabeceraOrden

    // console.log('cabeceraOrden', this.cabeceraOrden);
  }


  validarDatosEntrega() {
    let lugar: boolean = false
    let persona: boolean = false
    if (!this.datosEnvio.yoRecibo) {
      if (this.datosEnvio.numeroDocumentoTercero !== null && this.datosEnvio.nombreTercero !== null && this.datosEnvio.tipoDocumentoTercero !== null) {
        persona = true;
      } else {
        persona = false;
      }
    } else {
      if (this.datosEnvio.numeroDocumentoUser !== null && this.datosEnvio.nombreUser !== null && this.datosEnvio.tipoDocumentoUser !== null) {
        persona = true;
      } else {
        persona = false;
      }
    }
    if (this.datosEnvio.metodo == "DOMICILIO") {
      if (this.datosEnvio.direccion !== null && this.datosEnvio.idDepto !== null && this.datosEnvio.idMunicipio !== null) {
        lugar = true;
      } else {
        lugar = false;
      }
    } else {
      if (this.datosEnvio.nombreTienda !== null && this.datosEnvio.idCentroCosto !== null) {
        lugar = true;
      } else {
        lugar = false;
      }
    }

    this.validForm = lugar && persona

    // console.log(this.validForm);

  }

}
