import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AutenticacionComponent } from './pages/autenticacion/autenticacion.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { DetalleProductoComponent } from './pages/detalle-producto/detalle-producto.component';
import { CarritoCompraComponent } from './pages/carrito-compra/carrito-compra.component';
import { ShippingsComponent } from './pages/shippings/shippings.component';
import { ModifyAddressComponent } from './pages/modify-address/modify-address.component';
import { ResponsePaymentComponent } from './pages/response-payment/response-payment.component';
import { CatalogoComponent } from './pages/catalogo/catalogo.component';
import { EditAddressComponent } from './pages/edit-address/edit-address.component';
import { MetodosEntregaComponent } from './pages/metodos-entrega/metodos-entrega.component';
import { PreguntasComponent } from './pages/preguntas/preguntas.component';
import { JoyeriasComponent } from './pages/joyerias/joyerias.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { EditPerfilComponent } from './pages/edit-perfil/edit-perfil.component';
import { TrabajaNosotrosComponent } from './pages/trabaja-nosotros/trabaja-nosotros.component';
import { ExperienciaVeneziaComponent } from './pages/experiencia-venezia/experiencia-venezia.component';
import { VeneziaComponent } from './pages/venezia/venezia.component';
import { FavoritosCatalogoComponent } from './pages/favoritos-catalogo/favoritos-catalogo.component';
import { DetalleProductoIdComponent } from './pages/detalle-producto-id/detalle-producto-id.component';
import { CuidadosComponent } from './pages/cuidados/cuidados.component';
import { PoliticasTratamientosComponent } from './pages/politicas-tratamientos/politicas-tratamientos.component';
import { GanaKilatesComponent } from './pages/gana-kilates/gana-kilates.component';
import { CompraRapidaComponent } from './pages/compra-rapida/compra-rapida.component';
import { CompraOroComponent } from './pages/compra-oro/compra-oro.component';
import { PqrsComponent } from './pages/pqrs/pqrs.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { RestaurarPasswordComponent } from './pages/restaurar-password/restaurar-password.component';
import { OlvidoPasswordComponent } from './pages/olvido-password/olvido-password.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'vacantes',
    component: TrabajaNosotrosComponent
  },
  {
    path: 'contactanos',
    component: ContactUsComponent
  },
  {
    path: 'login',
    component: AutenticacionComponent
  },
  {
    path: 'registro',
    component: RegistroComponent
  },
  // {
  //   path: 'detalle/:categoria/:producto/:esgia/:id',
  //   component: DetalleProductoComponent
  // },
  {
    path: 'detalle',
    component: DetalleProductoComponent
  },
  {
    path: 'shopping-cart',
    component: CarritoCompraComponent
  },
  {
    path: 'shippings',
    component: ShippingsComponent
  },
  {
    path: 'modify-address',
    component: ModifyAddressComponent
  },
  {
    path: 'payment-confirmation',
    component: ResponsePaymentComponent
  },
  {
    path: 'catalogo',
    component: CatalogoComponent
  },
  {
    path: 'catalogo/:busqueda',
    component: CatalogoComponent
  },
  // {
  //   path: 'catalogue/:catalogue/:busqueda',
  //   component: CatalogoComponent
  // },
  // {
  //   path: 'catalogue/:catalogue',
  //   redirectTo: 'catalogue/:catalogue/0',
  //   pathMatch: 'full'
  // },
  {
    path: 'add-Address',
    component: EditAddressComponent
  },
  {
    path: 'edit-Address',
    component: EditAddressComponent
  },
  // {
  //   path: 'garantias',
  //   component: GarantiasComponent
  // },
  {
    path: 'metodos-entrega',
    component: MetodosEntregaComponent
  },
  {
    path: 'preguntas-frecuentes',
    component: PreguntasComponent
  },
  {
    path: 'joyerias',
    component: JoyeriasComponent
  },
  {
    path: 'perfil',
    component: PerfilComponent
  },
  {
    path: 'edit-perfil',
    component: EditPerfilComponent
  },
  {
    path: 'experiencia-venezia',
    component: ExperienciaVeneziaComponent
  },
  {
    path: 'venezia',
    component: VeneziaComponent
  },
  {
    path: 'favoritos',
    component: FavoritosCatalogoComponent
  },
  {
    path: 'tratamiento-datos',
    component: PoliticasTratamientosComponent
  },
  // {
  //   path: 'producto/:idProducto',
  //   component: DetalleProductoIdComponent
  // },
  {
    path: 'producto/:idProducto',
    component: DetalleProductoComponent
  },
  {
    path: 'cuidados',
    component: CuidadosComponent
  },
  {
    path: 'gana-kilates',
    component: GanaKilatesComponent
  },
  {
    path: 'compra-rapida',
    component: CompraRapidaComponent
  },
  {
    path: 'compra-oro',
    component: CompraOroComponent
  },
  {
    path: 'pqrs',
    component: PqrsComponent
  },
  {
    path: 'terminos-condiciones',
    component: TerminosCondicionesComponent
  },
  {
    path: 'olvido-contrasena',
    component: OlvidoPasswordComponent
  },
  { path: 'restaurar-contrasena', component: RestaurarPasswordComponent },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];
