import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpressService, LoadingService } from 'libreria-tienda';
import * as bcrypt from 'bcryptjs';
import { MatDialog } from '@angular/material/dialog';
import { PopupCorreoRegistroComponent } from 'libreria-tienda/shared/popup-correo-registro/popup-correo-registro.component';

@Component({
  selector: 'app-restaurar-password',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDividerModule, FormsModule, ReactiveFormsModule, MatFormFieldModule],
  templateUrl: './restaurar-password.component.html',
  styleUrl: './restaurar-password.component.css'
})
export class RestaurarPasswordComponent implements OnInit {

  expressService = inject(ExpressService)
  route = inject(ActivatedRoute)
  load = inject(LoadingService)
  router = inject(Router)


  constructor(private fb: FormBuilder, public dialog: MatDialog) {
    this.form = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&-])[A-Za-z\\d@$!%*?&-]{8,}$')]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&-])[A-Za-z\\d@$!%*?&-]{8,}$')]]
    })
    this.form.addValidators(
      this.matchValidator(this.form.get('password'), this.form.get('passwordConfirm'))
    );

  }
  form: FormGroup;

  isSend: boolean = false;

  equal: boolean = false;

  hide: boolean = true;

  hide2: boolean = true;

  submited: boolean = false

  email: string = ""

  token: string = ""

  loader: boolean = true;

  ngOnInit(): void {
    this.load.cargar(false)
    this.route.queryParamMap.subscribe(params => {
      this.email = params.get("email") || ""
      this.token = params.get("token") || ""
    })
  }
  matchValidator(
    control: AbstractControl | any,
    controlTwo: AbstractControl | any
  ): ValidatorFn {
    return () => {
      if (control.value !== controlTwo.value) {
        this.equal = false
        return { match_error: 'Value does not match' };
      } else {
        this.equal = true
        return null;
      }
    };
  }

  Restore() {
    this.submited = true
    if (this.form.valid) {
      this.loader = false
      this.expressService.restorePassword(this.email, this.token, this.encriptar(this.form.get("password")?.value)).subscribe(res => {
        if (res) {
          this.loader = true
          console.log("contraseña reestablecida exitosamente")
          this.openDialog('¡contraseña reestablecida exitosamente!', 'Inicie sesión')
          // this.router.navigate(['/login'])
        } else {
          this.loader = true
          console.log("fallo")
        }
      })
    }
    this.isSend = true
  }
  isHide(boton: string) {
    if (boton == "boton1")
      this.hide = !this.hide;
    else
      this.hide2 = !this.hide2;
    return false;
  }
  encriptar(password: string) {
    return bcrypt.hashSync(password, 10);
  }

  openDialog(titulo: string, texto: string) {
    this.dialog.open(PopupCorreoRegistroComponent, {
      data: {
        titulo: titulo,
        texto: texto,
        boton: null,
        img: 'https://qastorageorafa.blob.core.windows.net/generales/diamante-brillos.png'
      }
    }).afterClosed().subscribe(res => {
      this.router.navigate(['/login']).then(() => {
        window.location.reload();
        window.scrollTo(0, 0)
      });
    });
  }
}
