<div class="banner-container">
  <div class="max-with-container">
    <div class="item-container">
      <a class="align-center" (click)="navigate('joyerias')">
        <span class="icon-Localizacion size-wa white-color"></span>
        <span class="text-banner">{{propiedades['header']['ubicacion']}}</span>
      </a>
      <a class="Btn" [href]="whatsApp" target="_blank">
        <span class="icon-Whatsapp size-wa white-color sign"></span>
        <span class="text-animacion">{{propiedades['header']['whats']}}</span>
      </a>
      <!-- <a class="align-center" [href]="whatsApp" target="_blank">
        <span class="icon-Whatsapp size-wa white-color"></span>
        <span class="text-banner">{{propiedades['header']['whats']}}</span>
      </a> -->
    </div>
    <div class="item2-container">
      <a class="align-center" (click)="abrirCertificado()">
        <span class="icon-Garantia_icono white-color icon-size-standard"></span>
        <span class="text-banner">{{propiedades['header']['certificado']}}</span>
      </a>
    </div>
  </div>
</div>
