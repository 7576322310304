<div class="">
  <!-- Pasos para realizar el pago -->
  <lib-pasos-compra [paso]="1" class="d-none d-sm-none d-md-none d-lg-block"></lib-pasos-compra>

  <div class="contenedor-encabezado">
    <h1>Carrito de Compras</h1>
    <span>({{listItemsCompra.length}} Articulos)</span>
    <mat-divider></mat-divider>
  </div>

  <div class="d-flex-row pt-5 pb-5 space-around back-img padding-container">
    <div class="w-60">
      @if (shoppingCart) {
      <lib-carrito-vacio />
      }@else {
      <lib-carrito [carrito]="listItemsCompra" (indexDelet)="EliminarItem($event)"></lib-carrito>
      }
    </div>
    <div>
      <lib-resumen [carrito]="listItemsCompra" [section]="1" [envio]="-1" />
    </div>
  </div>
</div>
