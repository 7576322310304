// builds/browser.ts
import { createXhrRequester } from "@algolia/requester-browser-xhr";
import {
  createBrowserLocalStorageCache,
  createFallbackableCache,
  createMemoryCache,
  createNullLogger
} from "@algolia/client-common";

// src/ingestionClient.ts
import { createAuth, createTransporter, getAlgoliaAgent } from "@algolia/client-common";
var apiClientVersion = "1.19.0";
var REGIONS = ["eu", "us"];
function getDefaultHosts(region) {
  const url = "data.{region}.algolia.com".replace("{region}", region);
  return [{ url, accept: "readWrite", protocol: "https" }];
}
function isOnDemandTrigger(trigger) {
  return trigger.type === "onDemand";
}
function isScheduleTrigger(trigger) {
  return trigger.type === "schedule";
}
function isSubscriptionTrigger(trigger) {
  return trigger.type === "subscription";
}
function createIngestionClient({
  appId: appIdOption,
  apiKey: apiKeyOption,
  authMode,
  algoliaAgents,
  region: regionOption,
  ...options
}) {
  const auth = createAuth(appIdOption, apiKeyOption, authMode);
  const transporter = createTransporter({
    hosts: getDefaultHosts(regionOption),
    ...options,
    algoliaAgent: getAlgoliaAgent({
      algoliaAgents,
      client: "Ingestion",
      version: apiClientVersion
    }),
    baseHeaders: {
      "content-type": "text/plain",
      ...auth.headers(),
      ...options.baseHeaders
    },
    baseQueryParameters: {
      ...auth.queryParameters(),
      ...options.baseQueryParameters
    }
  });
  return {
    transporter,
    /**
     * The `appId` currently in use.
     */
    appId: appIdOption,
    /**
     * The `apiKey` currently in use.
     */
    apiKey: apiKeyOption,
    /**
     * Clears the cache of the transporter for the `requestsCache` and `responsesCache` properties.
     */
    clearCache() {
      return Promise.all([transporter.requestsCache.clear(), transporter.responsesCache.clear()]).then(() => void 0);
    },
    /**
     * Get the value of the `algoliaAgent`, used by our libraries internally and telemetry system.
     */
    get _ua() {
      return transporter.algoliaAgent.value;
    },
    /**
     * Adds a `segment` to the `x-algolia-agent` sent with every requests.
     *
     * @param segment - The algolia agent (user-agent) segment to add.
     * @param version - The version of the agent.
     */
    addAlgoliaAgent(segment, version) {
      transporter.algoliaAgent.add({ segment, version });
    },
    /**
     * Helper method to switch the API key used to authenticate the requests.
     *
     * @param params - Method params.
     * @param params.apiKey - The new API Key to use.
     */
    setClientApiKey({ apiKey }) {
      if (!authMode || authMode === "WithinHeaders") {
        transporter.baseHeaders["x-algolia-api-key"] = apiKey;
      } else {
        transporter.baseQueryParameters["x-algolia-api-key"] = apiKey;
      }
    },
    /**
     * Creates a new authentication resource.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param authenticationCreate -
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createAuthentication(authenticationCreate, requestOptions) {
      if (!authenticationCreate) {
        throw new Error("Parameter `authenticationCreate` is required when calling `createAuthentication`.");
      }
      if (!authenticationCreate.type) {
        throw new Error("Parameter `authenticationCreate.type` is required when calling `createAuthentication`.");
      }
      if (!authenticationCreate.name) {
        throw new Error("Parameter `authenticationCreate.name` is required when calling `createAuthentication`.");
      }
      if (!authenticationCreate.input) {
        throw new Error("Parameter `authenticationCreate.input` is required when calling `createAuthentication`.");
      }
      const requestPath = "/1/authentications";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: authenticationCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Creates a new destination.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param destinationCreate -
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createDestination(destinationCreate, requestOptions) {
      if (!destinationCreate) {
        throw new Error("Parameter `destinationCreate` is required when calling `createDestination`.");
      }
      if (!destinationCreate.type) {
        throw new Error("Parameter `destinationCreate.type` is required when calling `createDestination`.");
      }
      if (!destinationCreate.name) {
        throw new Error("Parameter `destinationCreate.name` is required when calling `createDestination`.");
      }
      if (!destinationCreate.input) {
        throw new Error("Parameter `destinationCreate.input` is required when calling `createDestination`.");
      }
      const requestPath = "/1/destinations";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: destinationCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Creates a new source.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param sourceCreate -
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createSource(sourceCreate, requestOptions) {
      if (!sourceCreate) {
        throw new Error("Parameter `sourceCreate` is required when calling `createSource`.");
      }
      if (!sourceCreate.type) {
        throw new Error("Parameter `sourceCreate.type` is required when calling `createSource`.");
      }
      if (!sourceCreate.name) {
        throw new Error("Parameter `sourceCreate.name` is required when calling `createSource`.");
      }
      const requestPath = "/1/sources";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: sourceCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Creates a new task.
     * @param taskCreate - Request body for creating a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createTask(taskCreate, requestOptions) {
      if (!taskCreate) {
        throw new Error("Parameter `taskCreate` is required when calling `createTask`.");
      }
      if (!taskCreate.sourceID) {
        throw new Error("Parameter `taskCreate.sourceID` is required when calling `createTask`.");
      }
      if (!taskCreate.destinationID) {
        throw new Error("Parameter `taskCreate.destinationID` is required when calling `createTask`.");
      }
      if (!taskCreate.action) {
        throw new Error("Parameter `taskCreate.action` is required when calling `createTask`.");
      }
      const requestPath = "/2/tasks";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: taskCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Creates a new task using the v1 endpoint, please use `createTask` instead.
     * @param taskCreate - Request body for creating a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createTaskV1(taskCreate, requestOptions) {
      if (!taskCreate) {
        throw new Error("Parameter `taskCreate` is required when calling `createTaskV1`.");
      }
      if (!taskCreate.sourceID) {
        throw new Error("Parameter `taskCreate.sourceID` is required when calling `createTaskV1`.");
      }
      if (!taskCreate.destinationID) {
        throw new Error("Parameter `taskCreate.destinationID` is required when calling `createTaskV1`.");
      }
      if (!taskCreate.trigger) {
        throw new Error("Parameter `taskCreate.trigger` is required when calling `createTaskV1`.");
      }
      if (!taskCreate.action) {
        throw new Error("Parameter `taskCreate.action` is required when calling `createTaskV1`.");
      }
      const requestPath = "/1/tasks";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: taskCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Creates a new transformation.
     * @param transformationCreate - Request body for creating a transformation.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    createTransformation(transformationCreate, requestOptions) {
      if (!transformationCreate) {
        throw new Error("Parameter `transformationCreate` is required when calling `createTransformation`.");
      }
      if (!transformationCreate.code) {
        throw new Error("Parameter `transformationCreate.code` is required when calling `createTransformation`.");
      }
      if (!transformationCreate.name) {
        throw new Error("Parameter `transformationCreate.name` is required when calling `createTransformation`.");
      }
      const requestPath = "/1/transformations";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: transformationCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customDelete - The customDelete object.
     * @param customDelete.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customDelete.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customDelete({ path, parameters }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customDelete`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customGet - The customGet object.
     * @param customGet.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customGet.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customGet({ path, parameters }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customGet`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPost - The customPost object.
     * @param customPost.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPost.parameters - Query parameters to apply to the current query.
     * @param customPost.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPost({ path, parameters, body }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPost`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPut - The customPut object.
     * @param customPut.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPut.parameters - Query parameters to apply to the current query.
     * @param customPut.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPut({ path, parameters, body }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPut`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes an authentication resource. You can\'t delete authentication resources that are used by a source or a destination.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param deleteAuthentication - The deleteAuthentication object.
     * @param deleteAuthentication.authenticationID - Unique identifier of an authentication resource.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteAuthentication({ authenticationID }, requestOptions) {
      if (!authenticationID) {
        throw new Error("Parameter `authenticationID` is required when calling `deleteAuthentication`.");
      }
      const requestPath = "/1/authentications/{authenticationID}".replace(
        "{authenticationID}",
        encodeURIComponent(authenticationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes a destination by its ID. You can\'t delete destinations that are referenced in tasks.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param deleteDestination - The deleteDestination object.
     * @param deleteDestination.destinationID - Unique identifier of a destination.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteDestination({ destinationID }, requestOptions) {
      if (!destinationID) {
        throw new Error("Parameter `destinationID` is required when calling `deleteDestination`.");
      }
      const requestPath = "/1/destinations/{destinationID}".replace(
        "{destinationID}",
        encodeURIComponent(destinationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes a source by its ID. You can\'t delete sources that are referenced in tasks.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param deleteSource - The deleteSource object.
     * @param deleteSource.sourceID - Unique identifier of a source.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteSource({ sourceID }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `deleteSource`.");
      }
      const requestPath = "/1/sources/{sourceID}".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes a task by its ID.
     * @param deleteTask - The deleteTask object.
     * @param deleteTask.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteTask({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `deleteTask`.");
      }
      const requestPath = "/2/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes a task by its ID using the v1 endpoint, please use `deleteTask` instead.
     * @param deleteTaskV1 - The deleteTaskV1 object.
     * @param deleteTaskV1.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteTaskV1({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `deleteTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes a transformation by its ID.
     * @param deleteTransformation - The deleteTransformation object.
     * @param deleteTransformation.transformationID - Unique identifier of a transformation.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteTransformation({ transformationID }, requestOptions) {
      if (!transformationID) {
        throw new Error("Parameter `transformationID` is required when calling `deleteTransformation`.");
      }
      const requestPath = "/1/transformations/{transformationID}".replace(
        "{transformationID}",
        encodeURIComponent(transformationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Disables a task.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param disableTask - The disableTask object.
     * @param disableTask.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    disableTask({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `disableTask`.");
      }
      const requestPath = "/2/tasks/{taskID}/disable".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Disables a task using the v1 endpoint, please use `disableTask` instead.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param disableTaskV1 - The disableTaskV1 object.
     * @param disableTaskV1.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    disableTaskV1({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `disableTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}/disable".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Enables a task.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param enableTask - The enableTask object.
     * @param enableTask.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    enableTask({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `enableTask`.");
      }
      const requestPath = "/2/tasks/{taskID}/enable".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Enables a task using the v1 endpoint, please use `enableTask` instead.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param enableTaskV1 - The enableTaskV1 object.
     * @param enableTaskV1.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    enableTaskV1({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `enableTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}/enable".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves an authentication resource by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getAuthentication - The getAuthentication object.
     * @param getAuthentication.authenticationID - Unique identifier of an authentication resource.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getAuthentication({ authenticationID }, requestOptions) {
      if (!authenticationID) {
        throw new Error("Parameter `authenticationID` is required when calling `getAuthentication`.");
      }
      const requestPath = "/1/authentications/{authenticationID}".replace(
        "{authenticationID}",
        encodeURIComponent(authenticationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a destination by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getDestination - The getDestination object.
     * @param getDestination.destinationID - Unique identifier of a destination.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getDestination({ destinationID }, requestOptions) {
      if (!destinationID) {
        throw new Error("Parameter `destinationID` is required when calling `getDestination`.");
      }
      const requestPath = "/1/destinations/{destinationID}".replace(
        "{destinationID}",
        encodeURIComponent(destinationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a single task run event by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getEvent - The getEvent object.
     * @param getEvent.runID - Unique identifier of a task run.
     * @param getEvent.eventID - Unique identifier of an event.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getEvent({ runID, eventID }, requestOptions) {
      if (!runID) {
        throw new Error("Parameter `runID` is required when calling `getEvent`.");
      }
      if (!eventID) {
        throw new Error("Parameter `eventID` is required when calling `getEvent`.");
      }
      const requestPath = "/1/runs/{runID}/events/{eventID}".replace("{runID}", encodeURIComponent(runID)).replace("{eventID}", encodeURIComponent(eventID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieve a single task run by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getRun - The getRun object.
     * @param getRun.runID - Unique identifier of a task run.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getRun({ runID }, requestOptions) {
      if (!runID) {
        throw new Error("Parameter `runID` is required when calling `getRun`.");
      }
      const requestPath = "/1/runs/{runID}".replace("{runID}", encodeURIComponent(runID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieve a source by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getSource - The getSource object.
     * @param getSource.sourceID - Unique identifier of a source.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getSource({ sourceID }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `getSource`.");
      }
      const requestPath = "/1/sources/{sourceID}".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a task by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getTask - The getTask object.
     * @param getTask.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTask({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `getTask`.");
      }
      const requestPath = "/2/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a task by its ID using the v1 endpoint, please use `getTask` instead.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getTaskV1 - The getTaskV1 object.
     * @param getTaskV1.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTaskV1({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `getTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a transformation by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param getTransformation - The getTransformation object.
     * @param getTransformation.transformationID - Unique identifier of a transformation.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getTransformation({ transformationID }, requestOptions) {
      if (!transformationID) {
        throw new Error("Parameter `transformationID` is required when calling `getTransformation`.");
      }
      const requestPath = "/1/transformations/{transformationID}".replace(
        "{transformationID}",
        encodeURIComponent(transformationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of all authentication resources.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listAuthentications - The listAuthentications object.
     * @param listAuthentications.itemsPerPage - Number of items per page.
     * @param listAuthentications.page - Page number of the paginated API response.
     * @param listAuthentications.type - Type of authentication resource to retrieve.
     * @param listAuthentications.platform - Ecommerce platform for which to retrieve authentications.
     * @param listAuthentications.sort - Property by which to sort the list of authentications.
     * @param listAuthentications.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listAuthentications({ itemsPerPage, page, type, platform, sort, order } = {}, requestOptions = void 0) {
      const requestPath = "/1/authentications";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (type !== void 0) {
        queryParameters["type"] = type.toString();
      }
      if (platform !== void 0) {
        queryParameters["platform"] = platform.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of destinations.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listDestinations - The listDestinations object.
     * @param listDestinations.itemsPerPage - Number of items per page.
     * @param listDestinations.page - Page number of the paginated API response.
     * @param listDestinations.type - Destination type.
     * @param listDestinations.authenticationID - Authentication ID used by destinations.
     * @param listDestinations.transformationID - Get the list of destinations used by a transformation.
     * @param listDestinations.sort - Property by which to sort the destinations.
     * @param listDestinations.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listDestinations({ itemsPerPage, page, type, authenticationID, transformationID, sort, order } = {}, requestOptions = void 0) {
      const requestPath = "/1/destinations";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (type !== void 0) {
        queryParameters["type"] = type.toString();
      }
      if (authenticationID !== void 0) {
        queryParameters["authenticationID"] = authenticationID.toString();
      }
      if (transformationID !== void 0) {
        queryParameters["transformationID"] = transformationID.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of events for a task run, identified by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listEvents - The listEvents object.
     * @param listEvents.runID - Unique identifier of a task run.
     * @param listEvents.itemsPerPage - Number of items per page.
     * @param listEvents.page - Page number of the paginated API response.
     * @param listEvents.status - Event status for filtering the list of task runs.
     * @param listEvents.type - Event type for filtering the list of task runs.
     * @param listEvents.sort - Property by which to sort the list of task run events.
     * @param listEvents.order - Sort order of the response, ascending or descending.
     * @param listEvents.startDate - Date and time in RFC 3339 format for the earliest events to retrieve. By default, the current time minus three hours is used.
     * @param listEvents.endDate - Date and time in RFC 3339 format for the latest events to retrieve. By default, the current time is used.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listEvents({ runID, itemsPerPage, page, status, type, sort, order, startDate, endDate }, requestOptions) {
      if (!runID) {
        throw new Error("Parameter `runID` is required when calling `listEvents`.");
      }
      const requestPath = "/1/runs/{runID}/events".replace("{runID}", encodeURIComponent(runID));
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (status !== void 0) {
        queryParameters["status"] = status.toString();
      }
      if (type !== void 0) {
        queryParameters["type"] = type.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieve a list of task runs.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listRuns - The listRuns object.
     * @param listRuns.itemsPerPage - Number of items per page.
     * @param listRuns.page - Page number of the paginated API response.
     * @param listRuns.status - Run status for filtering the list of task runs.
     * @param listRuns.type - Run type for filtering the list of task runs.
     * @param listRuns.taskID - Task ID for filtering the list of task runs.
     * @param listRuns.sort - Property by which to sort the list of task runs.
     * @param listRuns.order - Sort order of the response, ascending or descending.
     * @param listRuns.startDate - Date in RFC 3339 format for the earliest run to retrieve. By default, the current day minus seven days is used.
     * @param listRuns.endDate - Date in RFC 3339 format for the latest run to retrieve. By default, the current day is used.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listRuns({ itemsPerPage, page, status, type, taskID, sort, order, startDate, endDate } = {}, requestOptions = void 0) {
      const requestPath = "/1/runs";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (status !== void 0) {
        queryParameters["status"] = status.toString();
      }
      if (type !== void 0) {
        queryParameters["type"] = type.toString();
      }
      if (taskID !== void 0) {
        queryParameters["taskID"] = taskID.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      if (startDate !== void 0) {
        queryParameters["startDate"] = startDate.toString();
      }
      if (endDate !== void 0) {
        queryParameters["endDate"] = endDate.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of sources.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listSources - The listSources object.
     * @param listSources.itemsPerPage - Number of items per page.
     * @param listSources.page - Page number of the paginated API response.
     * @param listSources.type - Source type. Some sources require authentication.
     * @param listSources.authenticationID - Authentication IDs of the sources to retrieve. \'none\' returns sources that doesn\'t have an authentication.
     * @param listSources.sort - Property by which to sort the list of sources.
     * @param listSources.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listSources({ itemsPerPage, page, type, authenticationID, sort, order } = {}, requestOptions = void 0) {
      const requestPath = "/1/sources";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (type !== void 0) {
        queryParameters["type"] = type.toString();
      }
      if (authenticationID !== void 0) {
        queryParameters["authenticationID"] = authenticationID.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of tasks.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listTasks - The listTasks object.
     * @param listTasks.itemsPerPage - Number of items per page.
     * @param listTasks.page - Page number of the paginated API response.
     * @param listTasks.action - Actions for filtering the list of tasks.
     * @param listTasks.enabled - Whether to filter the list of tasks by the `enabled` status.
     * @param listTasks.sourceID - Source IDs for filtering the list of tasks.
     * @param listTasks.sourceType - Filters the tasks with the specified source type.
     * @param listTasks.destinationID - Destination IDs for filtering the list of tasks.
     * @param listTasks.triggerType - Type of task trigger for filtering the list of tasks.
     * @param listTasks.withEmailNotifications - If specified, the response only includes tasks with notifications.email.enabled set to this value.
     * @param listTasks.sort - Property by which to sort the list of tasks.
     * @param listTasks.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listTasks({
      itemsPerPage,
      page,
      action,
      enabled,
      sourceID,
      sourceType,
      destinationID,
      triggerType,
      withEmailNotifications,
      sort,
      order
    } = {}, requestOptions = void 0) {
      const requestPath = "/2/tasks";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (action !== void 0) {
        queryParameters["action"] = action.toString();
      }
      if (enabled !== void 0) {
        queryParameters["enabled"] = enabled.toString();
      }
      if (sourceID !== void 0) {
        queryParameters["sourceID"] = sourceID.toString();
      }
      if (sourceType !== void 0) {
        queryParameters["sourceType"] = sourceType.toString();
      }
      if (destinationID !== void 0) {
        queryParameters["destinationID"] = destinationID.toString();
      }
      if (triggerType !== void 0) {
        queryParameters["triggerType"] = triggerType.toString();
      }
      if (withEmailNotifications !== void 0) {
        queryParameters["withEmailNotifications"] = withEmailNotifications.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of tasks using the v1 endpoint, please use `getTasks` instead.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listTasksV1 - The listTasksV1 object.
     * @param listTasksV1.itemsPerPage - Number of items per page.
     * @param listTasksV1.page - Page number of the paginated API response.
     * @param listTasksV1.action - Actions for filtering the list of tasks.
     * @param listTasksV1.enabled - Whether to filter the list of tasks by the `enabled` status.
     * @param listTasksV1.sourceID - Source IDs for filtering the list of tasks.
     * @param listTasksV1.destinationID - Destination IDs for filtering the list of tasks.
     * @param listTasksV1.triggerType - Type of task trigger for filtering the list of tasks.
     * @param listTasksV1.sort - Property by which to sort the list of tasks.
     * @param listTasksV1.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listTasksV1({ itemsPerPage, page, action, enabled, sourceID, destinationID, triggerType, sort, order } = {}, requestOptions = void 0) {
      const requestPath = "/1/tasks";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (action !== void 0) {
        queryParameters["action"] = action.toString();
      }
      if (enabled !== void 0) {
        queryParameters["enabled"] = enabled.toString();
      }
      if (sourceID !== void 0) {
        queryParameters["sourceID"] = sourceID.toString();
      }
      if (destinationID !== void 0) {
        queryParameters["destinationID"] = destinationID.toString();
      }
      if (triggerType !== void 0) {
        queryParameters["triggerType"] = triggerType.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves a list of transformations.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param listTransformations - The listTransformations object.
     * @param listTransformations.itemsPerPage - Number of items per page.
     * @param listTransformations.page - Page number of the paginated API response.
     * @param listTransformations.sort - Property by which to sort the list of transformations.
     * @param listTransformations.order - Sort order of the response, ascending or descending.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listTransformations({ itemsPerPage, page, sort, order } = {}, requestOptions = void 0) {
      const requestPath = "/1/transformations";
      const headers = {};
      const queryParameters = {};
      if (itemsPerPage !== void 0) {
        queryParameters["itemsPerPage"] = itemsPerPage.toString();
      }
      if (page !== void 0) {
        queryParameters["page"] = page.toString();
      }
      if (sort !== void 0) {
        queryParameters["sort"] = sort.toString();
      }
      if (order !== void 0) {
        queryParameters["order"] = order.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Push a `batch` request payload through the Pipeline. You can check the status of task pushes with the observability endpoints.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param pushTask - The pushTask object.
     * @param pushTask.taskID - Unique identifier of a task.
     * @param pushTask.pushTaskPayload - Request body of a Search API `batch` request that will be pushed in the Connectors pipeline.
     * @param pushTask.watch - When provided, the push operation will be synchronous and the API will wait for the ingestion to be finished before responding.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    pushTask({ taskID, pushTaskPayload, watch }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `pushTask`.");
      }
      if (!pushTaskPayload) {
        throw new Error("Parameter `pushTaskPayload` is required when calling `pushTask`.");
      }
      if (!pushTaskPayload.action) {
        throw new Error("Parameter `pushTaskPayload.action` is required when calling `pushTask`.");
      }
      if (!pushTaskPayload.records) {
        throw new Error("Parameter `pushTaskPayload.records` is required when calling `pushTask`.");
      }
      const requestPath = "/2/tasks/{taskID}/push".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      if (watch !== void 0) {
        queryParameters["watch"] = watch.toString();
      }
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: pushTaskPayload
      };
      requestOptions = {
        timeouts: {
          connect: 18e4,
          read: 18e4,
          write: 18e4,
          ...requestOptions?.timeouts
        }
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Runs all tasks linked to a source, only available for Shopify sources. It will create 1 run per task.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param runSource - The runSource object.
     * @param runSource.sourceID - Unique identifier of a source.
     * @param runSource.runSourcePayload -
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    runSource({ sourceID, runSourcePayload }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `runSource`.");
      }
      const requestPath = "/1/sources/{sourceID}/run".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: runSourcePayload ? runSourcePayload : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Runs a task. You can check the status of task runs with the observability endpoints.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param runTask - The runTask object.
     * @param runTask.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    runTask({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `runTask`.");
      }
      const requestPath = "/2/tasks/{taskID}/run".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Runs a task using the v1 endpoint, please use `runTask` instead. You can check the status of task runs with the observability endpoints.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param runTaskV1 - The runTaskV1 object.
     * @param runTaskV1.taskID - Unique identifier of a task.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    runTaskV1({ taskID }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `runTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}/run".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for authentication resources.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param authenticationSearch - The authenticationSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchAuthentications(authenticationSearch, requestOptions) {
      if (!authenticationSearch) {
        throw new Error("Parameter `authenticationSearch` is required when calling `searchAuthentications`.");
      }
      if (!authenticationSearch.authenticationIDs) {
        throw new Error(
          "Parameter `authenticationSearch.authenticationIDs` is required when calling `searchAuthentications`."
        );
      }
      const requestPath = "/1/authentications/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: authenticationSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for destinations.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param destinationSearch - The destinationSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchDestinations(destinationSearch, requestOptions) {
      if (!destinationSearch) {
        throw new Error("Parameter `destinationSearch` is required when calling `searchDestinations`.");
      }
      if (!destinationSearch.destinationIDs) {
        throw new Error("Parameter `destinationSearch.destinationIDs` is required when calling `searchDestinations`.");
      }
      const requestPath = "/1/destinations/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: destinationSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for sources.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param sourceSearch - The sourceSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchSources(sourceSearch, requestOptions) {
      if (!sourceSearch) {
        throw new Error("Parameter `sourceSearch` is required when calling `searchSources`.");
      }
      if (!sourceSearch.sourceIDs) {
        throw new Error("Parameter `sourceSearch.sourceIDs` is required when calling `searchSources`.");
      }
      const requestPath = "/1/sources/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: sourceSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for tasks.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param taskSearch - The taskSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchTasks(taskSearch, requestOptions) {
      if (!taskSearch) {
        throw new Error("Parameter `taskSearch` is required when calling `searchTasks`.");
      }
      if (!taskSearch.taskIDs) {
        throw new Error("Parameter `taskSearch.taskIDs` is required when calling `searchTasks`.");
      }
      const requestPath = "/2/tasks/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: taskSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for tasks using the v1 endpoint, please use `searchTasks` instead.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param taskSearch - The taskSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchTasksV1(taskSearch, requestOptions) {
      if (!taskSearch) {
        throw new Error("Parameter `taskSearch` is required when calling `searchTasksV1`.");
      }
      if (!taskSearch.taskIDs) {
        throw new Error("Parameter `taskSearch.taskIDs` is required when calling `searchTasksV1`.");
      }
      const requestPath = "/1/tasks/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: taskSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Searches for transformations.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param transformationSearch - The transformationSearch object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    searchTransformations(transformationSearch, requestOptions) {
      if (!transformationSearch) {
        throw new Error("Parameter `transformationSearch` is required when calling `searchTransformations`.");
      }
      if (!transformationSearch.transformationIDs) {
        throw new Error(
          "Parameter `transformationSearch.transformationIDs` is required when calling `searchTransformations`."
        );
      }
      const requestPath = "/1/transformations/search";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: transformationSearch
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Triggers a stream-listing request for a source. Triggering stream-listing requests only works with sources with `type: docker` and `imageType: airbyte`.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param triggerDockerSourceDiscover - The triggerDockerSourceDiscover object.
     * @param triggerDockerSourceDiscover.sourceID - Unique identifier of a source.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    triggerDockerSourceDiscover({ sourceID }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `triggerDockerSourceDiscover`.");
      }
      const requestPath = "/1/sources/{sourceID}/discover".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers
      };
      requestOptions = {
        timeouts: {
          connect: 18e4,
          read: 18e4,
          write: 18e4,
          ...requestOptions?.timeouts
        }
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Try a transformation before creating it.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param transformationTry - The transformationTry object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    tryTransformation(transformationTry, requestOptions) {
      if (!transformationTry) {
        throw new Error("Parameter `transformationTry` is required when calling `tryTransformation`.");
      }
      if (!transformationTry.code) {
        throw new Error("Parameter `transformationTry.code` is required when calling `tryTransformation`.");
      }
      if (!transformationTry.sampleRecord) {
        throw new Error("Parameter `transformationTry.sampleRecord` is required when calling `tryTransformation`.");
      }
      const requestPath = "/1/transformations/try";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: transformationTry
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Try a transformation before updating it.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param tryTransformationBeforeUpdate - The tryTransformationBeforeUpdate object.
     * @param tryTransformationBeforeUpdate.transformationID - Unique identifier of a transformation.
     * @param tryTransformationBeforeUpdate.transformationTry - The transformationTry object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    tryTransformationBeforeUpdate({ transformationID, transformationTry }, requestOptions) {
      if (!transformationID) {
        throw new Error("Parameter `transformationID` is required when calling `tryTransformationBeforeUpdate`.");
      }
      if (!transformationTry) {
        throw new Error("Parameter `transformationTry` is required when calling `tryTransformationBeforeUpdate`.");
      }
      if (!transformationTry.code) {
        throw new Error("Parameter `transformationTry.code` is required when calling `tryTransformationBeforeUpdate`.");
      }
      if (!transformationTry.sampleRecord) {
        throw new Error(
          "Parameter `transformationTry.sampleRecord` is required when calling `tryTransformationBeforeUpdate`."
        );
      }
      const requestPath = "/1/transformations/{transformationID}/try".replace(
        "{transformationID}",
        encodeURIComponent(transformationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: transformationTry
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates an authentication resource.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param updateAuthentication - The updateAuthentication object.
     * @param updateAuthentication.authenticationID - Unique identifier of an authentication resource.
     * @param updateAuthentication.authenticationUpdate - The authenticationUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateAuthentication({ authenticationID, authenticationUpdate }, requestOptions) {
      if (!authenticationID) {
        throw new Error("Parameter `authenticationID` is required when calling `updateAuthentication`.");
      }
      if (!authenticationUpdate) {
        throw new Error("Parameter `authenticationUpdate` is required when calling `updateAuthentication`.");
      }
      const requestPath = "/1/authentications/{authenticationID}".replace(
        "{authenticationID}",
        encodeURIComponent(authenticationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PATCH",
        path: requestPath,
        queryParameters,
        headers,
        data: authenticationUpdate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates the destination by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param updateDestination - The updateDestination object.
     * @param updateDestination.destinationID - Unique identifier of a destination.
     * @param updateDestination.destinationUpdate - The destinationUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateDestination({ destinationID, destinationUpdate }, requestOptions) {
      if (!destinationID) {
        throw new Error("Parameter `destinationID` is required when calling `updateDestination`.");
      }
      if (!destinationUpdate) {
        throw new Error("Parameter `destinationUpdate` is required when calling `updateDestination`.");
      }
      const requestPath = "/1/destinations/{destinationID}".replace(
        "{destinationID}",
        encodeURIComponent(destinationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PATCH",
        path: requestPath,
        queryParameters,
        headers,
        data: destinationUpdate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates a source by its ID.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param updateSource - The updateSource object.
     * @param updateSource.sourceID - Unique identifier of a source.
     * @param updateSource.sourceUpdate - The sourceUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateSource({ sourceID, sourceUpdate }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `updateSource`.");
      }
      if (!sourceUpdate) {
        throw new Error("Parameter `sourceUpdate` is required when calling `updateSource`.");
      }
      const requestPath = "/1/sources/{sourceID}".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PATCH",
        path: requestPath,
        queryParameters,
        headers,
        data: sourceUpdate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates a task by its ID.
     * @param updateTask - The updateTask object.
     * @param updateTask.taskID - Unique identifier of a task.
     * @param updateTask.taskUpdate - The taskUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateTask({ taskID, taskUpdate }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `updateTask`.");
      }
      if (!taskUpdate) {
        throw new Error("Parameter `taskUpdate` is required when calling `updateTask`.");
      }
      const requestPath = "/2/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PATCH",
        path: requestPath,
        queryParameters,
        headers,
        data: taskUpdate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates a task by its ID using the v1 endpoint, please use `updateTask` instead.
     * @param updateTaskV1 - The updateTaskV1 object.
     * @param updateTaskV1.taskID - Unique identifier of a task.
     * @param updateTaskV1.taskUpdate - The taskUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateTaskV1({ taskID, taskUpdate }, requestOptions) {
      if (!taskID) {
        throw new Error("Parameter `taskID` is required when calling `updateTaskV1`.");
      }
      if (!taskUpdate) {
        throw new Error("Parameter `taskUpdate` is required when calling `updateTaskV1`.");
      }
      const requestPath = "/1/tasks/{taskID}".replace("{taskID}", encodeURIComponent(taskID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PATCH",
        path: requestPath,
        queryParameters,
        headers,
        data: taskUpdate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Updates a transformation by its ID.
     * @param updateTransformation - The updateTransformation object.
     * @param updateTransformation.transformationID - Unique identifier of a transformation.
     * @param updateTransformation.transformationCreate - The transformationCreate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    updateTransformation({ transformationID, transformationCreate }, requestOptions) {
      if (!transformationID) {
        throw new Error("Parameter `transformationID` is required when calling `updateTransformation`.");
      }
      if (!transformationCreate) {
        throw new Error("Parameter `transformationCreate` is required when calling `updateTransformation`.");
      }
      if (!transformationCreate.code) {
        throw new Error("Parameter `transformationCreate.code` is required when calling `updateTransformation`.");
      }
      if (!transformationCreate.name) {
        throw new Error("Parameter `transformationCreate.name` is required when calling `updateTransformation`.");
      }
      const requestPath = "/1/transformations/{transformationID}".replace(
        "{transformationID}",
        encodeURIComponent(transformationID)
      );
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers,
        data: transformationCreate
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Validates a source payload to ensure it can be created and that the data source can be reached by Algolia.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param sourceCreate -
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    validateSource(sourceCreate, requestOptions = void 0) {
      const requestPath = "/1/sources/validate";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: sourceCreate ? sourceCreate : {}
      };
      requestOptions = {
        timeouts: {
          connect: 18e4,
          read: 18e4,
          write: 18e4,
          ...requestOptions?.timeouts
        }
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Validates an update of a source payload to ensure it can be created and that the data source can be reached by Algolia.
     *
     * Required API Key ACLs:
     *  - addObject
     *  - deleteIndex
     *  - editSettings
     * @param validateSourceBeforeUpdate - The validateSourceBeforeUpdate object.
     * @param validateSourceBeforeUpdate.sourceID - Unique identifier of a source.
     * @param validateSourceBeforeUpdate.sourceUpdate - The sourceUpdate object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    validateSourceBeforeUpdate({ sourceID, sourceUpdate }, requestOptions) {
      if (!sourceID) {
        throw new Error("Parameter `sourceID` is required when calling `validateSourceBeforeUpdate`.");
      }
      if (!sourceUpdate) {
        throw new Error("Parameter `sourceUpdate` is required when calling `validateSourceBeforeUpdate`.");
      }
      const requestPath = "/1/sources/{sourceID}/validate".replace("{sourceID}", encodeURIComponent(sourceID));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: sourceUpdate
      };
      requestOptions = {
        timeouts: {
          connect: 18e4,
          read: 18e4,
          write: 18e4,
          ...requestOptions?.timeouts
        }
      };
      return transporter.request(request, requestOptions);
    }
  };
}

// builds/browser.ts
function ingestionClient(appId, apiKey, region, options) {
  if (!appId || typeof appId !== "string") {
    throw new Error("`appId` is missing.");
  }
  if (!apiKey || typeof apiKey !== "string") {
    throw new Error("`apiKey` is missing.");
  }
  if (!region || region && (typeof region !== "string" || !REGIONS.includes(region))) {
    throw new Error(`\`region\` is required and must be one of the following: ${REGIONS.join(", ")}`);
  }
  return createIngestionClient({
    appId,
    apiKey,
    region,
    timeouts: {
      connect: 25e3,
      read: 25e3,
      write: 25e3
    },
    logger: createNullLogger(),
    requester: createXhrRequester(),
    algoliaAgents: [{ segment: "Browser" }],
    authMode: "WithinQueryParameters",
    responsesCache: createMemoryCache(),
    requestsCache: createMemoryCache({ serializable: false }),
    hostsCache: createFallbackableCache({
      caches: [createBrowserLocalStorageCache({ key: `${apiClientVersion}-${appId}` }), createMemoryCache()]
    }),
    ...options
  });
}
export {
  apiClientVersion,
  ingestionClient,
  isOnDemandTrigger,
  isScheduleTrigger,
  isSubscriptionTrigger
};
