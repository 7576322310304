import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { EstadosFiltrosService, TipoEnvio } from 'libreria-tienda';
import { BuyServiceService } from 'libreria-tienda';
import { CommonLista, SioServicesService, User } from 'libreria-tienda';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'lib-receive-by',
  standalone: true,
  imports: [CommonModule, MatPseudoCheckboxModule, FormsModule, MatExpansionModule, ReactiveFormsModule, MatSelectModule],
  templateUrl: './receive-by.component.html',
  styleUrl: './receive-by.component.css'
})
export class ReceiveByComponent implements OnInit {



  isReceiveByMe: boolean = true;
  isReceiveByOther: boolean = false;
  formDomicilio: FormGroup;
  formTienda: FormGroup;
  listDocuments: CommonLista[] = new Array<CommonLista>();
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  isMobile: boolean = false;

  tipoEnvio: TipoEnvio = new TipoEnvio()
  @Input() user: User = new User()
  @Input() recibeYo: string | undefined = ""
  @Input() recibeOtro: string | undefined = ""
  @Input() quienRecibe: string | undefined = ""
  @Input() guardar: string | undefined = ""
  // _section: number = 1
  // @Input() set section(value: number) {
  //   this.formDomicilio.reset()
  //   this._section = value
  // }
  @Output() sendTypeEmit = new EventEmitter<TipoEnvio>()

  public sioService = inject(SioServicesService);
  public buyService = inject(BuyServiceService);

  constructor(private fb: FormBuilder) {

    this.formDomicilio = this.fb.group({
      nombres: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(50)] // Validadores síncronos
      ],
      apellidos: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(50)]
      ],
      tipoDocumento: [
        '',
        [Validators.required]
      ],
      numeroDocumento: [
        '',
        [Validators.required, Validators.minLength(8)]
      ]
    });

  }

  ngOnInit(): void {
    this.sioService.GetListaPorCodigo("TPDOC").subscribe(list => {
      this.listDocuments = list
    })
    this.buyService.newBuy.subscribe(sec => {
      console.log(sec);
      this.saveInfo();
    })


  }

  onChangeRecibido(quienRecibe: string) {
    if (quienRecibe == 'yo') {
      this.isReceiveByMe = true;
      this.isReceiveByOther = false;
    } else {
      this.isReceiveByMe = false;
      this.isReceiveByOther = true;
    }
  }

  selectedReceiver: 'yo' | 'otro' = 'yo';

  isChecked(receiver: 'yo' | 'otro'): boolean {
    return this.selectedReceiver === receiver; // Devuelve si está seleccionada
  }

  saveInfo() {
    if (this.isReceiveByMe) {
      this.buyService.sendType.yoRecibo = true
      this.buyService.updateEstaPagando(true);
    } else {
      console.log(this.formDomicilio.value);
      console.log(this.formDomicilio.valid);

      if (this.formDomicilio.valid) {
        this.buyService.sendType.yoRecibo = false
        this.buyService.sendType.nombreTercero = this.formDomicilio.get("nombres")?.value
        this.buyService.sendType.apellidoTercero = this.formDomicilio.get("apellidos")?.value
        this.buyService.sendType.tipoDocumentoTercero = this.formDomicilio.get("tipoDocumento")?.value.codLista
        this.buyService.sendType.numeroDocumentoTercero = this.formDomicilio.get("numeroDocumento")?.value
        this.buyService.updateEstaPagando(true);
      } else {
        this.buyService.updateEstaPagando(false);
        this.formDomicilio.markAllAsTouched();
      }
    }
  }
}
