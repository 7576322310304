<div>
  <div class="d-flex-column second-side">
    @if (totalProducts == 0 && newProduct == false) {
    <div class="d-flex-column pt-5 cart-center pl-pr-1rem w-100 align-center">
      <span class="icon-shoppingCart icon-size-shop pb-1"></span>
      <span class="text-empty pb-3">{{propiedades['header']['empty-cart']}}</span>
      <button (click)="cerrar(); routing('home')">{{propiedades['header']['select-product']}}</button>
    </div>
    }@else{
    <div class="d-flex-column pt-5 tam-box">
      @if (newProduct) {
      <div class="d-flex-row pt-5 space-eve">
        <span class="icon-Icono_aprobacion icon-size apro"></span>
        <span class="apro">{{propiedades['header']['new-product']}}</span>
      </div>
      <div class="d-flex-row pt-5">
        <div>
          <img class="img-size" src="{{lastProduct.imagen}}" alt="">
        </div>
        <div class="d-flex-column space-between pl-5px">
          <span class="new-product">REF. {{lastProduct.idProducto}}</span>
          <span matTooltip="{{lastProduct.descWeb}}" class="text text-elipsis">{{lastProduct.descWeb}}</span>
          <span class="text" *ngIf="lastProduct.tallaId != -1">Talla:
            {{lastProduct.descTalla}}</span>
          <!-- <span class="text" *ngIf="lastProduct.tallaId == -1">Talla: No
            tiene</span> -->
          <span class="text">Cantidad. {{lastProduct.cantidad}}</span>
        </div>
      </div>
      }@else {
      <div class="product-container">
        @for (item of items; track $index) {
        <div class="pb-3">
          <div class="d-flex-row" (click)="VerProducto(item.idProducto)">
            <div>
              <img class="img-size" src="{{item.imagen}}" alt="">
            </div>
            <div class="d-flex-column pl-5px">
              <span class="new-product">REF. {{item.idProducto}}</span>
              <span matTooltip="{{item.descWeb}}" class="text text-elipsis">{{item.descWeb}}</span>
              <span class="text" *ngIf="item.tallaId != -1">Talla:
                {{item.descTalla}}</span>
              <!-- <span class="text" *ngIf="item.tallaId == -1">Talla: No tiene</span> -->
              <span class="text">Cantidad. {{item.cantidad}}</span>
            </div>
          </div>
        </div>
        }
      </div>
      }

      <div class="pt-3">
        <button (click)="redirect('shopping-cart')" class="button-mycart">{{propiedades['header']['view-cart']}}
          ({{totalProducts}})</button>
      </div>
      <div class="pt-3">
        <button (click)="redirect('shippings')">{{propiedades['header']['pay']}}</button>
      </div>
      <a (click)="volver();" class="pt-3 underline">{{propiedades['header']['continue']}}</a>
    </div>
    }
  </div>
</div>
