export class TipoEnvio {
  metodo: string; // Metodo de entrega

  // Entrega en punto
  idCentroCosto: string | null = null; //Centro de costo del punto kevin
  nombreTienda: string | null = null;

  // Los dos
  idDepto: string | null = null;
  nombreDepto: string | null = null;
  idMunicipio: string | null = null;
  nombreMunicipio: string | null = null;
  yoRecibo: boolean = true;
  mensajePersonalizado: string | null = null;

  // Tercero
  nombreTercero: string | null = null;
  apellidoTercero: string | null = null;
  tipoDocumentoTercero: string | null = null;
  numeroDocumentoTercero: string | null = null;
  numeroContactoTercero: string | null = null;

  // Usuario
  nombreUser: string | null = null;
  apellidoUser: string | null = null;
  tipoDocumentoUser: string | null = null;
  numeroDocumentoUser: string | null = null;
  numeroContacto: string | null = null;
  correo: string | null = null;

  // Domicilio
  direccion: string | null = null; // Dirección de entrega del usuario
  envioNormal: boolean | null = null;
  envioExpress: boolean | null = null;
  tiempoEntrega: string | null = null;
}
