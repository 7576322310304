<div class="d-none d-sm-none d-md-none d-lg-block">
  <div class="pt-3">
    <span class="reference">Ref. {{codigo}}</span>
  </div>
  @if (item.porcentaje !== 0) {
  <div>
    <span class="price price-full">$ {{item.precioFull | numeroPunto}}</span>
  </div>
  }
  <div class="d-flex-row">
    <span class="price price-auto" [ngClass]="{'descuento':item.porcentaje !== 0}">$ {{item.precioAutorizado |
      numeroPunto}}</span>
    @if (item.porcentaje !== 0) {
    <lib-etiqueta-descuento [porcentaje]="item.porcentaje" class="pl-1rem" />
    }
  </div>
  @if (item.porcentaje < 15 && item.porcentaje !==0) { <span>Precio exclusivo para ventas online</span>
    }
    <div>
      <div class="pt-3">
        <span class="characteristics">{{getName('cara')}}</span>
      </div>
      <!-- @for (characteristic of caracteristicas; track $index) {
      <div class="d-flex-center" [ngClass]="{'pb-3' : $index == (caracteristicas.length -1)}">
        @if (characteristic.tipo == 1) {
        <span class="circle"></span>
        <span class="details">{{characteristic.texto}}</span>
        }
      </div>
      } -->
      <mat-accordion class="pt-3">
        <mat-expansion-panel (opened)="onPanelOpened()" class="border-expansion" (closed)="onPanelClosed()"
          hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="no-wrap">{{getName('mas-detalles')}}</span>
            </mat-panel-title>
            <mat-panel-description>
              <mat-icon class="color-black" *ngIf="!open ">add
              </mat-icon>
              <mat-icon class="color-black" *ngIf="open">
                remove</mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <!-- <div *ngFor="let characteristic of caracteristicas" class="d-flex-align-end d-flex-row pr-2">
            <span *ngIf="characteristic.tipo == 2">{{characteristic.texto}}</span>
          </div> -->
        </mat-expansion-panel>
        <mat-divider></mat-divider>
      </mat-accordion>
    </div>
    <div class="d-flex-column pt-3">
      <span class="cant pb-2">{{getName('cant')}}</span>
      <div class="d-flex-row">
        <button class="button-addremove" (click)="menos()">
          <mat-icon>remove</mat-icon>
        </button>
        <input type="number" id="cant" #cant value="{{ item.cantidad }}" min=1 name="" id="" placeholder="Cantidad"
          class="input-class input-cant" (keydown)="handleKeydown($event)" readonly>
        <button class="button-addremove" (click)="mas()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    @if (unico) {
    <mat-error class="pt-3 d-flex-center">
      <mat-icon class="red">error_outline</mat-icon><span class="pl-1rem"> Producto unico en la compañia. Por
        favor
        Comunicate con servicio al cliente</span>
    </mat-error>
    }
    <div class="pt-3">
      <span class="cant pb-2">¿Te gustaría agregar una de nuestras pulseras? </span>
      <div class="d-flex-row pt-3">
        @for (item of complementos; track $index) {
        <lib-complemento [info]="item" class="pl-1rem" (selectComple)="complemtoSele($index, $event)" />
        }
      </div>
    </div>
    <div class="d-flex-column gap pt-3">
      <div class="d-flex-row d-flex-center">
        <button class="button-cart" type="submit" (click)="agregarCarrito()">{{getName('but-cart')}}</button>
        <lib-favorito-corazon class="pl-1rem" />
      </div>
      <span (click)="donwloadPolitics()" class="politics cursor-pointer" [innerHTML]="getName('politics')"></span>
    </div>
</div>
