<div class="back-img pt-5 pb-5">
  <div class="d-flex-center d-flex-column">
    <div>
      <h2 class="title">¡Hola {{userLog.nombre}}!</h2>
    </div>
    <div class="mb-3">
      <span class="welcome">Bienvenido a tu cuenta</span>
    </div>
  </div>
  <mat-tab-group [(selectedIndex)]="activeTabIndex" mat-stretch-tabs="false" mat-align-tabs="center"
    animationDuration="0ms">
    <mat-tab label="Perfil">
      <lib-info-perfil />
    </mat-tab>
    <mat-tab label="Compras">
      <lib-historial-compras />
    </mat-tab>
  </mat-tab-group>
</div>
