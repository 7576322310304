<div class="">
  @if (carrito) {
  @for (item of carrito; track $index) {
  <div class="d-flex-column pt-5">
    <div class="d-flex-row pb-5">
      <div class="pl-1rem">
        <img (click)="routing(item.idProducto)" class="img" src="{{item.imagen}}" alt="">
      </div>
      <div class="column-shop row-shop w-100 pl-1rem">
        <div class="d-flex-column">
          <span class="ref">Ref. {{item.idProducto}}</span>
          <span class="desc" [ngClass]="{'vermas': vermas == $index}">{{item.descripcionWeb}}</span>
          @if (vermas == $index) {
          <span class="vermas" (click)="verMas($index)">Ver menos</span>
          }@else {
          <span class="vermas" (click)="verMas($index)">Ver mas</span>
          }
        </div>
        <div class="d-flex-row w-100 space-around pt-1rem h-web-kit contenedor-datos">
          <div class="d-flex-column align-center mobile-on">
            <div class="d-flex-column h-100">
              <span class="titles pb-4">Precio</span>
              <span class="priceDescuento">${{getTotalSinDescuento($index) | numeroPunto}}</span>
              <span class="price">${{getTotal($index) | numeroPunto}}</span>
            </div>
          </div>
          <div class="d-flex-column align-center">
            @if (item.descripcionTalla !== '-1') {
            <span class="titles pb-3">Talla</span>
            <div>
              {{item.descripcionTalla}}
            </div>
            }
          </div>
          <div class="d-flex-column align-center">
            <span class="titles pb-3">Cantidad</span>
            <div class="d-flex-row">
              <button class="button-addremove" (click)="menos($index)">
                <mat-icon>remove</mat-icon>
              </button>
              <input type="number" id="cant" #cant value="{{ item.cantidadSeleccionada }}" min=1 placeholder="Cantidad"
                class="input-cant" (keydown)="handleKeydown($event)" readonly>
              <button class="button-addremove" (click)="mas($index)">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div class="d-flex basura mobile-on contenedor-acciones">
              @if (item.descripcionTalla !== '-1') {
              <span class="cursor-pointer editar" (click)="EditItem($index)">
                <i class="fa-solid fa-pen-to-square size-edit"></i>
                <span class="remover">Cambiar talla</span>
              </span>
              }
              <span class="icon-Eliminar icon-size-color cursor-pointer" (click)="deleteItem($index)"><span class="remover">Remover</span></span>
            </div>
          </div>
          <div class="d-flex-column align-center mobile-off">
            <div class="d-flex-column h-100">
              <span class="titles pb-4">Precio</span>
              <span class="priceDescuento">${{getTotalSinDescuento($index) | numeroPunto}}</span>
              <span class="price">${{getTotal($index) | numeroPunto}}</span>
            </div>
            <div class="d-flex basura">
              @if (item.descripcionTalla !== '-1') {
              <span class="cursor-pointer" (click)="EditItem($index)">
                <i class="fa-solid fa-pen-to-square size-edit"></i>
              </span>
              }
              <span class="icon-Eliminar icon-size-color cursor-pointer" (click)="deleteItem($index)"><span class="remover">Remover</span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (carrito.length-1 !== $index && carrito.length>0) {
    <mat-divider></mat-divider>
    }
  </div>
  }
  }
</div>
